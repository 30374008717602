import APIUrl from "../../APIUrl";
import axios from "axios";

export const GET_RGPD = "GET_RGPD";

function getRgpdAction(rgpd) {
  return { type: GET_RGPD, rgpd: rgpd };
}

export const getRgpd = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getRgpd)
      .then(function (response) {
        if (response) dispatch(getRgpdAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const createRgpd = function (successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.createRgpd)
      .then(function (response) {
        dispatch(getRgpd());
        return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};
