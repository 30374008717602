export default class Util {
  static shallowClone(obj) {
    return { ...obj };
  }

  static emptyString(val) {
    if (!(typeof val === "string" || val instanceof String)) return false;

    return !val || ("" + val).replace(" ", "") === "";
  }

  static emptyArray(array) {
    return !array || array.length === 0;
  }

  static emptyObject(obj) {
    return Object.entries(obj).length === 0 && obj.constructor === Object;
  }

  /**
   * https://codesips.io/relaiable-way-to-check-datatypes-in-javascript/
   *
   * //test
   * //Primitive
   * console.assert(typeOf(1) === 'Number'); //true
   * console.assert(typeOf(1.2) === 'Number'); //true
   * console.assert(typeOf(1/0) === 'Number'); //true
   * console.assert(typeOf(1 + +'A') === 'Number'); //true
   * console.assert(typeOf(undefined) === 'Undefined'); //true
   * console.assert(typeOf(null) === 'Null'); //true
   * console.assert(typeOf(false) === 'Boolean'); //true
   * console.assert(typeOf(true) === 'Boolean'); //true
   * console.assert(typeOf(new Date()) === 'Date'); //true
   *
   * //Non-primitive
   * console.assert(typeOf({}) === 'Object'); //true
   * console.assert(typeOf([]) === 'Array'); //true
   * console.assert(typeOf('Hello world') === 'String'); //true
   * console.assert(typeOf(() => {}) === 'Function'); //true
   * console.assert(typeOf(/ab+c/) === 'RegExp'); //true
   *
   * @param {*} type
   */
  static typeOf(type) {
    return Object.prototype.toString.call(type).slice(8, -1);
  }

  static isEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  // New method to validate username format (from 5 to 20 alphanumeric chars, including dash and underscore)
  static isValidUserName(username, minLength, maxLength) {
    // Set default length values if not provided
    minLength = minLength || 5;
    maxLength = maxLength || 20;

    // Define reg exp and test it
    var pattern = "^([a-zA-Z0-9_]){" + minLength + "," + maxLength + "}$";
    var re = new RegExp(pattern);
    var testIt = re.test(username);

    // If there was an error, we return an array of min and max values to update the error message
    if (!testIt) {
      return {
        minLength: minLength,
        maxLength: maxLength,
      };
    }

    return testIt;
  }

  // Validates a password from 6 chars min with at least one upper/lower case and/or one number
  // https://www.thepolyglotdeveloper.com/2015/05/use-regex-to-test-password-strength-in-javascript/
  static checkValidPasswordForRecovery(password) {
    var pattern = new RegExp(
      "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
    );
    return pattern.test(password);
  }

  static isPostal(postal) {
    var re = /^\d{2}[ ]?\d{3}$/im;
    return re.test(String(postal).toLowerCase());
  }

  static isPhone(phone) {
    var re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
    return re.test(String(phone).toLowerCase());
  }

  static stringToColor(str) {
    function hashCode(str) {
      // java String#hashCode
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      return hash;
    }

    function intToRGB(i) {
      var c = (i & 0x00ffffff).toString(16).toUpperCase();

      return "00000".substring(0, 6 - c.length) + c;
    }

    return "#" + intToRGB(hashCode(str));
  }

  static normalize(text, excludes = []) {
    if (!text) return "";

    var normalized = text
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-z0-9]/gi, "_")
      .toLowerCase();

    for (let exclude of excludes) {
      var re = new RegExp(exclude, "g");
      normalized = normalized.replace(re, "");
    }

    return normalized;
  }

  static getUrl() {
    var spliced = window.location.href.split("/#/");

    if (!spliced[1]) return null;

    var suffix =
      spliced[1].indexOf("/") !== -1 ? spliced[1].split("/")[0] : spliced[1];
    suffix = suffix.replace(/\//g, "");

    return suffix;
  }

  // Verify/set HTTPS protocol if frontend is running in HTTPS
  static checkHttps(url) {
    return window.location.protocol === "https:"
      ? url.replace(/^http:/, "https:")
      : url.replace(/^https:/, "http:");
  }
}
