import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import UserModal from "./UserModal";
import Users from "./Users";
import Roles from "../../enums/Roles";
import { Alert } from "react-bootstrap";

class EstablishmentUsers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };
  }

  closeModal() {
    this.setState({ modal: null });
  }

  openModal() {
    this.setState({
      modal: (
        <UserModal
          company={this.props.company}
          establishment={this.props.establishment}
          close={() => this.closeModal()}
        />
      ),
    });
  }

  render() {
    const { user } = this.props;

    if (!user) return null;

    const isAdmin = user.role === Roles.ADMIN;

    if (this.props.clients.length === 0) {
      return (
        <React.Fragment>
          {isAdmin && (
            <button
              className="btn btn-info m-auto hoverable"
              onClick={(e) => this.openModal()}
            >
              <FormattedMessage id="Therapist.Add" />
            </button>
          )}

          <Alert variant="secondary" className="mt-3">
            <FormattedMessage id="Empty.Therapist" />
          </Alert>

          {this.state.modal}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {isAdmin && (
          <button
            className="btn btn-info m-auto hoverable"
            onClick={(e) => this.openModal()}
          >
            <FormattedMessage id="Therapist.Add" />
          </button>
        )}

        <Users clients={this.props.clients} />

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EstablishmentUsers);
