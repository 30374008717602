import { GET_EXPRESSION_NEEDS } from "../../actions/expressionNeeds/expressionNeeds";

export default function expressionNeeds(state = [], action) {
  switch (action.type) {
    case GET_EXPRESSION_NEEDS:
      return action.expressionNeeds;
    default:
      return state;
  }
}
