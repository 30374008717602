import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import SignatureInput from "../../../sub/SignatureInput";
import { updPatient } from "../../../../actions/patients/patients";
import { exportPDF } from "../../../../actions/patients/patients";
import Util from "../../../../util/Util";

class ExpressionNeedsSignaturePage extends React.Component {
  stopEvent(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  disabled() {
    return !this.props.therapistSignature || !this.props.patientSignature;
  }

  exportPDF() {
    var clonedPatient = Util.shallowClone(this.props.patient);
    // clonedPatient.expressions_needs.therapist_signature = this.props.therapistSignature;
    // clonedPatient.expressions_needs.patient_signature = this.props.patientSignature;
    let data = {
      date: this.props.date,
    };
    clonedPatient.expressions_needs.push(data);

    this.props.onUpdPatient(clonedPatient, () => {
      this.props.onExportPDF();
    });
  }

  render() {
    return (
      <>
        <div className="row">
          <div className="col-11 offset-1 mt-5 row">
            <div className="col-4">
              <label htmlFor="therapist_signature" className="col-form-label">
                <FormattedMessage id="Therapist.Signature" />
              </label>
            </div>
            <div
              id="signature-container"
              className="col-6 offset-1 p-0 purple-border max-height-200 min-height-200 text-center"
            >
              <SignatureInput
                containerId="signature-container"
                onEnd={(value) => {
                  this.props.setState({ therapist_signature: value });
                }}
              />
            </div>
          </div>
          <div className="col-11 offset-1 mt-5 mb-5 row">
            <div className="col-4">
              <label htmlFor="therapist_signature" className="col-form-label">
                <FormattedMessage id="Patient.Signature" />
              </label>
            </div>
            <div
              id="signature-container"
              className="col-6 offset-1 p-0 purple-border max-height-200 min-height-200 text-center"
            >
              <SignatureInput
                containerId="signature-container"
                onEnd={(value) => {
                  this.props.setState({ patient_signature: value });
                }}
              />
            </div>
          </div>
          <button
            type="button"
            className="btn btn-info btn-block col-7 m-auto"
            disabled={this.disabled()}
            onClick={() => this.exportPDF()}
          >
            <FormattedMessage id="Export.PDF" />
          </button>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onExportPDF: (patient, successCallback) =>
      dispatch(exportPDF(patient, successCallback)),
    onUpdPatient: (patient, successCallback) =>
      dispatch(updPatient(patient, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ExpressionNeedsSignaturePage));
