import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../util/Util";
import {
  addCalendarEventAdmin,
  updateCalendarEventAdmin,
  deleteCalendarEventAdmin,
  addCalendarEventClient,
  deleteCalendarEventClient,
} from "../../actions/calendarEvents/calendarEvents";
import DateUtil from "../../util/DateUtil";
import ConfirmationModal from "../sub/modals/ConfirmationModal";
import Roles from "../../enums/Roles";
import { Alert, Button, Modal } from "react-bootstrap";
import CustomLabel from "../sub/CustomLabel";
class AddCalendarEventModal extends React.Component {
  constructor(props) {
    super(props);

    const { calendarEvent, user } = this.props;

    this.isUpdate = !!calendarEvent;

    this.state = {
      loading: false,
      modal: null,

      // BE data
      patient_id: calendarEvent ? calendarEvent.patient_id : "",
      prescriber_id: calendarEvent ? calendarEvent.prescriber_id : "",
      collaborator_id: this.getCollaboratorId(calendarEvent),
      type:
        calendarEvent && user.role !== Roles.CLIENT ? calendarEvent.type : "3",
      desc: calendarEvent ? calendarEvent.desc : "",
      start_date: calendarEvent
        ? DateUtil.toDateTime(calendarEvent.start_date)
        : "",
      end_date: calendarEvent
        ? DateUtil.toDateTime(calendarEvent.end_date)
        : "",
      notValidate: calendarEvent ? calendarEvent.notValidate : true,

      // Errors
      endDateError: null,
    };
  }

  getCollaboratorId(calendarEvent) {
    if (this.props.user.role === Roles.SALES_REP) {
      return this.props.user._id;
    }

    if (calendarEvent && calendarEvent.collaborator_id) {
      return calendarEvent.collaborator_id;
    }

    if (calendarEvent && !calendarEvent.collaborator_id) {
      return undefined;
    }

    return undefined;
  }

  onSubmit() {
    this.setState({ loading: true });

    const calendarEvent = {
      patient_id: this.state.patient_id,
      prescriber_id: Util.emptyString(this.state.prescriber_id)
        ? this.props.user._id
        : this.state.prescriber_id,
      collaborator_id: this.state.collaborator_id,
      type: this.state.type,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      desc: this.state.desc,
      notValidate: this.props.user.role !== 0 ? false : true,
    };

    // Send to BE
    this.props.user.role !== 0
      ? this.props.onAddCalendarEventAdmin(calendarEvent, () =>
          this.props.close()
        )
      : this.props.onAddCalendarEventClient(calendarEvent, () =>
          this.props.close()
        );
  }

  onChange(field, value) {
    const check = () => {
      // End date must be after start date
      if (
        this.state.start_date &&
        this.state.end_date &&
        new Date(this.state.start_date).getTime() >
          new Date(this.state.end_date).getTime()
      ) {
        this.setState({
          endDateError: (
            <FormattedMessage id="End.Date.Before.Start.Date.Error" />
          ),
        });
      } else {
        this.setState({ endDateError: null });
      }
    };

    // Set state, then (asynchronously!) check data integrity
    this.setState({ [field]: value }, check);
  }

  onBlur(field, value) {
    // Update is not allowed to client
    if (!this.isUpdate || this.disabled() || this.props.user.role === 0) return;

    if (field === "collaborator_id" && value === "") value = undefined;

    // Disallow empty values
    if (value === "") return;

    var data = {
      calendarEventId: this.props.calendarEvent._id,
      updatedField: field,
      updatedValue: value,
    };

    this.props.onUpdateCalendarEventAdmin(data);
  }

  disabled() {
    return (
      this.state.loading ||
      // Uncorrect fields
      Util.emptyString(this.state.desc) ||
      !this.state.start_date ||
      Util.emptyString(this.state.start_date) ||
      !this.state.end_date ||
      Util.emptyString(this.state.end_date) ||
      !this.state.patient_id ||
      Util.emptyString(this.state.patient_id) ||
      // Errors
      this.state.endDateError
    );
  }

  delete() {
    const successCallback = () => {
      // Admin
      if (this.props.user.role !== 0) {
        this.props.onDeleteCalendarEventAdmin(
          this.props.calendarEvent._id,
          () => this.props.close()
        );
      }
      // Client
      else {
        this.props.onDeleteCalendarEventClient(
          this.props.calendarEvent._id,
          () => this.props.close()
        );
      }
    };

    this.setState({
      modal: (
        <ConfirmationModal
          isOpen={true}
          content={<FormattedMessage id="Confirm.CalendarEvent.Removal" />}
          successCallback={successCallback}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  onAcceptRdv(field, value) {
    var data = {
      calendarEventId: this.props.calendarEvent._id,
      updatedField: field,
      updatedValue: value,
    };

    this.props.onUpdateCalendarEventAdmin(data);
    this.props.close();
  }

  render() {
    const { user, patients, clients, establishmentId } = this.props;

    if (!user) return null;

    const isAdmin = user.role === Roles.ADMIN;

    const isCollaborator = user.role === Roles.SALES_REP;
    const isClient = user.role === Roles.CLIENT;

    const patientsNode = patients.map((patient) => {
      if (patient.establishment_id !== establishmentId) return null;

      return (
        <option key={patient._id} value={patient._id}>
          {patient.first_name + " " + patient.name}
        </option>
      );
    });

    var prescribersNode = [];
    if (this.state.patient_id && !Util.emptyString(this.state.patient_id)) {
      // Find selected patient
      const patient = patients.find(
        (p) => p._id.toString() === this.state.patient_id.toString()
      );

      prescribersNode = clients.map((client) => {
        // Remove ergo that were not assigned to this patient
        if (
          !patient.prescribers_ids.find(
            (ps) => ps.toString() === client._id.toString()
          )
        )
          return null;

        return (
          <option key={client._id} value={client._id}>
            {client.first_name + " " + client.name}
          </option>
        );
      });
    }

    var collaboratorsNode = [];

    if (this.props.collaborators) {
      collaboratorsNode = this.props.collaborators.map((collaborator) => {
        if (
          collaborator.role === Roles.SALES_REP &&
          collaborator.establishments &&
          collaborator.establishments.find(
            (e) => e.establishment_id === this.props.establishmentId
          )
        ) {
          return (
            <option key={collaborator._id} value={collaborator._id}>
              {collaborator.first_name + " " + collaborator.name}
            </option>
          );
        } else return null;
      });
    }

    return (
      <div>
        <div className="modal-bg show" style={{ zIndex: 4 }}>
          <Modal
            show={true}
            onHide={() => this.props.close()}
            backdrop={"static"}
            dialogClassName={"modal-dialog lg"}
            size={"lg"}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {this.isUpdate ? (
                  <FormattedMessage id="Calendar.Event.Detail" />
                ) : !isClient ? (
                  <FormattedMessage id="Add.Calendar.Event" />
                ) : (
                  <FormattedMessage id="Ask.New.Trial" />
                )}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {this.isUpdate ? (
                this.state.notValidate ? (
                  <Alert variant={"warning"}>
                    <i className="fa fa-hourglass-2"></i>{" "}
                    <FormattedMessage id="Calendar.Event.Validation.Pending" />
                  </Alert>
                ) : (
                  <Alert variant={"success"}>
                    <i className="fa fa-check-circle"></i>{" "}
                    <FormattedMessage id="Calendar.Event.Validated" />
                  </Alert>
                )
              ) : (
                ""
              )}
              <div className="form-group row">
                <CustomLabel
                  label={this.props.intl.formatMessage({ id: "Patient" })}
                  htmlFor="patient_id"
                  className="col-12 col-sm-5 col-form-label"
                  required
                />
                <div className="col-12 col-sm-7">
                  <select
                    id="patient_id"
                    className="form-control w-100 selectlist"
                    value={this.state.patient_id}
                    onBlur={(e) => this.onBlur("patient_id", e.target.value)}
                    disabled={
                      (!isAdmin && !isCollaborator && !isClient) ||
                      (isClient && this.isUpdate)
                    }
                    onChange={(e) => {
                      this.setState({ patient_id: e.target.value });
                    }}
                  >
                    <option value="">
                      {this.props.intl.formatMessage({ id: "Select" })}
                    </option>
                    {patientsNode}
                  </select>
                </div>
              </div>

              {!isClient && (
                <div className="form-group row">
                  <CustomLabel
                    label={this.props.intl.formatMessage({ id: "Therapist" })}
                    htmlFor="prescriber_id"
                    className="col-12 col-sm-5 col-form-label"
                    required
                  />
                  <div className="col-12 col-sm-7">
                    <select
                      id="prescriber_id"
                      className="form-control w-100 selectlist"
                      value={this.state.prescriber_id}
                      onBlur={(e) =>
                        this.onBlur("prescriber_id", e.target.value)
                      }
                      disabled={!isAdmin && !isCollaborator && !isClient}
                      onChange={(e) => {
                        this.setState({ prescriber_id: e.target.value });
                      }}
                    >
                      <option value="">
                        {this.props.intl.formatMessage({ id: "Select" })}
                      </option>
                      {prescribersNode}
                    </select>
                  </div>
                </div>
              )}

              {!isClient && (
                <div className="form-group row">
                  <CustomLabel
                    label={this.props.intl.formatMessage({ id: "Event.Type" })}
                    htmlFor="event_type"
                    className="col-12 col-sm-5 col-form-label"
                    required
                  />
                  <div className="col-12 col-sm-7">
                    <select
                      id="event_type"
                      className="form-control w-100 selectlist"
                      value={this.state.type}
                      onBlur={(e) => this.onBlur("type", e.target.value)}
                      disabled={!isAdmin && !isCollaborator && !isClient}
                      onChange={(e) => {
                        this.setState({ type: e.target.value });
                      }}
                    >
                      <option key="0" value="0">
                        {this.props.intl.formatMessage({
                          id: "Expression.Needs",
                        })}
                      </option>
                      <option key="1" value="1">
                        {this.props.intl.formatMessage({
                          id: "Anthropometric.Measurement",
                        })}
                      </option>
                      <option key="2" value="2">
                        {this.props.intl.formatMessage({
                          id: "Home.Assessment",
                        })}
                      </option>
                      <option key="3" value="3">
                        {this.props.intl.formatMessage({ id: "Trials" })}
                      </option>
                    </select>
                  </div>
                </div>
              )}

              {isAdmin && (
                <div className="form-group row">
                  <CustomLabel
                    label={this.props.intl.formatMessage({
                      id: "Assign.To.Collaborator",
                    })}
                    htmlFor="collaborator"
                    className="col-12 col-sm-5 col-form-label"
                    required
                  />
                  <div className="col-12 col-sm-7">
                    <select
                      id="collaborator"
                      className="form-control w-100 selectlist"
                      value={this.state.collaborator_id}
                      onBlur={(e) =>
                        this.onBlur("collaborator_id", e.target.value)
                      }
                      disabled={!isAdmin}
                      onChange={(e) => {
                        this.onChange("collaborator_id", e.target.value);
                      }}
                    >
                      <option value="">
                        {this.props.intl.formatMessage({ id: "Select" })}
                      </option>
                      {collaboratorsNode}
                    </select>
                  </div>
                </div>
              )}

              <div className="form-group row">
                <CustomLabel
                  label={this.props.intl.formatMessage({ id: "Start.Date" })}
                  htmlFor="start_date"
                  className="col-12 col-sm-5 col-form-label"
                  required
                />
                <div className="col-12 col-sm-7">
                  <input
                    className="form-control"
                    type="datetime-local"
                    id="start_date"
                    autoComplete="off"
                    value={this.state.start_date}
                    disabled={
                      (!isAdmin && !isCollaborator && !isClient) ||
                      (isClient && this.isUpdate)
                    }
                    onChange={(e) =>
                      this.onChange("start_date", e.target.value)
                    }
                    onBlur={(e) => this.onBlur("start_date", e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <CustomLabel
                  label={this.props.intl.formatMessage({ id: "End.Date" })}
                  htmlFor="end_date"
                  className="col-12 col-sm-5 col-form-label"
                  required
                />
                <div className="col-12 col-sm-7">
                  dd
                  <input
                    className="form-control"
                    type="datetime-local"
                    id="end_date"
                    autoComplete="off"
                    value={this.state.end_date}
                    disabled={
                      (!isAdmin && !isCollaborator && !isClient) ||
                      (isClient && this.isUpdate)
                    }
                    onChange={(e) => this.onChange("end_date", e.target.value)}
                    onBlur={(e) => this.onBlur("end_date", e.target.value)}
                  />
                  <div className="text-danger">
                    <small>{this.state.endDateError}</small>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <CustomLabel
                  label={this.props.intl.formatMessage({ id: "Description" })}
                  htmlFor="desc"
                  className="col-12 col-sm-5 col-form-label"
                  required
                />
                <div className="col-12 col-sm-7">
                  <textarea
                    className="form-control"
                    type="text"
                    id="desc"
                    autoComplete="off"
                    value={this.state.desc}
                    disabled={
                      (!isAdmin && !isCollaborator && !isClient) ||
                      (isClient && this.isUpdate)
                    }
                    onChange={(e) => this.onChange("desc", e.target.value)}
                    onBlur={(e) => this.onBlur("desc", e.target.value)}
                  />
                </div>
              </div>
            </Modal.Body>

            {(isAdmin || isCollaborator || isClient) && (
              <Modal.Footer>
                {!this.isUpdate ? (
                  <Button
                    variant="info"
                    onClick={() => this.onSubmit()}
                    disabled={this.disabled()}
                  >
                    <FormattedMessage id="Add" />
                  </Button>
                ) : /* In update mode, allow delete only if user role is not "client" or only if the event is not validated yet */
                this.props.user.role !== 0 ||
                  (this.state.notValidate && this.props.user.role === 0) ? (
                  <Button variant="danger" onClick={() => this.delete()}>
                    <FormattedMessage id="Delete" />
                  </Button>
                ) : (
                  ""
                )}
                {this.isUpdate &&
                  this.state.notValidate === true &&
                  this.props.user.role !== 0 && (
                    <Button
                      variant="success"
                      onClick={() => this.onAcceptRdv("notValidate", false)}
                    >
                      <FormattedMessage id="Accept.meeting" />
                    </Button>
                  )}
                <Button variant="secondary" onClick={() => this.props.close()}>
                  <FormattedMessage id="Close" />
                </Button>
              </Modal.Footer>
            )}
          </Modal>
        </div>

        {this.state.modal}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    patients: state.patients,
    clients: state.clients,
    collaborators: state.collaborators,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // ADMIN
    onAddCalendarEventAdmin: (calendarEvent, successCallback) =>
      dispatch(addCalendarEventAdmin(calendarEvent, successCallback)),
    onUpdateCalendarEventAdmin: (data) =>
      dispatch(updateCalendarEventAdmin(data)),
    onDeleteCalendarEventAdmin: (calendarEventId, successCallback) =>
      dispatch(deleteCalendarEventAdmin(calendarEventId, successCallback)),
    // CLIENT
    onAddCalendarEventClient: (calendarEvent, successCallback) =>
      dispatch(addCalendarEventClient(calendarEvent, successCallback)),
    onDeleteCalendarEventClient: (calendarEventId, successCallback) =>
      dispatch(deleteCalendarEventClient(calendarEventId, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(AddCalendarEventModal));
