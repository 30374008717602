import React from "react";
import PatientsAdmin from "../patients/Patients";
import SAVAdmin from "../patients/SAV";
import CentresAdmin from "../clients/Establishments";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Card, CardTitle } from "reactstrap";

export default class SplitHomeAdmin extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="m-2 zoom-7 overflow-hidden">
              <Card className="p-3">
                <CardTitle>
                  <h4 className="text-info">
                    <FormattedMessage id="Summary.Centres" />
                  </h4>
                </CardTitle>
                <CentresAdmin limit={5} />
                <Link to="/home/clients" className="overlay text-center">
                  <i className="fa fa-search"></i>
                </Link>
              </Card>
            </div>
          </div>

          <div className="col-12 col-lg-6 ">
            <div className="m-2 zoom-7 overflow-hidden">
              <Card className="p-3">
                <CardTitle>
                  <h4 className="text-info">
                    <FormattedMessage id="Summary.Patients.Monitoring" />
                  </h4>
                </CardTitle>
                <PatientsAdmin limit={5} />
                <Link to="/home/patients" className="overlay text-center">
                  <i className="fa fa-search"></i>
                </Link>
              </Card>
            </div>
          </div>

          <div className="col-12 col-lg-6">
            <div className="m-2 zoom-7">
              <Card className="p-3">
                <CardTitle>
                  <h4 className="text-info">
                    <FormattedMessage id="SAV" />
                  </h4>
                </CardTitle>
                <SAVAdmin limit={5} />
                {/* <Link to="/home/estimates" className="overlay">&nbsp;</Link> */}
              </Card>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
