import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import ExpressionNeedsRow from "./ExpressionNeedsRow";
import { Alert } from "react-bootstrap";

class ExpressionNeedsArray extends React.Component {
  render() {
    const { expressionsNeeds, patient } = this.props;

    var ExpressionsNeedsNode;

    if (!patient || !expressionsNeeds) return null;

    const expressionsNeedsArray = expressionsNeeds.filter(
      (expressionNeeds) => expressionNeeds.patient_id === patient._id
    );

    if (expressionsNeedsArray && expressionsNeedsArray.length > 0) {
      ExpressionsNeedsNode = expressionsNeedsArray.map((expressionNeeds) => {
        return (
          <ExpressionNeedsRow
            key={Math.random()}
            expressionNeeds={expressionNeeds}
            patient={patient}
          />
        );
      });
    }

    return (
      <React.Fragment>
        {(!expressionsNeedsArray || expressionsNeedsArray.length === 0) && (
          <Alert variant="secondary" className="mt-3">
            <FormattedMessage id="Expression.Needs.Empty" />
          </Alert>
        )}

        {expressionsNeedsArray && expressionsNeedsArray.length > 0 && (
          <table className="table tablee4vhp mt-3">
            <thead>
              <tr className="tablerowhead">
                <th className="d-none d-md-table-cell">
                  <FormattedMessage id="Date" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                <th className="d-none d-md-table-cell">
                  <FormattedMessage id="Title" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                <th className="d-none d-md-table-cell">
                  <FormattedMessage id="Model" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                <th className="d-none d-md-table-cell">
                  <FormattedMessage id="Patient.Notes" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                <th className="d-none d-md-table-cell">
                  <FormattedMessage id="Objectives" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                <th className="d-none d-md-table-cell">
                  <FormattedMessage id="Actions" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
              </tr>
            </thead>
            <tbody>{ExpressionsNeedsNode}</tbody>
          </table>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    expressionsNeeds: state.expressionNeeds,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ExpressionNeedsArray));
