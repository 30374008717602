import APIUrl from "../../APIUrl";
import axios from "axios";

export const GET_EXPRESSION_NEEDS_TYPES = "GET_EXPRESSION_NEEDS_TYPES";

function getExpressionNeedsTypesAction(expressionNeedsTypes) {
  return {
    type: GET_EXPRESSION_NEEDS_TYPES,
    expressionNeedsTypes: expressionNeedsTypes,
  };
}

export const getExpressionNeedsTypes = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getExpressionNeedsTypes)
      .then(function (response) {
        if (response) dispatch(getExpressionNeedsTypesAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addExpressionNeedsType = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addExpressionNeedsType, data)
      .then(function (response) {
        dispatch(getExpressionNeedsTypes());
        successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};
