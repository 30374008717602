import { GET_EXPRESSION_NEEDS_TYPES } from "../../actions/configurables/expressionNeedsTypes";

export default function expressionNeedsTypes(state = [], action) {
  switch (action.type) {
    case GET_EXPRESSION_NEEDS_TYPES:
      return action.expressionNeedsTypes;
    default:
      return state;
  }
}
