import { GET_CLIENTS } from "../../actions/clients/clients";

export default function clients(state = [], action) {
  switch (action.type) {
    case GET_CLIENTS:
      return action.clients;
    default:
      return state;
  }
}
