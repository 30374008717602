import React from "react";
import { Route, Switch } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import MenuSettingsAdmin from "../settings/admin/MenuSettingsAdmin";
import { connect } from "react-redux";
import { getUser } from "../../actions/user/user";
import { getRgpd } from "../../actions/rgpd/rgpd";
import { getClientsAdmin, getClients } from "../../actions/clients/clients";
import { getEstablishments } from "../../actions/establishments/establishments";
import { getEstablishment } from "../../actions/establishment/establishment";
import MenuAdmin from "./Menus/MenuAdmin";
import MenuSalesRep from "./Menus/MenuSalesRep";
import Menu from "./Menus/Menu";
import Roles from "../../enums/Roles";
import { FormattedMessage } from "react-intl";
import {
  getNotifs,
  deleteNotif,
} from "../../actions/notifications/notifications";
import SplitHomeAdmin from "./SplitHomeAdmin";
import SplitHome from "./SplitHome";
import Establishments from "../clients/Establishments";
import {
  getEstablishmentsSettings,
  getEstablishmentSettings,
} from "../../actions/establishments/establishmentSettings";
import { getCollaboratorTypes } from "../../actions/configurables/collaboratorTypes";
import { getGeneralSettings } from "../../actions/settings/generalSettings/generalSettings";
import { getCollaborators } from "../../actions/collaborators/collaborators";
import { getCompany } from "../../actions/company/company";
import { getAdmin } from "../../actions/admin/admin";
import { getBrands } from "../../actions/configurables/brands";
import { getModels } from "../../actions/configurables/models";
import RgpdModal from "../rgpd/RgpdModal";
import Account from "../account/Account";
import Patients from "../patients/Patients";
import { getPatients, getPatientsAdmin } from "../../actions/patients/patients";
import { getExpressionNeedsTypes } from "../../actions/configurables/expressionNeedsTypes";
import { getTrials, getTrialsAdmin } from "../../actions/trials/trials";
import {
  getAnthropometricMeasurementsAdmin,
  getAnthropometricMeasurements,
} from "../../actions/anthropometricMeasurement/anthropometricMeasurement";
import { getHomeAssessments } from "../../actions/homeAssessment/homeAssessment";
import { getHomeAssessmentsAdmin } from "../../actions/homeAssessment/homeAssessment";
import { getBundles } from "../../actions/settings/bundleSettings/bundle";
import { getTrialPerceptions } from "../../actions/settings/trialPerceptions/trialPerceptions";
import { getAllPatientSAV } from "../../actions/savs/savs";
import Calendar from "../calendar/CustomCalendar";
import { getExpressionsNeeds } from "../../actions/expressionNeeds/expressionNeeds";
import Statistics from "../statistics/Statistics";

class Homepage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rgpdModal: null,
    };
  }

  closeRgpdModal() {
    this.setState({ rgpdModal: null });
  }

  componentDidMount() {
    Promise.all([
      // First, fetch the user
      this.props.onGetRgpd(),
      this.props.onGetUser(),
    ]).then(() => {
      // Next things to fetch
      if (!this.props.rgpd)
        this.setState({
          rgpdModal: <RgpdModal closeRgpdModal={() => this.closeRgpdModal()} />,
        });

      this.props.onGetEstablishment();
      this.props.onGetNotifs();
      this.props.onGetCollaboratorTypes();
      this.props.onGetGeneralSettings();
      this.props.onGetExpressionNeedsTypes();
      this.props.onGetExpressionsNeeds();
      this.props.onGetBrands();
      this.props.onGetModels();

      // Client specific
      if (this.props.user.role === Roles.CLIENT) {
        this.props.onGetEstablishmentSettings();
        this.props.onGetAdmin();
        this.props.onGetPatients();
        this.props.onGetClients();
        this.props.onGetTrials();
        this.props.onGetHomeAssessments();
        this.props.onGetAnthropometricMeasurements();
      }

      // Admin & Sales rep
      if (
        this.props.user.role === Roles.SALES_REP ||
        this.props.user.role === Roles.ADMIN
      ) {
        this.props.onGetCompany();
        this.props.onGetClientsAdmin();
        this.props.onGetEstablishments();
        this.props.onGetPatientsAdmin();
        this.props.onGetAnthropometricMeasurementsAdmin();
        this.props.onGetHomeAssessmentsAdmin();
        this.props.onGetTrialPerceptions();
        this.props.onGetTrialsAdmin();
        this.props.onGetAllPatientSAV();
        this.props.onGetCollaborators();
      }

      // Admin specific
      if (this.props.user.role === Roles.ADMIN) {
        this.props.onGetAdmin();
        this.props.onGetTrialsAdmin();
        this.props.onGetAnthropometricMeasurementsAdmin();
        this.props.onGetHomeAssessmentsAdmin();
        this.props.onGetTrialPerceptions();
        this.props.onGetBundles();
      }
    });
  }

  closeNotifModal(notif) {
    this.props.onDeleteNotif({ notifId: notif._id });
  }

  render() {
    var hrefTerms = "tou-" + this.props.user.lang + ".html";

    // Display a link to release notes ONLY if user is an admin or a sales_rep. If user is a client, we only display version number
    var releaseNotesLink =
      this.props.user.role !== Roles.CLIENT ? (
        <a
          href={"release-notes-" + this.props.user.lang + ".html"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="Version" /> {process.env.REACT_APP_VERSION}
        </a>
      ) : (
        <span>
          <FormattedMessage id="Version" /> {process.env.REACT_APP_VERSION}
        </span>
      );

    var menu = <Menu />;
    var switchComponent = <SwitchComponent />;

    if (this.props.user.role === Roles.SALES_REP) {
      menu = <MenuSalesRep />;
      switchComponent = <SwitchComponentSalesRep />;
    }

    if (this.props.user.role === Roles.ADMIN) {
      menu = <MenuAdmin />;
      switchComponent = <SwitchComponentAdmin />;
    }

    return (
      <React.Fragment>
        {menu}

        <div className="container-fluid p-3 animated fadeIn maindiv">
          {switchComponent}
        </div>

        {this.state.rgpdModal}
        <div className="footerLinks p-2 text-center">
          <a href={hrefTerms} target="_blank" rel="noopener noreferrer">
            <FormattedMessage id="General.Conditions" />
          </a>{" "}
          | {releaseNotesLink}
        </div>
        <NotificationContainer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rgpd: state.rgpd,
    user: state.user,
    notifications: state.notifications,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetUser: () => dispatch(getUser()),
    onGetRgpd: () => dispatch(getRgpd()),
    onGetNotifs: () => dispatch(getNotifs()),
    onDeleteNotif: (data) => dispatch(deleteNotif(data)),
    onGetCollaboratorTypes: () => dispatch(getCollaboratorTypes()),
    onGetEstablishmentSettings: () => dispatch(getEstablishmentSettings()),
    onGetEstablishment: () => dispatch(getEstablishment()),
    onGetAdmin: () => dispatch(getAdmin()),
    onGetGeneralSettings: () => dispatch(getGeneralSettings()),
    onGetClients: () => dispatch(getClients()),
    onGetPatients: () => dispatch(getPatients()),
    onGetTrials: () => dispatch(getTrials()),
    onGetExpressionsNeeds: () => dispatch(getExpressionsNeeds()),
    onGetHomeAssessments: () => dispatch(getHomeAssessments()),
    onGetAnthropometricMeasurements: () =>
      dispatch(getAnthropometricMeasurements()),
    onGetBrands: () => dispatch(getBrands()),
    onGetModels: () => dispatch(getModels()),

    // ADMIN & SALES_REP
    onGetClientsAdmin: () => dispatch(getClientsAdmin()),
    onGetEstablishments: () => dispatch(getEstablishments()),
    onGetEstablishmentsSettings: () => dispatch(getEstablishmentsSettings()),
    onGetCompany: () => dispatch(getCompany()),
    onGetAllPatientSAV: () => dispatch(getAllPatientSAV()),
    onGetCollaborators: () => dispatch(getCollaborators()),

    // ADMIN only
    onGetPatientsAdmin: () => dispatch(getPatientsAdmin()),
    onGetExpressionNeedsTypes: () => dispatch(getExpressionNeedsTypes()),
    onGetAnthropometricMeasurementsAdmin: () =>
      dispatch(getAnthropometricMeasurementsAdmin()),
    onGetHomeAssessmentsAdmin: () => dispatch(getHomeAssessmentsAdmin()),
    onGetTrialsAdmin: () => dispatch(getTrialsAdmin()),
    onGetBundles: () => dispatch(getBundles()),
    onGetTrialPerceptions: () => dispatch(getTrialPerceptions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);

class SwitchComponent extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/home/patients" component={Patients} />
        <Route path="/home/account" component={Account} />
        <Route path="/home/calendar" component={Calendar} />
        <Route path="/home" component={SplitHome} />
      </Switch>
    );
  }
}

class SwitchComponentSalesRep extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/home/account" component={Account} />
        <Route path="/home/settings" component={MenuSettingsAdmin} />
        <Route path="/home/clients" component={Establishments} />
        <Route path="/home/patients" component={Patients} />
        <Route path="/home/calendar" component={Calendar} />
        <Route path="/home" component={SplitHomeAdmin} />
      </Switch>
    );
  }
}

class SwitchComponentAdmin extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/home/account" component={Account} />
        <Route path="/home/settings" component={MenuSettingsAdmin} />
        <Route path="/home/clients" component={Establishments} />
        <Route path="/home/patients" component={Patients} />
        <Route path="/home/calendar" component={Calendar} />
        <Route path="/home/statistics" component={Statistics} />
        <Route path="/home" component={SplitHomeAdmin} />
      </Switch>
    );
  }
}
