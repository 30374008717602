import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../util/Util";
import { updPatient } from "../../actions/patients/patients";
import Roles from "../../enums/Roles";
import { UncontrolledTooltip } from "reactstrap";

class PatientOccupationalTherapist extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prescriber_id: "",

      disabled: false,
    };
  }

  onChange(field, value) {
    this.setState({ [field]: value });
  }

  addPrescriber() {
    if (Util.emptyString(this.state.prescriber_id) || this.state.disabled)
      return;

    this.setState({ disabled: true, prescriber_id: "" });

    /* If multiple therapists are allowed */
    // var successCallback = () => this.setState({ disabled: false });

    /* If one only therapist are allowed */
    var successCallback = () => this.setState({ disabled: false });

    var clonedPatient = Util.shallowClone(this.props.patient);
    if (!clonedPatient.prescribers_ids) clonedPatient.prescribers_ids = [];

    clonedPatient.prescribers_ids.push(this.state.prescriber_id);
    this.props.onUpdPatient(clonedPatient, successCallback);
  }

  removePrescriber(patient, prescriberId) {
    if (this.state.disabled) return;

    var clonedPatient = Util.shallowClone(patient);
    clonedPatient.prescribers_ids = clonedPatient.prescribers_ids.filter(
      (id) => id !== prescriberId
    );
    if (
      patient.main_prescriber_id &&
      patient.main_prescriber_id === prescriberId
    )
      clonedPatient.main_prescriber_id = null;

    /* If multiple therapists are allowed */
    // var successCallback = () => this.setState({ prescriber_id: "" });

    /* If one therapists are allowed */
    var successCallback = () => this.setState({ prescriber_id: "" });

    this.props.onUpdPatient(clonedPatient, successCallback);
  }

  disabled() {
    return Util.emptyString(this.state.prescriber_id) || this.state.disabled;
  }

  setMainPrescriber(patient, prescriberId) {
    if (this.state.disabled) return;

    var clonedPatient = Util.shallowClone(patient);

    if (
      patient.main_prescriber_id &&
      patient.main_prescriber_id === prescriberId
    ) {
      clonedPatient.main_prescriber_id = null;
    } else {
      clonedPatient.main_prescriber_id = prescriberId;
    }

    this.props.onUpdPatient(clonedPatient);
  }

  render() {
    const { patient, clients } = this.props;
    if (!patient) return null;

    var prescribersNode = clients.map((p) => {
      if (p.establishment_id.toString() !== patient.establishment_id.toString())
        return null;

      var disabled = false;
      if (patient.prescribers_ids)
        disabled = patient.prescribers_ids.find((pId) => p._id === pId);
      return (
        <option value={p._id} key={p._id} disabled={disabled}>
          {p.username} {p.name}
        </option>
      );
    });

    var prescribersAssignmentsNodes = patient.prescribers_ids.map((id) => {
      var prescriber = clients.find((p) => p._id === id);
      if (!prescriber) return null;

      return (
        <tr key={"prescriber-" + prescriber._id}>
          <td>{prescriber.first_name}</td>
          <td>{prescriber.name}</td>
          {this.props.user.role !== Roles.CLIENT && (
            <td>
              <div
                id={"principal-" + prescriber._id}
                className={
                  "d-inline-block principal-button " +
                  (patient.main_prescriber_id &&
                  patient.main_prescriber_id === prescriber._id
                    ? "principal-button-active"
                    : "")
                }
                onClick={(e) => this.setMainPrescriber(patient, prescriber._id)}
              >
                P
              </div>
              <UncontrolledTooltip
                delay={{ show: 0, hide: 0 }}
                placement="top"
                target={"principal-" + prescriber._id}
              >
                <FormattedMessage id="Principal.Therapist" />
              </UncontrolledTooltip>
              <i
                id={"delete-" + prescriber._id}
                className="fa fa-trash icon-big tdaction"
                onClick={(e) => this.removePrescriber(patient, prescriber._id)}
              ></i>
              <UncontrolledTooltip
                delay={{ show: 0, hide: 0 }}
                placement="top"
                target={"delete-" + prescriber._id}
              >
                <FormattedMessage id="Delete" />
              </UncontrolledTooltip>
            </td>
          )}
        </tr>
      );
    });

    return (
      <React.Fragment>
        {this.props.user.role !== Roles.CLIENT && (
          <div className="col-12 col-lg-9 col-xl-8">
            <div className="form-group row">
              <label
                htmlFor="prescriber_id"
                className="col-12 col-md-5 col-form-label"
              >
                <FormattedMessage id="Occupational.Therapists" />
              </label>
              <div className="col-11 col-md-6">
                <select
                  className="form-control d-inline"
                  id="prescriber_id"
                  value={this.state.prescriber_id}
                  onChange={(e) =>
                    this.onChange("prescriber_id", e.target.value)
                  }
                >
                  <option value=""></option>
                  {prescribersNode}
                </select>
              </div>

              <div className="col-1 text-left align-left float-left p-0">
                <i
                  className="fa fa-plus-square icon-huge hoverable clickable d-inline"
                  onClick={() => {
                    if (!this.disabled()) this.addPrescriber();
                  }}
                  disabled={this.disabled()}
                />
              </div>
            </div>
          </div>
        )}

        {patient &&
          patient.prescribers_ids &&
          patient.prescribers_ids.length > 0 && (
            <table className="table col-12 tablee4vhp mt-5">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="First.Name" />
                  </th>
                  <th>
                    <FormattedMessage id="Name" />
                  </th>
                  {this.props.user.role !== Roles.CLIENT && (
                    <th>
                      <FormattedMessage id="Actions" />
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>{prescribersAssignmentsNodes}</tbody>
            </table>
          )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    clients: state.clients,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdPatient: (patient, successCallback) =>
      dispatch(updPatient(patient, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(PatientOccupationalTherapist));
