import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import UpdateAccountForm from "./UpdateAccountForm";
import UpdatePwdForm from "./UpdatePwdForm";
import Contacts from "./Contacts";

import Role from "../../enums/Roles";

import Util from "../../util/Util";
import { Tab, Tabs } from "react-bootstrap";

class Account extends React.Component {
  render() {
    if (Util.emptyObject(this.props.user)) return null;
    if (
      this.props.user.role === Role.CLIENT &&
      Util.emptyObject(this.props.establishment)
    )
      return null;

    var establishment = null;

    if (this.props.user.role === Role.CLIENT) {
      establishment = this.props.establishment;
    }

    return (
      <React.Fragment>
        <Tabs defaultActiveKey="general" id="tab-account" className="mb-3">
          <Tab
            eventKey="general"
            title={<FormattedMessage id="My.Informations" />}
          >
            <UpdateAccountForm
              user={this.props.user}
              establishment={establishment}
            />
          </Tab>

          <Tab
            eventKey="profile"
            title={<FormattedMessage id="Change.Password" />}
          >
            <UpdatePwdForm />
          </Tab>

          {this.props.user.role === Role.CLIENT && (
            <Tab eventKey="contact" title={<FormattedMessage id="Contacts" />}>
              <Contacts
                admin={this.props.admin}
                generalSettings={this.props.generalSettings}
              />
            </Tab>
          )}
        </Tabs>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    establishment: state.establishment,
    admin: state.admin,
    generalSettings: state.generalSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
