import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import MenuProfile from "./MenuProfile";
import APIUrl from "../../../APIUrl";
import { NavLink } from "react-router-dom";
import MenuLogo from "./MenuLogo";
import { logout } from "../../../actions/authentication/authentication";
import { Link } from "react-router-dom";
import Util from "../../../util/Util";

class MenuAdmin extends React.Component {
  logout(e) {
    e.preventDefault();
    e.stopPropagation();

    // Store a copy of the token before deleting it
    let token = APIUrl.jwtToken;

    // let that = this;

    function onLogoutSuccess() {
      // that.props.history.push('/');
      return window.location.replace(
        Util.checkHttps(process.env.REACT_APP_LOGIN_URL) + token
      );
    }

    // Call logout and redirect on success
    this.props.onLogout(onLogoutSuccess);
  }

  render() {
    return (
      <nav className="navbar navbar-expand-lg menu">
        <div
          id="menu"
          className="collapse navbar-collapse mb-3 mb-md-0 mt-2 pb-2"
        >
          <ul className="navbar-nav list-inline text-center align-items-center">
            <li className="nav-item list-inline-item align-middle">
              <Link
                to="/#"
                className="logo-item"
                onClick={(e) => this.logout(e)}
              >
                <img
                  className="logo-header"
                  alt="logo e4vhp"
                  src="./images/svg/logo-header-e4vhp.svg"
                />
              </Link>
            </li>

            <li className="nav-item list-inline-item align-middle text-nowrap">
              <NavLink
                className="w-100 p-3"
                to="/home"
                exact
                activeClassName="selected"
              >
                <FormattedMessage id="Home" />
              </NavLink>
            </li>

            <li className="nav-item list-inline-item align-middle text-nowrap">
              <NavLink
                className="w-100 p-3"
                to="/home/clients"
                exact
                activeClassName="selected"
              >
                <FormattedMessage id="Clients" />
              </NavLink>
            </li>

            <li className="nav-item list-inline-item align-middle text-nowrap">
              <NavLink
                className="w-100 p-3"
                to="/home/patients"
                exact
                activeClassName="selected"
              >
                <FormattedMessage id="Patients.Monitoring" />
              </NavLink>
            </li>

            <li className="nav-item list-inline-item align-middle text-nowrap">
              <NavLink
                className="w-100 p-3"
                to="/home/calendar"
                exact
                activeClassName="selected"
              >
                <FormattedMessage id="Calendar" />
              </NavLink>
            </li>

            <li className="nav-item list-inline-item align-middle text-nowrap">
              <NavLink
                className="w-100 p-3"
                to="/home/statistics"
                exact
                activeClassName="selected"
              >
                <FormattedMessage id="Statistics" />
              </NavLink>
            </li>

            <li className="nav-item list-inline-item align-middle text-nowrap">
              <NavLink
                className="w-100 p-3"
                to="/home/settings"
                exact
                activeClassName="selected"
              >
                <FormattedMessage id="Settings" />
              </NavLink>
            </li>

            <MenuLogo generalSettings={this.props.generalSettings} />

            <li className="menuProfile nav-item list-inline-item align-middle text-nowrap">
              <MenuProfile />
            </li>
          </ul>
        </div>

        <button
          className="navbar-toggler m-auto"
          type="button"
          data-toggle="collapse"
          data-target="#menu"
          aria-controls="menu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
      </nav>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    generalSettings: state.generalSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: (successCallback) => dispatch(logout(successCallback)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MenuAdmin)
);
