import { GET_ESTABLISHMENTS } from "../../actions/establishments/establishments";

export default function establishments(state = [], action) {
  switch (action.type) {
    case GET_ESTABLISHMENTS:
      return action.establishments;
    default:
      return state;
  }
}
