import APIUrl from "../../../APIUrl";
import axios from "axios";

export const GET_BUNDLES = "GET_BUNDLES";

export function getBundleAction(bundles) {
  return { type: GET_BUNDLES, bundles: bundles };
}

export const getBundles = function (callback) {
  return function (dispatch) {
    return axios.get(APIUrl.getBundles).then(function (response) {
      dispatch(getBundleAction(response.data));
      if (callback) callback();
    });
  };
};

export const addBundleType = function (bundleType, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addBundleType, bundleType)
      .then(function (response) {
        dispatch();
        return successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addBundle = function (bundle, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addBundle, bundle)
      .then(function (response) {
        dispatch(getBundles());
        return successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateBundle = function (data) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updBundle, data)
      .then(function (response) {
        dispatch(getBundles());
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteBundle = function (bundle, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteBundle, bundle)
      .then(function (response) {
        dispatch(getBundles());
        if (successCallback) successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};
