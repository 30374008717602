import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { updPatient } from "../../actions/patients/patients";
import { addExpressionNeedsType } from "../../actions/configurables/expressionNeedsTypes";
import ExpressionNeedModal from "./expressionNeeds/ExpressionNeedsModal";
import Util from "../../util/Util";
import DateUtil from "../../util/DateUtil";
// import ComboBox from "../sub/ComboBox";
// import Roles from '../../enums/Roles';
import ExpressionNeedsArray from "./expressionNeeds/ExpressionNeedsArray";

class PatientExpressionNeed extends React.Component {
  constructor(props) {
    super(props);

    const { patient } = this.props;

    this.isUpdate = !!patient;

    const getValue = (field) => {
      if (patient) {
        if (patient.expressions_needs) {
          if (patient.expressions_needs[field]) {
            return patient.expressions_needs[field];
          }
        }
      }
      return "";
    };

    this.state = {
      type: getValue("type"),
      prescriber_expected_objectives: getValue(
        "prescriber_expected_objectives"
      ),
      envisaged_models: getValue("envisaged_models"),
      patient_notes: getValue("patient_notes"),
      date: DateUtil.toyyyyMMdd(getValue("date")),
      nameError: null,
      disabled: false,
      loading: false,

      modal: null,
    };
  }

  stopEvent(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  onChange(field, value) {
    this.setState({ [field]: value });
  }

  // onBlur(field, value) {
  //     if (!this.isUpdate) return;
  //     const { patient } = this.props;

  //     // Required fields
  //     if (field === "type" && Util.emptyString(this.state.type)) return;
  //     else if (field === "expression_needs" && Util.emptyString(this.state.expression_needs)) return;

  //     var clonedPatient = Util.shallowClone(patient);
  //     if (!clonedPatient.expressions_needs) clonedPatient.expressions_needs = {};
  //     clonedPatient.expressions_needs[field] = this.state[field];

  //     this.props.onUpdPatient(clonedPatient);

  // }

  disabled() {
    return (
      Util.emptyString(this.state.type) ||
      Util.emptyString(this.state.expression_needs) ||
      this.state.loading
    );
  }

  onFieldChange(field, value) {
    this.setState({ [field]: value });

    // Careful with name : must be unique!
    if (field === "name") {
      if (!this.isNameUnique(value)) {
        this.setState({
          nameError: (
            <FormattedMessage id="Expression.Need.Type.Already.Exists" />
          ),
        });
        return;
      } else {
        this.setState({ nameError: null });
      }
    }
  }

  isNameUnique(name) {
    for (let expressionNeedsType of this.props.expressionNeedsTypes) {
      if (expressionNeedsType.name.toLowerCase() === name.toLowerCase()) {
        if (
          this.props.expressionNeedsTypes &&
          this.props.expressionNeedsTypes._id === expressionNeedsType._id
        )
          continue;
        return false;
      }
    }

    return true;
  }

  openModal(patient) {
    this.setState({ modal: null }, () =>
      this.setState({
        modal: (
          <ExpressionNeedModal
            isOpen={true}
            closeModal={() => this.closeModal()}
            patient={patient}
            date={this.state.date}
          />
        ),
      })
    );
  }

  closeModal() {
    this.setState({
      modal: null,
      pdf_title: "",
      type: "",
      prescriber_expected_objectives: "",
      envisaged_models: "",
      patient_notes: "",
      date: "",
      establishment_id: "",
      client_id: "",
    });
  }

  render() {
    // var disable = false;
    // if (this.props.user.role === Roles.CLIENT) disable = true;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 pt-3">
            {/* <div className="form-group row">
                            <label htmlFor="type" className="col-12 col-sm-5 col-form-label"><FormattedMessage id="Type" /></label>
                            <div id="establishment-type-id" className="col-12 col-md-7">

                                <select className="form-control" onChange={(e) => this.onChange("type", e.target.value)} defaultValue={this.state.type} value={this.state.type}>
                                    <option></option>
                                    <option value={this.props.intl.formatMessage({ id: "First.Equipment" })}>
                                        {this.props.intl.formatMessage({ id: "First.Equipment" })}
                                    </option>
                                    <option value={this.props.intl.formatMessage({ id: "Renewal" })}>{this.props.intl.formatMessage({ id: "Renewal" })}</option>
                                </select>

                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="date" className="col-12 col-sm-5 col-form-label"><FormattedMessage id="Date" /></label>
                            <div className="col-12 col-sm-7">
                                <input className="form-control" type="date" value={this.state.date} id="date" onChange={(e) => this.onChange("date", e.target.value)} disabled={disable} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="prescriber_expected_objectives" className="col-12 col-sm-5 col-form-label"><FormattedMessage id="Prescriber.Expected.Objectives" /></label>
                            <div className="col-12 col-sm-7">
                                <textarea className="form-control" type="text" value={this.state.prescriber_expected_objectives} id="prescriber_expected_objectives" rows="7" onChange={(e) => this.onChange("prescriber_expected_objectives", e.target.value)} disabled={disable} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="patient_notes" className="col-12 col-sm-5 col-form-label"><FormattedMessage id="Patient.Notes" /></label>
                            <div className="col-12 col-sm-7">
                                <textarea className="form-control" type="text" value={this.state.patient_notes} id="patient_notes" rows="7" onChange={(e) => this.onChange("patient_notes", e.target.value)} disabled={disable} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="envisaged_models" className="col-12 col-sm-5 col-form-label"><FormattedMessage id="Envisaged.Model" /></label>
                            <div className="col-12 col-sm-7">
                                <textarea className="form-control" type="text" value={this.state.envisaged_models} id="envisaged_models" rows="7" onChange={(e) => this.onChange("envisaged_models", e.target.value)} disabled={disable} />
                            </div>
                        </div>

                        <button type="button" className="btn btn-info btn-block col-7 m-auto" disabled={this.state.disabled} onClick={() => this.openModal(this.props.patient)}><FormattedMessage id="Proceed.To.Signature" /></button> */}

            <ExpressionNeedsArray patient={this.props.patient} />
          </div>
        </div>

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    expressionNeedsTypes: state.expressionNeedsTypes,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdPatient: (patient) => dispatch(updPatient(patient)),
    onAddExpressionNeedsType: (data, successCallback) =>
      dispatch(addExpressionNeedsType(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(PatientExpressionNeed));
