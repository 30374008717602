import APIUrl from "../../APIUrl";
import axios from "axios";

export const GET_MODELS = "GET_MODELS";

function getModelsAction(models) {
  return { type: GET_MODELS, models: models };
}

export const getModels = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getModels)
      .then(function (response) {
        if (response) dispatch(getModelsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addModel = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addModel, data)
      .then(function (response) {
        dispatch(getModels());
        if (response) successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateModel = function (data) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateModel, data)
      .then(function (response) {
        dispatch(getModels());
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const removeModel = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.removeModel, data)
      .then(function (response) {
        dispatch(getModels());
        // if(response) console.log(response);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addModelImg = function (
  modelId,
  data,
  successCallback
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addModelImg + modelId, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        dispatch(getModels());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteModelImg = function (
  modelId,
  successCallback
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteModelImg , {modelId: modelId} )
      .then(function (response) {
        dispatch(getModels());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

