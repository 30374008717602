import { GET_ANTHROPOMETRIC_MEASUREMENTS } from "../../actions/anthropometricMeasurement/anthropometricMeasurement";

export default function AnthropometricMeasurements(state = [], action) {
  switch (action.type) {
    case GET_ANTHROPOMETRIC_MEASUREMENTS:
      return action.AnthropometricMeasurements;

    default:
      return state;
  }
}
