import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import AddPatientHelperModal from "./AddPatientHelperModal";
import { updPatient } from "../../actions/patients/patients";
import Util from "../../util/Util";
import ConfirmationModal from "../sub/modals/ConfirmationModal";
import Roles from "../../enums/Roles";
import { UncontrolledTooltip } from "reactstrap";
import { Alert } from "react-bootstrap";

class PatientHelpers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };
  }

  openModal(patient, helper) {
    this.setState({
      modal: (
        <AddPatientHelperModal
          patient={patient}
          helper={helper}
          close={() => this.closeModal()}
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  delete(patient, helper) {
    if (!patient || !helper) return;

    const onConfirm = () => {
      var clonedPatient = Util.shallowClone(patient);

      clonedPatient.helpers = clonedPatient.helpers.filter(
        (h) => h._id !== helper._id
      );

      // Send to BE
      this.props.onUpdPatient(clonedPatient);
    };

    this.setState({
      modal: (
        <ConfirmationModal
          isOpen={true}
          title={<FormattedMessage id="Confirm" />}
          content={<FormattedMessage id="Confirm.Helper.Removal" />}
          successCallback={onConfirm}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  render() {
    const { patient } = this.props;

    var patientHelpersNode;
    if (patient && patient.helpers) {
      patientHelpersNode = patient.helpers.map((h) => {
        return (
          <tr key={"helper-" + h._id} className={"order-tr order-tr"}>
            <td className="d-md-table-cell mw-200 align-middle">{h.name}</td>
            <td className="d-md-table-cell mw-200 align-middle">
              {h.first_name}
            </td>
            <td className="d-none d-md-table-cell mw-200 align-middle">
              {h.link}
            </td>
            <td className="d-none d-md-table-cell mw-200 align-middle">
              {h.other}
            </td>
            {this.props.user.role !== Roles.CLIENT && (
              <td className="d-none d-md-table-cell mw-200 align-middle">
                <i
                  id={"modify-" + h._id}
                  className="fa fa-edit icon-big"
                  onClick={(e) => this.openModal(patient, h)}
                ></i>
                <UncontrolledTooltip
                  delay={{ show: 0, hide: 0 }}
                  placement="top"
                  target={"modify-" + h._id}
                >
                  <FormattedMessage id="Modify" />
                </UncontrolledTooltip>
                <i
                  id={"delete-" + h._id}
                  className="fa fa-trash icon-big"
                  onClick={(e) => this.delete(patient, h)}
                ></i>
                <UncontrolledTooltip
                  delay={{ show: 0, hide: 0 }}
                  placement="top"
                  target={"delete-" + h._id}
                >
                  <FormattedMessage id="Delete" />
                </UncontrolledTooltip>
              </td>
            )}
          </tr>
        );
      });
    }

    return (
      <React.Fragment>
        {this.props.user.role !== Roles.CLIENT && (
          <button
            className="btn btn-info m-auto hoverable"
            onClick={(e) => this.openModal(patient)}
          >
            <FormattedMessage id="Patient.Helper.Add" />
          </button>
        )}
        {(!patient || !patient.helpers || patient.helpers.length <= 0) && (
          <Alert variant="secondary" className="mt-3">
            <FormattedMessage
              id={"Empty.Patient.Helpers." + this.props.user.role}
            />
          </Alert>
        )}

        {patient && patient.helpers && patient.helpers.length > 0 && (
          <table className="table tablee4vhp mt-3">
            <thead>
              <tr className="tablerowhead">
                <th className="d-md-table-cell">
                  <FormattedMessage id="Name" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                <th className="d-md-table-cell">
                  <FormattedMessage id="First.Name" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                <th className="d-none d-md-table-cell">
                  <FormattedMessage id="Link" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                <th className="d-none d-md-table-cell">
                  <FormattedMessage id="Other" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                {this.props.user.role !== Roles.CLIENT && (
                  <th className="d-none d-md-table-cell">
                    <FormattedMessage id="Actions" />{" "}
                    <i className="fa fa-chevron-down float-right mr-5"></i>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>{patientHelpersNode}</tbody>
          </table>
        )}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdPatient: (patient, successCallback) =>
      dispatch(updPatient(patient, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(PatientHelpers));
