import APIUrl from "../../../APIUrl";
import axios from "axios";

export const GET_TRIAL_PERCEPTIONS = "GET_TRIAL_PERCEPTIONS";

function getTrialPerceptionsAction(trialPerceptions) {
  return { type: GET_TRIAL_PERCEPTIONS, trialPerceptions: trialPerceptions };
}

export const getTrialPerceptions = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getTrialPerceptions)
      .then(function (response) {
        dispatch(getTrialPerceptionsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addTrialPerception = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addTrialPerception, data)
      .then(function (response) {
        dispatch(getTrialPerceptions());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteTrialPerception = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteTrialPerception, data)
      .then(function (response) {
        dispatch(getTrialPerceptions());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateTrialPerception = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateTrialPerception, data)
      .then(function (response) {
        dispatch(getTrialPerceptions());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};
