import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import Collaborator from "./Collaborator";
import Assignments from "./Assignments";
import { Modal, Tab, Tabs } from "react-bootstrap";

class CollaboratorsModal extends React.Component {
  close() {
    this.props.close();
  }

  stopEvent(e) {
    e.stopPropagation();
  }

  getCollaborator(collaboratorId) {
    if (!collaboratorId) return null;

    for (let collaborator of this.props.collaborators) {
      if (collaborator._id === collaboratorId) return collaborator;
    }
  }

  render() {
    var collaborator = this.getCollaborator(this.props.collaboratorId);

    var aClassName = collaborator ? "" : "disabled";

    return (
      <div className="modal-bg show">
        <Modal
          show={true}
          onHide={() => this.close()}
          backdrop={"static"}
          dialogClassName={"modal-dialog xl"}
          size={"xl"}
        >
          <Modal.Body>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.close()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <Tabs
              defaultActiveKey="collaborator-info"
              id="collaborator-tab"
              className="mb-3"
            >
              <Tab
                eventKey="collaborator-info"
                title={<FormattedMessage id="Informations" />}
              >
                <Collaborator
                  company={this.props.company}
                  collaborator={collaborator}
                  onCollaboratorCreated={(collaborator) =>
                    this.props.onCollaboratorCreated(collaborator)
                  }
                />
              </Tab>
              <Tab
                eventKey="collaborator-settings"
                title={<FormattedMessage id="Assignments" />}
                disabled={aClassName}
              >
                <Assignments collaborator={collaborator} />
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    collaborators: state.collaborators,
    company: state.company,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollaboratorsModal);
