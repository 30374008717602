import { GET_TRIAL_PERCEPTIONS } from "../../../actions/settings/trialPerceptions/trialPerceptions";

export default function trialPerceptions(state = [], action) {
  switch (action.type) {
    case GET_TRIAL_PERCEPTIONS:
      return action.trialPerceptions;
    default:
      return state;
  }
}
