import { GET_BUNDLES } from "../../actions/settings/bundleSettings/bundle";

export default function bundles(state = [], action) {
  switch (action.type) {
    case GET_BUNDLES:
      return action.bundles;
    default:
      return state;
  }
}
