import { GET_ESTABLISHMENTS_SETTINGS } from "../../actions/establishments/establishmentSettings";

export default function establishmentsSettings(state = [], action) {
  switch (action.type) {
    case GET_ESTABLISHMENTS_SETTINGS:
      return action.establishmentsSettings;
    default:
      return state;
  }
}
