import APIUrl from "../../APIUrl";
import axios from "axios";

export const GET_ESTABLISHMENT = "GET_ESTABLISHMENT";

function getEstablishmentAction(establishment) {
  return { type: GET_ESTABLISHMENT, establishment: establishment };
}

export const getEstablishment = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getEstablishment)
      .then(function (response) {
        if (response) dispatch(getEstablishmentAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};
