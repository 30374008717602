export default class DateUtil {
  static toDate(date) {
    date = new Date(date);
    let dd = date.getDate();
    let MM = date.getMonth() + 1;
    const yyyy = date.getFullYear();

    if (dd < 10) dd = `0${dd}`;
    if (MM < 10) MM = `0${MM}`;

    return `${dd}/${MM}/${yyyy}`;
  }

  // Format : "2014-12-08T15:43:00"
  static toDateTime(date) {
    date = new Date(date);
    let dd = date.getDate();
    let MM = date.getMonth() + 1;
    const yyyy = date.getFullYear();

    if (dd < 10) dd = `0${dd}`;
    if (MM < 10) MM = `0${MM}`;

    let hh = date.getHours();
    let mm = date.getMinutes();
    let ss = date.getSeconds();

    if (hh < 10) hh = `0${hh}`;
    if (mm < 10) mm = `0${mm}`;
    if (ss < 10) ss = `0${ss}`;

    return `${yyyy}-${MM}-${dd}T${hh}:${mm}:${ss}`;
  }

  static toyyyyMMdd(date) {
    if (!date) return "";

    var inputDate = new Date(date);

    var year = inputDate.getFullYear();
    var month = inputDate.getMonth() + 1;
    var day = inputDate.getDate();

    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;

    var formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  static toyyyyMMddPatient(date) {
    // if (!date) return "";
    var inputDate = new Date(date);

    var year = inputDate.getFullYear();
    var month = inputDate.getMonth() + 1;
    var day = inputDate.getDate();
    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;

    if (year.toString().length === 1) {
      year = "000" + year.toString();
    }
    if (year.toString().length === 2) {
      year = "00" + year.toString();
    }
    if (year.toString().length === 3) {
      year = "0" + year.toString();
    }

    var formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  static toDateWithHour(date) {
    date = new Date(date);
    let dd = date.getDate();
    let MM = date.getMonth() + 1;
    const yyyy = date.getFullYear();

    if (dd < 10) dd = `0${dd}`;
    if (MM < 10) MM = `0${MM}`;

    let hh = date.getHours();
    let mm = date.getMinutes();

    if (mm < 10) mm = `0${mm}`;

    return `${dd}/${MM}/${yyyy} ${hh}:${mm}`;
  }

  static addYears(date, nbr) {
    var d = new Date(date);
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    return new Date(year + nbr, month, day);
  }

  static daysBetween(d0, d1) {
    var millisecondsPerDay = 24 * 60 * 60 * 1000;
    return Math.round(
      (new Date(d0).getTime() - new Date(d1).getTime()) / millisecondsPerDay
    );
  }
}
