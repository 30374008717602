import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, Modal } from "react-bootstrap";

import Util from "../../../../../util/Util";
import StringUtil from "../../../../../util/StringUtil";

import CustomLabel from "../../../../sub/CustomLabel";
import FileDropZone from "../../../../sub/FileDropZone";

import {
  addModel,
  addModelImg,
  deleteModelImg,
  updateModel,
} from "../../../../../actions/configurables/models";
import MenuButton from "../../../../sub/bootstrap/MenuButton";
import APIUrl from "../../../../../APIUrl";
import UploadCustomImgModal from "../../../../sub/modals/UploadCustomImgModal";

class ModelsAdminModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      associateBrand: "",
      option: "",
      brandId: "",
      simModels: [], // Detect similarities to avoid duplicates
      file: null,

      nameError: null,
      associateBrandError: null,
      disabled: false,
      modal: null,

    };

    if (this.props.modelId) {
      var model = this.getModel(this.props.modelId);

      this.state = {
        name: model.name,
        brandId: model.brand_id,
        option: model.option || "",
        simModels: [], // Detect similarities to avoid duplicates

        nameError: null,
        associateBrandError: null,
        disabled: false,
        modal: null,
      };
    }
  }

  getModel(modelId) {
    for (let model of this.props.models) {
      if (model._id === modelId) return model;
    }
  }

  close() {
    this.props.closeModal();
  }

  onChange(fieldName, fieldValue) {
    if (fieldName === "name") {
      // Empty? Return error
      if (Util.emptyString(fieldValue.trim())) {
        return this.setState({
          name: fieldValue,
          nameError: <FormattedMessage id="Brand.Empty.Error" />,
        });
      } else {
        this.checkDuplicate(fieldName, fieldValue);
      }
    }

    this.setState({
      [fieldName]: fieldValue,
      disabled: false,
    });
  }

  //Check that this model does not already exist (for this brand only - duplicates are allowed among brands)
  checkDuplicate(fieldName, fieldValue) {
    this.setState({ nameError: false });
    let tmpSimModels = [];

    outer: for (let model of this.props.models) {
      // Full duplicate (model and brand)
      if (
        model.name.toUpperCase() ===
          (fieldName === "name" ? fieldValue : this.state.name) &&
        model.brand_id ===
          (fieldName === "brandId" ? fieldValue : this.state.brandId)
      ) {
        return this.setState({
          fieldName: fieldValue,
          nameError: <FormattedMessage id="Model.Already.Exists" />,
        });
      } else {
        let m1 = fieldName === "name" ? fieldValue : this.state.name;

        for (let s of tmpSimModels) {
          if (
            (s[0] === m1 && s[1] === model.name) ||
            (s[0] === model.name && s[1] === m1)
          ) {
            continue outer;
          }
        }
        // console.log(model.name + " / " + m1 + " : " + StringUtil.similarity(model.name, m1));
        if (
          StringUtil.similarity(model.name, m1) > 0.5 &&
          this.state.brandId !== "" &&
          (fieldName === "brandId" ? fieldValue : this.state.brandId) ===
            model.brand_id
        ) {
          tmpSimModels.push([model.name, m1]);
        }
      }
    }

    this.setState({
      simModels: tmpSimModels.length > 0 ? tmpSimModels : [],
    });
  }

  add() {
    this.setState({ disabled: true });

    let data = {
      name: this.state.name.toUpperCase().trim(),
      brand_id: this.state.brandId,
      option: this.state.option
    };

    let callback = (newModel) => {
      this.sendImg(this.state.file, newModel._id, () => this.close());
    };

    this.props.onAddModel(data, callback);
  }

  update(fieldName, fieldValue) {
    if (
      !this.props.modelId ||
      this.state.nameError ||
      this.state.associateBrandError
    )
      return;

    let data = {
      modelId: this.props.modelId,
      fieldName: fieldName,
      fieldValue: fieldValue,
    };

    this.props.onUpdateModel(data);
  }

  onDropFile(file, clearCallback) {
    if (this.state.nameError) return;

    if (!this.props.modelId) {
      return this.setState({ file: file });
    }

    this.sendImg(file, this.props.modelId, clearCallback);
  }

  sendImg(file, modelId, callback) {
    if (!file) return callback();

    let formData = new FormData();
    formData.append(file.name, file);

    const successCallback = () => {
      if (callback) callback();
    };

    this.props.onAddModelImg(modelId, formData, successCallback);
  }

  deleteImg(model) {
    if (!model) return;

    this.props.onDeleteModelImg(model._id);
  }

  openUploadImgModal(modelId, currentImage) {
    this.setState({
      modal: (
        <UploadCustomImgModal
          closeModal={() => this.close()}
          currentImage={currentImage}
          mode="upload"
          onComplete={(files) =>
            this.sendImg(files[0], modelId, this.close())
          }
        />
      ),
    });
  }

  render() {
    const model = this.props.modelId
      ? this.getModel(this.props.modelId)
      : null;

    const imgSrc = model
      ? APIUrl.getModelImg +
      model._id +
      "/" +
      model.name +
      "/" +
      Math.random() +
      "?token=" +
      APIUrl.jwtToken
      : null;

    const replaceImgBtn =
      model && model.file ? (
        <MenuButton
          icon="trash"
          variant="danger"
          onClick={() => this.deleteImg(model)}
          size="sm"
          hover={<FormattedMessage id="Image.Delete" />}
        />
      ) : (
        <MenuButton
          icon="upload"
          onClick={() => this.openUploadImgModal(model._id, imgSrc)}
          size="sm"
          hover={<FormattedMessage id="Image.Update" />}
        />
      );

    return (
      <React.Fragment>
        <div className="modal-bg show">
          <Modal
            show={true}
            onHide={() => this.close()}
            backdrop={"static"}
            size={"lg"}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {!this.props.modelId ? (
                  <FormattedMessage id="Add.Model" />
                ) : (
                  <FormattedMessage id="Model.Edit" />
                )}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div className="col-12">
                {this.props.modelId && <div>
                  <div className="row justify-content-center">
                    <img
                      src={imgSrc}
                      className="m-3 img-thumbnail"
                      alt={"model_" + Math.random()}
                      height="150"
                      width="150"
                    />
                  </div>
                  <div className="row justify-content-center mb-3">
                    {replaceImgBtn}
                  </div>

                </div>}
                <div className="form-group row">
                  <CustomLabel
                    label={this.props.intl.formatMessage({
                      id: "Brand.Associate",
                    })}
                    htmlFor="associate-brand"
                    required
                  />
                  <div className="col-12 col-sm-7">
                    {!this.props.modelId ? (
                      <select
                        className="form-control selectlist"
                        value={this.state.brandId || ""}
                        onChange={(e) =>
                          this.onChange("brandId", e.target.value)
                        }
                        onBlur={(e) => this.update("brand_id", e.target.value)}
                      >
                        <option value="">
                          {this.props.intl.formatMessage({
                            id: "Select.Brand",
                          })}
                        </option>
                        {this.props.brands.map((brand) => {
                          return (
                            <option key={brand._id} value={brand._id}>
                              {brand.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : (
                      <select
                        className="form-control selectlist"
                        value={this.state.brandId}
                        disabled={true}
                      >
                        {this.props.brands
                          .filter((brand) => brand._id === this.state.brandId)
                          .map((brand) => {
                            return (
                              <option key={brand._id} value={brand._id}>
                                {brand.name}
                              </option>
                            );
                          })}
                      </select>
                    )}
                    <small className="text-danger">
                      {this.state.associateBrandError}
                    </small>
                  </div>
                </div>

                <div className="form-group row">
                  <CustomLabel
                    label={this.props.intl.formatMessage({ id: "Name" })}
                    htmlFor="modelName"
                    labelClassName="col-12 col-md-4 col-form-label"
                    required
                  />
                  <div id="name" className="col-12 col-md-8">
                    <input
                      type="text"
                      className="form-control"
                      id="modelName"
                      value={this.state.name}
                      onChange={(e) =>
                        this.onChange("name", e.target.value.toUpperCase())
                      }
                      onBlur={(e) =>
                        this.update("name", e.target.value.toUpperCase())
                      }
                    />
                    <small className="text-danger">
                      {this.state.nameError}
                    </small>
                  </div>
                </div>
                <div className="form-group row">
                  <CustomLabel
                    label={this.props.intl.formatMessage({ id: "Option.Wheelchair" })}
                    htmlFor="option"
                    labelClassName="col-12 col-md-4 col-form-label"
                  />
                  <div id="option" className="col-12 col-md-8">
                    <input
                      type="text"
                      className="form-control"
                      id="option"
                      value={this.state.option}
                      onChange={(e) =>
                        this.onChange("option", e.target.value)
                      }
                      onBlur={(e) =>
                        this.update("option", e.target.value)
                      }
                    />
                  </div>
                </div>
                {!this.props.modelId && 
                  <div className="form-group row">
                    <CustomLabel
                      label={this.props.intl.formatMessage({ id: "Photo" })}
                      htmlFor="image"
                      labelClassName="col-12 col-md-4 col-form-label"
                    />
                    <div id="image" className="col-12 col-md-8">
                      <FileDropZone
                        onDropFile={(file, clearCallback) =>
                          this.onDropFile(file, clearCallback)
                        }
                        acceptedExtensions={["jpg", "png"]}
                        multiple={false}
                      />
                    </div>
                  </div>
                }
              </div>
              {!this.props.modelId &&
                this.state.simModels.length > 0 &&
                !this.state.nameError && (
                  <div className="alert alert-warning m-auto" role="alert">
                    <div className="">
                      <FormattedMessage id="Model.Similarities" /> :
                    </div>
                    <ul className="">
                      {this.state.simModels.map((model, index) => (
                        <li key={index}>{model[0]}</li>
                      ))}
                    </ul>
                    <div>
                      <FormattedMessage id="Duplicate.fix" />
                    </div>
                  </div>
                )}
            </Modal.Body>

            {!this.props.modelId && (
              <Modal.Footer>
                <Button variant="secondary" onClick={() => this.close()}>
                  <FormattedMessage id="Cancel" />
                </Button>
                <Button
                  variant="btn btn-info"
                  onClick={() => this.add()}
                  disabled={
                    this.state.disabled ||
                    this.state.nameError ||
                    this.state.name === "" ||
                    this.state.brandId === ""
                  }
                >
                  <FormattedMessage id="Add" />
                </Button>
              </Modal.Footer>
            )}
          </Modal>
        </div>

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    models: state.models,
    brands: state.brands,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddModel: (data, successCallback) =>
      dispatch(addModel(data, successCallback)),
    onUpdateModel: (data) => dispatch(updateModel(data)),
    onAddModelImg: (modelId, data, successCallback) =>
      dispatch(addModelImg(modelId, data, successCallback)),
    onDeleteModelImg: (modelId, successCallback) =>
      dispatch(deleteModelImg(modelId, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ModelsAdminModal));
