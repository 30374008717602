import APIUrl from "../../APIUrl";
import axios from "axios";

export const GET_SAVS = "GET_SAVS";

function getSAVsAction(savs) {
  return { type: GET_SAVS, savs: savs };
}

export const getPatientSAV = function (patientId, successCallback) {
  return function (dispatch) {
    return axios
      .get(APIUrl.getPatientSAV + patientId)
      .then(function (response) {
        dispatch(getSAVsAction(response.data));
        if (successCallback) successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const getAllPatientSAV = function (successCallback) {
  return function (dispatch) {
    return axios
      .get(APIUrl.getAllPatientSAV)
      .then(function (response) {
        // console.log(response)
        dispatch(getSAVsAction(response.data));
        if (successCallback) successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addPatientSAV = function (patientSAV, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addPatientSAV, patientSAV)
      .then(function (response) {
        dispatch(getPatientSAV(patientSAV.patient_id));
        if (successCallback) successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updPatientSAV = function (patientSAV, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updPatientSAV, patientSAV)
      .then(function (response) {
        dispatch(getPatientSAV(patientSAV.patient_id));
        if (successCallback) successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addPatientSAVFile = function (patientId, file, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addPatientSAVFile + patientId, file, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

// export const updPatientSAV = function(patient, successCallback){
//     return function(dispatch){
//         return axios.post(APIUrl.updPatientSAV, patient)
//             .then(function(response){
//                 dispatch(getPatientsAdmin());
//                 if(successCallback) successCallback(response.data);
//             })
//             .catch(function(err){
//                 throw err;
//             });
//     };
// };

// export const updateSettingsSAV = function(dataType, successCallback){
//     return function(dispatch){
//         return axios.post(APIUrl.updateSettingsSAV, dataType)
//             .then(function(response){
//                 dispatch(getPatientsAdmin());
//                 if(successCallback) successCallback();
//             })
//             .catch(function(err){
//                 throw err;
//             });
//     };
// };
