export default class StringUtil {
  static closest(string, matches, threshold = 50) {
    if (!string || !matches) return false;

    var distance, match;
    var candidate;
    for (var i = 0; i < matches.length; i++) {
      candidate = matches[i];

      if (string === candidate) return string;

      var measurement = StringUtil.sift(string, candidate);

      if (!distance || measurement < distance) {
        distance = measurement;
        match = candidate;
      }
    }

    if (distance > threshold) return false;

    return match;
  }

  static sift(s1, s2) {
    if (s1 === null || s1.length === 0) {
      if (s2 === null || s2.length === 0) {
        return 0;
      } else {
        return s2.length;
      }
    }

    if (s2 === null || s2.length === 0) {
      return s1.length;
    }

    var c = 0;
    var offset1 = 0;
    var offset2 = 0;
    var lcs = 0;
    var maxOffset = 5;

    while (c + offset1 < s1.length && c + offset2 < s2.length) {
      if (s1.charAt(c + offset1) === s2.charAt(c + offset2)) {
        lcs++;
      } else {
        offset1 = 0;
        offset2 = 0;
        for (var i = 0; i < maxOffset; i++) {
          if (c + i < s1.length && s1.charAt(c + i) === s2.charAt(c)) {
            offset1 = i;
            break;
          }
          if (c + i < s2.length && s1.charAt(c) === s2.charAt(c + i)) {
            offset2 = i;
            break;
          }
        }
      }
      c++;
    }

    return (s1.length + s2.length) / 2 - lcs;
  }

  static truncate(string, length) {
    if (string.length <= length) return string;

    return string.substring(0, length) + "...";
  }

  // ==========================================================

  static similarity(s1, s2) {
    var longer = s1;
    var shorter = s2;

    if (s1.length < s2.length) {
      longer = s2;
      shorter = s1;
    }

    var longerLength = longer.length;

    if (longerLength === 0) {
      return 1.0;
    }

    return (
      (longerLength - StringUtil.editDistance(longer, shorter)) /
      parseFloat(longerLength)
    );
  }

  static editDistance(s1, s2) {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();

    var costs = [];
    for (var i = 0; i <= s1.length; i++) {
      var lastValue = i;
      for (var j = 0; j <= s2.length; j++) {
        if (i === 0) costs[j] = j;
        else {
          if (j > 0) {
            var newValue = costs[j - 1];
            if (s1.charAt(i - 1) !== s2.charAt(j - 1))
              newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
            costs[j - 1] = lastValue;
            lastValue = newValue;
          }
        }
      }

      if (i > 0) costs[s2.length] = lastValue;
    }

    return costs[s2.length];
  }
}
