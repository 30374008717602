import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../util/Util";
import { addClient, updateClient } from "../../actions/clients/clients";
import { checkIfUsernameExists } from "../../actions/user/user";
import Roles from "../../enums/Roles";
import { Modal, Button } from "react-bootstrap";
import CustomLabel from "../sub/CustomLabel";

class UserModal extends React.Component {
  constructor(props) {
    super(props);

    if (this.props.client) {
      var client = this.props.client;
      this.state = {
        username: client.username,
        name: client.name,
        first_name: client.first_name,
        function: client.function,
        email: client.email,
        phone: client.phone,
        phone_fix: client.phone_fix,
        address: client.address,
        postal_code: client.postal_code,
        city: client.city,
        validator: client.validator,

        // Errors
        usernameError: null,
        phoneError: null,
        mobileError: null,
        nameError: null,
        first_nameError: null,
        emailError: null,

        disabled: false,
      };
    } else {
      this.state = {
        username: "",
        name: "",
        first_name: "",
        function: "",
        email: "",
        phone: "",
        phone_fix: "",
        address: "",
        postal_code: "",
        city: "",
        validator: true,

        // Errors
        usernameError: null,
        phoneError: null,
        mobileError: null,
        nameError: null,
        first_nameError: null,
        emailError: null,

        disabled: false,
      };
    }
  }

  scanErrors(noErrorCallback) {
    // Check for variables emptyness
    var isUsernameEmpty = Util.emptyString(this.state.username);
    var isNameEmpty = Util.emptyString(this.state.name);
    var isFirstNameEmpty = Util.emptyString(this.state.first_name);
    var isEmailEmpty = Util.emptyString(this.state.email);

    // Empty fields errors
    if (isUsernameEmpty) {
      this.setState({
        usernameError: <FormattedMessage id="Empty.Username" />,
      });
    }
    if (isNameEmpty) {
      this.setState({ nameError: <FormattedMessage id="Empty.Name" /> });
    }
    if (isFirstNameEmpty) {
      this.setState({
        first_nameError: <FormattedMessage id="Empty.FirstName" />,
      });
    }
    if (isEmailEmpty) {
      this.setState({ emailError: <FormattedMessage id="Empty.Email" /> });
    }

    // Invalid email error
    if (!Util.isEmail(this.state.email)) {
      this.setState({ emailError: <FormattedMessage id="Invalid.Email" /> });
    }

    if (isUsernameEmpty || isEmailEmpty || isNameEmpty || isFirstNameEmpty)
      return;

    // We are modifying a user. No need to check for email & username unicity
    if (this.props.client) {
      if (!isUsernameEmpty) return noErrorCallback();
      else return;
    }

    // Make sure username is unique
    this.props.onCheckIfUsernameExists(
      this.state.username,
      () => {
        this.setState({
          usernameError: <FormattedMessage id="Username.Already.Exists" />,
        });
      },
      () => {
        if (!isUsernameEmpty) return noErrorCallback();
      }
      // // Make sure email is unique
      // () => this.props.onCheckIfEmailExists(
      //     this.state.email,
      //     () => { this.setState({ emailError: <FormattedMessage id="Email.Already.Exists" /> }) },
      //     () => { if (!isUsernameEmpty) return noErrorCallback() }
      // )
    );
  }

  onComplete() {
    var createUser = () => {
      var user = {
        username: this.state.username,
        name: this.state.name,
        first_name: this.state.first_name,
        function: this.state.function,
        email: this.state.email,
        validator: this.state.validator,
        phone: this.state.phone,
        phone_fix: this.state.phone_fix,
        address: this.state.address,
        postal_code: this.state.postal_code,
        city: this.state.city,
        establishment_id: this.props.establishment._id,
      };

      this.setState({ disabled: true });

      this.props.onAddClient(user, () => this.props.close());
    };

    // Validate fields & send to BE
    this.scanErrors(createUser);
  }

  update(field) {
    if (
      !this.props.client ||
      this.state.emailError ||
      this.state.usernameError ||
      this.state.nameError ||
      this.state.first_nameError
    )
      return;

    if (
      field === "username" &&
      this.props.client.username === this.state[field]
    )
      return;

    if (field === "phone" && this.state.phoneError) return;

    if (field === "phone_fix" && this.state.mobileError) return;

    if (field === "email" && this.props.client.email === this.state[field])
      return;

    var updateValue = () => {
      var data = {
        clientId: this.props.client._id,
        updatedField: field,
        updatedValue: this.state[field],
      };

      this.props.onUpdateClient(data);
    };

    // Validate fields & send to BE
    this.scanErrors(updateValue);
  }

  onFieldChange(field, value) {
    if (field === "validator")
      this.setState({ validator: !this.state.validator });
    else this.setState({ [field]: value });

    // Empty fields errors
    if (field === "name" && Util.emptyString(value))
      this.setState({ nameError: <FormattedMessage id="Empty.Name" /> });
    if (field === "first_name" && Util.emptyString(value))
      this.setState({
        first_nameError: <FormattedMessage id="Empty.FirstName" />,
      });

    if (field === "name" && !Util.emptyString(value))
      this.setState({ nameError: null });
    if (field === "first_name" && !Util.emptyString(value))
      this.setState({ first_nameError: null });

    // Check if username is not empty and not already taken
    if (field === "username") {
      if (Util.emptyString(value)) return;

      // Force this value to be in lowercase
      // value = value.toLowerCase();

      this.setState({ [field]: value });

      // Test username syntax
      var checkUsername = Util.isValidUserName(value, 3, 20);
      if (checkUsername instanceof Object === true) {
        this.setState({
          usernameError: (
            <FormattedMessage
              id="Invalid.Username"
              values={{
                minLength: checkUsername.minLength,
                maxLength: checkUsername.maxLength,
              }}
            />
          ),
        });
        return;
      } else {
        this.setState({ usernameError: null });
      }

      if (!this.props.client || this.props.client.username !== value) {
        this.props.onCheckIfUsernameExists(
          value,
          () => {
            this.setState({
              usernameError: (
                <FormattedMessage
                  id="Username.Already.Exists"
                  values={{ username: value }}
                />
              ),
            });
          },
          () => {
            this.setState({ usernameError: null });
          }
        );
      }
    }

    if (field === "phone") {
      if (!Util.emptyString(value) && !Util.isPhone(value)) {
        this.setState({
          phoneError: <FormattedMessage id="Invalid.Phone.Number.Error" />,
        });
      } else this.setState({ phoneError: null });
    }

    if (field === "phone_fix") {
      if (!Util.emptyString(value) && !Util.isPhone(value)) {
        this.setState({
          mobileError: <FormattedMessage id="Invalid.Phone.Number.Error" />,
        });
      } else this.setState({ mobileError: null });
    }

    // Check if email is not empty and not already taken
    if (field === "email" && !Util.emptyString(value)) {
      this.setState({ emailError: null });

      if (!Util.isEmail(value)) {
        this.setState({ emailError: <FormattedMessage id="Invalid.Email" /> });
        return;
      }

      // if (!this.props.client || this.props.client.email !== value) {
      //     this.props.onCheckIfEmailExists(value,
      //         () => { this.setState({ emailError: <FormattedMessage id="Email.Already.Exists" /> }) },
      //         () => { this.setState({ emailError: null }) });
      // }
    }
  }

  disabled() {
    return (
      Util.emptyString(this.state.username) ||
      this.state.usernameError ||
      Util.emptyString(this.state.name) ||
      Util.emptyString(this.state.first_name) ||
      this.state.nameError ||
      this.state.first_nameError ||
      this.state.emailError ||
      this.state.phoneError ||
      this.state.mobileError ||
      Util.emptyString(this.state.email) ||
      // Util.emptyString(this.state.type_id) ||
      //  !this.isNameUnique(this.state.username) ||
      this.state.disabled
    );
  }

  render() {
    const { client, user } = this.props;

    if (!user) return null;

    const isAdmin = user.role === Roles.ADMIN;

    return (
      <div className="modal-bg show">
        <Modal
          show={true}
          onHide={() => this.props.close()}
          backdrop={"static"}
          dialogClassName={"modal-dialog xl"}
          size={"xl"}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.client ? (
                <FormattedMessage id="Modify.User" />
              ) : (
                <FormattedMessage id="Add.User" />
              )}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Username" })}
                htmlFor="user-username"
                className="col-12 col-md-5 col-form-label"
                required
              />
              <div className="col-12 col-md-7">
                <input
                  type="text"
                  className="form-control col-12 col-md-8 d-inline"
                  id="user-username"
                  autoComplete="off"
                  value={this.state.username}
                  disabled={!isAdmin}
                  onChange={(e) =>
                    this.onFieldChange("username", e.target.value)
                  }
                  onBlur={(e) => this.update("username")}
                />
                <span className="col-12 col-md-4">
                  -{this.props.company.url}
                </span>
                <div className="text-danger">
                  <small>{this.state.usernameError}</small>
                </div>
              </div>
            </div>

            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Name" })}
                htmlFor="user-name"
                className="col-12 col-md-5 col-form-label"
                required
              />
              <div className="col-12 col-md-7">
                <input
                  type="text"
                  className="form-control col-12"
                  id="user-name"
                  autoComplete="off"
                  value={this.state.name}
                  disabled={!isAdmin}
                  onChange={(e) => this.onFieldChange("name", e.target.value)}
                  onBlur={(e) => this.update("name")}
                />
                <div className="text-danger">
                  <small>{this.state.nameError}</small>
                </div>
              </div>
            </div>

            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "First.Name" })}
                htmlFor="user-first_name"
                className="col-12 col-md-5 col-form-label"
                required
              />
              <div className="col-12 col-md-7">
                <input
                  type="text"
                  className="form-control"
                  id="user-first_name"
                  autoComplete="off"
                  value={this.state.first_name}
                  disabled={!isAdmin}
                  onChange={(e) =>
                    this.onFieldChange("first_name", e.target.value)
                  }
                  onBlur={(e) => this.update("first_name")}
                />
                <div className="text-danger">
                  <small>{this.state.first_nameError}</small>
                </div>
              </div>
            </div>

            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Function" })}
                htmlFor="user-function"
                className="col-12 col-md-5 col-form-label"
              />
              <div className="col-12 col-md-7">
                <input
                  type="text"
                  className="form-control"
                  id="user-function"
                  autoComplete="off"
                  value={this.state.function}
                  disabled={!isAdmin}
                  onChange={(e) =>
                    this.onFieldChange("function", e.target.value)
                  }
                  onBlur={(e) => this.update("function")}
                />
              </div>
            </div>

            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Email" })}
                htmlFor="user-email"
                className="col-12 col-md-5 col-form-label"
                required
              />
              <div className="col-12 col-md-7">
                <input
                  type="email"
                  className="form-control"
                  id="user-email"
                  autoComplete="off"
                  value={this.state.email}
                  disabled={!isAdmin}
                  onChange={(e) => this.onFieldChange("email", e.target.value)}
                  onBlur={(e) => this.update("email")}
                />
                <div className="text-danger">
                  <small>{this.state.emailError}</small>
                </div>
              </div>
            </div>

            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Mobile" })}
                htmlFor="user-phone"
                className="col-12 col-md-5 col-form-label"
              />
              <div className="col-12 col-md-7">
                <input
                  type="text"
                  className="form-control"
                  id="user-phone"
                  autoComplete="off"
                  value={this.state.phone}
                  disabled={!isAdmin}
                  onChange={(e) => this.onFieldChange("phone", e.target.value)}
                  onBlur={(e) => this.update("phone")}
                />
                <div className="text-danger">
                  <small>{this.state.phoneError}</small>
                </div>
              </div>
            </div>

            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Phone" })}
                htmlFor="user-phone_fix"
                className="col-12 col-md-5 col-form-label"
              />
              <div className="col-12 col-md-7">
                <input
                  type="text"
                  className="form-control"
                  id="user-phone_fix"
                  autoComplete="off"
                  value={this.state.phone_fix}
                  disabled={!isAdmin}
                  onChange={(e) =>
                    this.onFieldChange("phone_fix", e.target.value)
                  }
                  onBlur={(e) => this.update("phone_fix")}
                />
                <div className="text-danger">
                  <small>{this.state.mobileError}</small>
                </div>
              </div>
            </div>

            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Address" })}
                htmlFor="user-address"
                className="col-12 col-md-5 col-form-label"
              />
              <div className="col-12 col-md-7">
                <input
                  type="text"
                  className="form-control"
                  id="user-address"
                  autoComplete="off"
                  value={this.state.address}
                  disabled={!isAdmin}
                  onChange={(e) =>
                    this.onFieldChange("address", e.target.value)
                  }
                  onBlur={(e) => this.update("address")}
                />
              </div>
            </div>
            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Postal.Code" })}
                htmlFor="user-postal-code"
                className="col-12 col-md-5 col-form-label"
              />
              <div className="col-12 col-md-7">
                <input
                  type="text"
                  className="form-control"
                  id="user-postal-code"
                  autoComplete="off"
                  value={this.state.postal_code}
                  disabled={!isAdmin}
                  onChange={(e) =>
                    this.onFieldChange("postal_code", e.target.value)
                  }
                  onBlur={(e) => this.update("postal_code")}
                />
              </div>
            </div>
            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "City" })}
                htmlFor="user-city"
                className="col-12 col-md-5 col-form-label"
              />
              <div className="col-12 col-md-7">
                <input
                  type="text"
                  className="form-control"
                  id="user-city"
                  autoComplete="off"
                  value={this.state.city}
                  disabled={!isAdmin}
                  onChange={(e) => this.onFieldChange("city", e.target.value)}
                  onBlur={(e) => this.update("city")}
                />
              </div>
            </div>

            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Validator" })}
                htmlFor=""
                className="col-12 col-md-5 custom-control-label no-after no-before"
              />
              <div className="col-12 col-md-7">
                <div className="custom-control custom-switch mx-auto switch-success text-left">
                  <input
                    onChange={(e) =>
                      this.onFieldChange("validator", e.target.value)
                    }
                    type="checkbox"
                    id="validator-switch"
                    className="custom-control-input switch-bg-blue"
                    checked={this.state.validator}
                    disabled={!isAdmin}
                    onBlur={(e) => this.update("validator")}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="validator-switch"
                  ></label>
                </div>
              </div>
            </div>
          </Modal.Body>

          {!client && (
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.props.close()}>
                <FormattedMessage id="Cancel" />
              </Button>
              <Button
                variant="info"
                onClick={() => this.onComplete()}
                disabled={this.disabled()}
              >
                <FormattedMessage id="Add" />
              </Button>
            </Modal.Footer>
          )}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddClient: (data, successCallback) =>
      dispatch(addClient(data, successCallback)),
    onCheckIfUsernameExists: (username, existsCallback, noExistsCallback) =>
      dispatch(
        checkIfUsernameExists(username, existsCallback, noExistsCallback)
      ),
    // onCheckIfEmailExists: (email, existsCallback, noExistsCallback) => dispatch(checkIfEmailExists(email, existsCallback, noExistsCallback)),
    onUpdateClient: (data) => dispatch(updateClient(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(UserModal));
