import { GET_ESTABLISHMENT } from "../../actions/establishment/establishment";

export default function establishment(state = [], action) {
  switch (action.type) {
    case GET_ESTABLISHMENT:
      return action.establishment;
    default:
      return state;
  }
}
