import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
// import UserModal from './UserModal';
import Paginator from "../../sub/Paginator";
import APIUrl from "../../../APIUrl";
import Roles from "../../../enums/Roles";

class PatientObservation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };

    this.paginator = new Paginator(this);
  }

  render() {
    const { trial, patient, user } = this.props;

    if (!trial || !patient) return null;

    this.paginator.init();
    let observationsNode = () => {
      if (!this.paginator.keep()) return null;
      let i = 0;

      return (
        <tr className="tre4vhp" key={trial._id}>
          <td className="d-none mw-200 d-lg-table-cell">{trial.commentary}</td>
          <td className="d-none mw-200 d-lg-table-cell">
            {trial.files &&
              trial.files.length > 0 &&
              trial.files.map((fileName) => {
                if (user.role === Roles.ADMIN) {
                  return (
                    <img
                      key={i++}
                      src={
                        APIUrl.getTrialFileAdmin +
                        patient._id +
                        "/" +
                        trial._id +
                        "/" +
                        fileName +
                        "?token=" +
                        APIUrl.jwtToken
                      }
                      className="observation-img"
                      width="100"
                      height="100"
                      alt="logo"
                    />
                  );
                }

                return (
                  <img
                    key={i++}
                    src={
                      APIUrl.getTrialFile +
                      patient._id +
                      "/" +
                      trial._id +
                      "/" +
                      fileName +
                      "?token=" +
                      APIUrl.jwtToken
                    }
                    className="observation-img"
                    width="100"
                    height="100"
                    alt="logo"
                  />
                );
              })}
          </td>
          {/* <td className="text-center tdaction">
                        <i className="fa fa-edit icon-big" onClick={(e) => openModal(trial)}></i>
                    </td> */}
          <td className="pt-2 pb-0 mb-0" colSpan="99999">
            {this.paginator.render()}
          </td>
        </tr>
      );
    };

    let tableStyle = "table mt-3";
    if (this.props.cssStyle) tableStyle = this.props.cssStyle;

    return (
      <React.Fragment>
        <table className={tableStyle + " tablee4vhp"}>
          <thead>
            <tr>
              <th className="d-none d-lg-table-cell">
                <FormattedMessage id="Description" />
              </th>
              <th className="d-none d-lg-table-cell">
                <FormattedMessage id="Picture" />
              </th>
              {/* <th className="text-center"><FormattedMessage id="Actions" /></th> */}
            </tr>
          </thead>
          <tbody>{observationsNode()}</tbody>
        </table>

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientObservation);
