import APIUrl from "../../APIUrl";
import axios from "axios";

export const GET_NOTIFS = "GET_NOTIFS";

function getNotifsAction(notifications) {
  return { type: GET_NOTIFS, notifications: notifications };
}

export const getNotifs = function (successCallback) {
  return function (dispatch) {
    return axios
      .get(APIUrl.getNotifs)
      .then(function (response) {
        if (response) dispatch(getNotifsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteNotif = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteNotif, data)
      .then(function () {
        dispatch(getNotifs());
      })
      .catch(function (err) {
        throw err;
      });
  };
};
