import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import PatientIdentification from "./PatientIdentification";
import PatientNotes from "./PatientNotes";
import PatientExpressionNeeds from "./PatientExpressionNeeds";
import PatientTrials from "./PatientTrials";
import PatientDocuments from "./PatientDocuments";
import EquipmentType from "./EquipmentType";
import ModelWheelchair from "./ModelWheelchair";
import HomeAssessment from "./HomeAssessment";
import PatientSAV from "./PatientSAV";
import PatientAnthropometricMeasurement from "./PatientAnthropometricMeasurement";
import { Modal, Tabs, Tab } from "react-bootstrap";
import Roles from "../../enums/Roles";

class PatientModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      section: this.props.section || "identification",
    };
  }

  getPatient(patientId) {
    return this.props.patients.find((x) => x._id === patientId);
  }

  buildTab(section, i18n, disable) {
    const isSelected = this.state.section === section;

    return (
      <a
        className={
          "nav-item nav-link p-2 pr-sm-3 pl-sm-3 rounded-0 " +
          (isSelected ? " active " : "") +
          (disable ? " disabled " : "")
        }
        id={section}
        data-toggle="tab"
        href={"#" + section}
        role="tab"
        aria-controls="nav-patients-info"
        aria-selected={isSelected}
        disabled={disable}
        onClick={() => this.setState({ section })}
      >
        <FormattedMessage id={i18n} />
      </a>
    );
  }

  render() {
    const { patientId } = this.props;
    const patient = this.getPatient(patientId);

    if (patientId && !patient) return null;

    var disableAnnexTabs = !patient;

    return (
      <div className="modal-bg show">
        <Modal
          show={true}
          onHide={() => this.props.close()}
          backdrop={"static"}
          dialogClassName={"modal-dialog xl"}
          size={"xl"}
        >
          <Modal.Body>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.props.close()}
            >
              <span aria-hidden="true">&times;</span>
            </button>

            <Tabs
              defaultActiveKey={this.state.section}
              id="tab-patient"
              className="mb-3"
              mountOnEnter
              unmountOnExit
            >
              <Tab
                eventKey="identification"
                title={<FormattedMessage id="Identification" />}
              >
                <PatientIdentification
                  patient={patient}
                  openModal={(patient) => this.props.openModal(patient)}
                />
              </Tab>
              {!disableAnnexTabs && this.props.user.role === Roles.ADMIN && (
                <Tab
                  eventKey="note"
                  title={<FormattedMessage id="Bloc.Note" />}
                  disabled={disableAnnexTabs}
                >
                  <PatientNotes patient={patient} />
                </Tab>
              )}
              {!disableAnnexTabs && (
                <Tab
                  eventKey="equipment-type"
                  title={<FormattedMessage id="Equipment.Type" />}
                  disabled={disableAnnexTabs}
                >
                  <EquipmentType patient={patient} />
                </Tab>
              )}
              {!disableAnnexTabs && (
                <Tab
                  eventKey="expression-needs"
                  title={<FormattedMessage id="Expression.Needs" />}
                  disabled={disableAnnexTabs}
                >
                  <PatientExpressionNeeds
                    patient={patient}
                    expressionNeedsTypes={this.props.expressionNeedsTypes}
                    openModal={(patient) => this.props.openModal(patient)}
                  />
                </Tab>
              )}
              {!disableAnnexTabs && (
                <Tab
                  eventKey="anthropometric-measurement"
                  title={<FormattedMessage id="Anthropometric.Measurement" />}
                  disabled={disableAnnexTabs}
                >
                  <PatientAnthropometricMeasurement
                    patient={patient}
                    openModal={(patient) => this.props.openModal(patient)}
                  />
                </Tab>
              )}
              {!disableAnnexTabs && (
                <Tab
                  eventKey="modelWheelchair"
                  title={<FormattedMessage id="Model.Wheelchair" />}
                  disabled={disableAnnexTabs}
                >
                  <ModelWheelchair
                    patient={patient}
                    openModal={(patient) => this.props.openModal(patient)}
                  />
                </Tab>
              )}
              {!disableAnnexTabs && (
                <Tab
                  eventKey="trials"
                  title={<FormattedMessage id="Trials" />}
                  disabled={disableAnnexTabs}
                >
                  <PatientTrials
                    patient={patient}
                    openModal={(patient) => this.props.openModal(patient)}
                  />
                </Tab>
              )}
              {!disableAnnexTabs && (
                <Tab
                  eventKey="patients-homeAssessment"
                  title={<FormattedMessage id="Home.Assessment" />}
                  disabled={disableAnnexTabs}
                >
                  <HomeAssessment patient={patient} />
                </Tab>
              )}
              {!disableAnnexTabs && (
                <Tab
                  eventKey="documents"
                  title={<FormattedMessage id="Documents" />}
                  disabled={disableAnnexTabs}
                >
                  <PatientDocuments patient={patient} />
                </Tab>
              )}
              {!disableAnnexTabs && (
                <Tab
                  eventKey="sav"
                  title={<FormattedMessage id="SAV" />}
                  disabled={disableAnnexTabs}
                >
                  <PatientSAV patient={patient} />
                </Tab>
              )}
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    patients: state.patients,
    expressionNeedsTypes: state.expressionNeedsTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientModal);
