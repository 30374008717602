import React from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

class TrialPerceptionModalAdmin extends React.Component {
  constructor(props) {
    super(props);

    var trialPerception = this.props.trialPerception || null;

    if (trialPerception) {
      this.state = {
        perception: trialPerception.name,
        error: null,
      };
    } else {
      this.state = {
        perception: "",
        error: null,
      };
    }
  }

  isUpdate() {
    if (this.props.trialPerception) return true;
    else return false;
  }

  close() {
    this.props.close();
  }

  onChange(field, value) {
    if (this.props.trialPerceptions) {
      for (var trialPerception of this.props.trialPerceptions) {
        if (trialPerception.name === value) {
          this.setState({
            error: <FormattedMessage id="Name.Exist" />,
          });
          break;
        } else {
          this.setState({
            error: null,
          });
        }
      }
    }

    if (this.isUpdate() && value === this.props.trialPerception.name) {
      this.setState({
        error: null,
      });
    }

    this.setState({ [field]: value });
  }

  onUpdate() {
    if (this.CheckError() || !this.isUpdate()) {
      return;
    }

    var data = this.props.trialPerception;

    data.name = this.state.perception;

    this.props.updateTrialPerception(data);
  }

  onAdd() {
    this.props.addTrialPerception(this.state.perception);
  }

  CheckError() {
    if (
      this.isUpdate() &&
      this.state.perception === this.props.trialPerception.name
    )
      return true;
    if (!this.state.perception || this.state.error) return true;
    else return false;
  }

  stopEvent(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  render() {
    return (
      <div className="modal-bg show">
        <Modal show={true} onHide={() => this.close()} backdrop={"static"}>
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id="Trial.Perception" />
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div>
              <input
                type="text"
                className="form-control"
                onChange={(e) => this.onChange("perception", e.target.value)}
                onBlur={(e) => this.onUpdate()}
                value={this.state.perception}
              />
            </div>
            <small className="form-text text-danger mb-3">
              {this.state.error}
            </small>
          </Modal.Body>

          {!this.isUpdate() && (
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.close()}>
                <FormattedMessage id="Cancel" />
              </Button>
              <Button
                variant="info"
                onClick={() => this.onAdd()}
                disabled={this.CheckError()}
              >
                <FormattedMessage id="Add" />
              </Button>
            </Modal.Footer>
          )}
        </Modal>
      </div>
    );
  }
}

export default TrialPerceptionModalAdmin;
