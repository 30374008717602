import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../../util/Util";
import { updPatient } from "../../../actions/patients/patients";
import ConfirmationModal from "../../sub/modals/ConfirmationModal";
import ExpressionNeedsSignaturePage from "./pages/ExpressionNeedsSignaturePage";
import { Modal } from "react-bootstrap";
// import PatientTrialTypes from '../../enums/PatientTrialTypes'

class ExpressionNeedsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      patient_signature: "",
      therapist_signature: "",
    };
  }

  onChange(field, value) {
    this.setState({ [field]: value });
  }

  disabled() {
    return (
      Util.emptyString(this.state.title) ||
      !this.state.file ||
      this.state.disabled ||
      this.state.fileError
    );
  }

  close() {
    const confirm = () => this.props.closeModal();

    const title = <FormattedMessage id="Confirmation" />;
    const content = <FormattedMessage id="Really.Quit" />;

    this.setState({
      modal: (
        <ConfirmationModal
          isOpen={true}
          title={title}
          content={content}
          successCallback={confirm}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  render() {
    const { patient } = this.props;

    return (
      <>
        <div className="modal-bg show">
          <Modal
            show={true}
            onHide={() => this.close()}
            backdrop={"static"}
            dialogClassName={
              "modal-dialog xl no-max-height height-full height-auto"
            }
            size={"xl"}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <FormattedMessage id="Patient.Expression.Needs.Signature" />
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <ExpressionNeedsSignaturePage
                setState={(state) => this.setState(state)}
                patient={patient}
                patientSignature={this.state.patient_signature}
                therapistSignature={this.state.therapist_signature}
                date={this.props.date}
              />
            </Modal.Body>
          </Modal>
        </div>

        {this.state.modal}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdPatient: (patient, successCallback) =>
      dispatch(updPatient(patient, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ExpressionNeedsModal));
