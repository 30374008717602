import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Card, CardTitle } from "reactstrap";
import Patients from "../../components/patients/Patients";

class SplitHome extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 col-lg-6 ">
            <div className="m-2 zoom-7 overflow-hidden">
              <Card className="p-3">
                <CardTitle>
                  <h4 className="text-info">
                    <FormattedMessage id="Summary.Patients.Monitoring" />
                  </h4>
                </CardTitle>
                <Patients limit={5} />
                <Link to="/home/patients" className="overlay">
                  &nbsp;
                </Link>
              </Card>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SplitHome;
