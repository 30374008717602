var PatientDocumentTypes = {
  QUOTATION: 0,
  COMMERCIAL_SHEET: 1,
  SUPPLIER_MEASUREMENT_SHEET: 2,
  SERVICE_GUIDE_MAINTENANCE: 3,
  PRESCRIPTION: 4,
  APPROPRIATE_ELECTRIC_WHEELCHAIR: 5,
  PRIOR_AGREEMENT_REQUEST: 6,
  DELIVERY_FORM: 7,
  BILL: 8,
};

module.exports = PatientDocumentTypes;
