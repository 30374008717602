import { GET_ESTABLISHMENT_TYPES } from "../../actions/configurables/establishmentTypes";

export default function establishmentTypes(state = [], action) {
  switch (action.type) {
    case GET_ESTABLISHMENT_TYPES:
      return action.establishmentTypes;
    default:
      return state;
  }
}
