import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  addEstablishment,
  updateEstablishment,
} from "../../actions/establishments/establishments";
import { addEstablishmentType } from "../../actions/configurables/establishmentTypes";
// import { checkIfEmailExists } from '../../actions/user/user';
import ComboBox from "../sub/ComboBox";
import Util from "../../util/Util";
import { Map, Marker } from "pigeon-maps";
import Roles from "../../enums/Roles";
import CustomLabel from "../sub/CustomLabel";
// import axios from 'axios';

class EstablishmentInfo extends React.Component {
  constructor(props) {
    super(props);

    var establishment = this.props.establishment || {};

    this.state = {
      name: establishment.name || "",
      email: establishment.email || "",
      phone: establishment.phone || "",
      address: establishment.address || "",
      address_compl: establishment.address_compl || "",
      postal_code: establishment.postal_code || "",
      city: establishment.city || "",
      website: establishment.website || "",
      type_id: establishment.type_id || "",

      latLongEstablishement: undefined,

      nameError: null,
      phoneError: null,
      emailError: null,
      disabled: false,
    };
  }

  updateParentState(data) {
    this.props.updateParentState(data);
  }

  isUpdate() {
    return (
      this.props.establishment !== undefined &&
      this.props.establishment !== null
    );
  }

  onFieldChange(field, value) {
    this.setState({ [field]: value });

    // Careful with name : must be unique!
    if (field === "name") {
      if (!this.isNameUnique(value)) {
        this.setState({
          nameError: (
            <FormattedMessage id="Establishment.Name.Already.Exists" />
          ),
        });
        return;
      } else {
        this.setState({ nameError: null });
      }
    }

    if (field === "phone") {
      if (!Util.emptyString(value) && !Util.isPhone(value)) {
        this.setState({
          phoneError: <FormattedMessage id="Invalid.Phone.Number.Error" />,
        });
      } else this.setState({ phoneError: null });
    }

    if (field === "email") {
      if (Util.emptyString(value)) return;

      if (this.isUpdate() && this.props.establishment.email === value) {
        this.setState({ emailError: null });
        return;
      }

      if (!Util.isEmail(value)) {
        this.setState({ emailError: <FormattedMessage id="Invalid.Email" /> });
        return;
      } else {
        this.setState({ emailError: null });
      }

      // this.props.onCheckIfEmailExists(
      //     value,
      //     () => { this.setState({ emailError: <FormattedMessage id="Email.Already.Exists" /> }) },
      //     () => { this.setState({ emailError: null }) }
      // );
    }
  }

  sendUpdate(field, value) {
    let mapFields = ["address", "address_compl", "postal_code", "city"];

    // Update Map before update
    if (
      mapFields.indexOf(field) &&
      this.state.address !== "" &&
      this.state.postal_code !== "" &&
      this.state.postal_code.length === 5 &&
      this.state.city !== ""
    ) {
      var addressCompl =
        this.state.address.replace(/\s+/g, "+") +
        "+" +
        this.state.postal_code +
        "+" +
        this.state.city;

      async function promiseLatLongEstablishement() {
        var APIaddress =
          "https://api-adresse.data.gouv.fr/search/?q=" + addressCompl;
        var config = {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        };
        const promiseLatLongEstablishement = fetch(APIaddress, config);
        return await promiseLatLongEstablishement;
      }

      var getLatLong = async (callbackFunction) => {
        try {
          const LatLongEstablishement = await promiseLatLongEstablishement().then(
            (res) => {
              return res.json();
            }
          );
          return callbackFunction(LatLongEstablishement);
        } catch (e) {
          console.log(e);
        }
      };
      const callbackFunction = (result, callback) => {
        this.updateParentState({
          latLongEstablishement: {
            lat: result.features[0].geometry.coordinates[0],
            lon: result.features[0].geometry.coordinates[1],
          },
        });
      };

      getLatLong(callbackFunction);
    }

    // Establishment already exists, update field value
    if (this.props.establishment && !this.disabled()) {
      this.props.onUpdateEstablishment({
        establishmentId: this.props.establishment._id,
        updatedField: field,
        updatedValue: value,
      });
    }
  }

  isNameUnique(name) {
    for (let establishment of this.props.establishments) {
      if (establishment.name.toLowerCase() === name.toLowerCase()) {
        // Careful: same establishment we're modifying, so name conflict doesn't matter
        if (
          this.props.establishment &&
          this.props.establishment._id === establishment._id
        )
          continue;
        return false;
      }
    }

    return true;
  }

  onSubmit() {
    var establishment = {
      type_id: this.state.type_id,
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      address: this.state.address,
      address_compl: this.state.address_compl,
      postal_code: this.state.postal_code,
      city: this.state.city,
      website: this.state.website,
    };

    // Close the modal and immediately open it back, parametrized with the establishment
    var successCallback = (esta) => {
      this.props.closeModal();
      this.props.openModal(esta);
    };

    this.props.onAddEstablishment(establishment, successCallback);
  }

  disabled() {
    return (
      Util.emptyString(this.state.name) ||
      Util.emptyString(this.state.type_id) ||
      !this.isNameUnique(this.state.name) ||
      this.state.phoneError ||
      this.state.emailError ||
      this.state.disabled
    );
  }

  render() {
    function mapTilerProvider(x, y, z) {
      return `https://a.tile.openstreetmap.org/${z}/${x}/${y}.png`;
    }

    const { user } = this.props;

    if (!user) return null;

    const isAdmin = user.role === Roles.ADMIN;

    return (
      <React.Fragment>
        <div className="row mb-3">
          <div className="col-12 col-lg-6">
            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Type" })}
                htmlFor="establishment-type-id"
                className="col-12 col-md-5 col-form-label"
                required
              />
              <div id="establishment-type-id" className="col-12 col-md-7">
                <ComboBox
                  menuPlacement="top"
                  onChange={(value) => this.onFieldChange("type_id", value)}
                  onBlur={(value) => this.sendUpdate("type_id", value)}
                  defaultOption={this.state.type_id}
                  options={this.props.establishmentTypes}
                  disabled={!isAdmin}
                  onCreateOption={(data, successCallback) =>
                    this.props.onAddEstablishmentType(data, successCallback)
                  }
                />
              </div>
            </div>

            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Name" })}
                htmlFor="establishment-name"
                className="col-12 col-md-5 col-form-label"
                required
              />
              <div className="col-12 col-md-7">
                <input
                  type="text"
                  className="form-control"
                  id="establishment-name"
                  autoComplete="off"
                  value={this.state.name.toUpperCase()}
                  disabled={!isAdmin}
                  onChange={(e) =>
                    this.onFieldChange("name", e.target.value.toUpperCase())
                  }
                  onBlur={(e) =>
                    this.sendUpdate("name", e.target.value.toUpperCase())
                  }
                />
                {this.state.nameError && (
                  <div className="text-danger">
                    <small>{this.state.nameError}</small>
                  </div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Address" })}
                htmlFor="establishment-address"
                className="col-12 col-md-5 col-form-label"
              />
              <div className="col-12 col-md-7">
                <input
                  type="text"
                  className="form-control"
                  id="establishment-address"
                  autoComplete="off"
                  value={this.state.address}
                  disabled={!isAdmin}
                  onChange={(e) =>
                    this.onFieldChange("address", e.target.value)
                  }
                  onBlur={(e) => this.sendUpdate("address", e.target.value)}
                />
              </div>
            </div>
            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Address.Compl" })}
                htmlFor="establishment-address-compl"
                className="col-12 col-md-5 col-form-label"
              />
              <div className="col-12 col-md-7">
                <input
                  type="text"
                  className="form-control"
                  id="establishment-address-compl"
                  autoComplete="off"
                  value={this.state.address_compl}
                  disabled={!isAdmin}
                  onChange={(e) =>
                    this.onFieldChange("address_compl", e.target.value)
                  }
                  onBlur={(e) =>
                    this.sendUpdate("address_compl", e.target.value)
                  }
                />
              </div>
            </div>
            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Postal.Code" })}
                htmlFor="establishment-postal-code"
                className="col-12 col-md-5 col-form-label"
              />
              <div className="col-12 col-md-7">
                <input
                  type="number"
                  className="form-control"
                  id="establishment-postal-code"
                  autoComplete="off"
                  value={this.state.postal_code}
                  disabled={!isAdmin}
                  onChange={(e) =>
                    this.onFieldChange("postal_code", e.target.value)
                  }
                  onBlur={(e) => this.sendUpdate("postal_code", e.target.value)}
                />
              </div>
            </div>
            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "City" })}
                htmlFor="establishment-city"
                className="col-12 col-md-5 col-form-label"
              />
              <div className="col-12 col-md-7">
                <input
                  type="text"
                  className="form-control"
                  id="establishment-city"
                  autoComplete="off"
                  value={this.state.city}
                  disabled={!isAdmin}
                  onChange={(e) => this.onFieldChange("city", e.target.value)}
                  onBlur={(e) => this.sendUpdate("city", e.target.value)}
                />
              </div>
            </div>
            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Email" })}
                htmlFor="establishment-email"
                className="col-12 col-md-5 col-form-label"
              />
              <div className="col-12 col-md-7">
                <input
                  type="email"
                  className="form-control"
                  id="establishment-email"
                  autoComplete="off"
                  value={this.state.email}
                  disabled={!isAdmin}
                  onChange={(e) => this.onFieldChange("email", e.target.value)}
                  onBlur={(e) => this.sendUpdate("email", e.target.value)}
                />
                {this.state.emailError && (
                  <div className="text-danger">
                    <small>{this.state.emailError}</small>
                  </div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Phone" })}
                htmlFor="establishment-phone"
                className="col-12 col-md-5 col-form-label"
              />
              <div className="col-12 col-md-7">
                <input
                  type="text"
                  className="form-control"
                  id="establishment-phone"
                  autoComplete="off"
                  value={this.state.phone}
                  disabled={!isAdmin}
                  onChange={(e) => this.onFieldChange("phone", e.target.value)}
                  onBlur={(e) => this.sendUpdate("phone", e.target.value)}
                />
                <div className="text-danger">
                  <small>{this.state.phoneError}</small>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Website" })}
                htmlFor="establishment-website"
                className="col-12 col-md-5 col-form-label"
              />
              <div className="col-12 col-md-7">
                <input
                  type="text"
                  className="form-control"
                  id="establishment-city"
                  autoComplete="off"
                  value={this.state.website}
                  disabled={!isAdmin}
                  onChange={(e) =>
                    this.onFieldChange("website", e.target.value)
                  }
                  onBlur={(e) => this.sendUpdate("website", e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-6">
            <div className="map-wrapper">
              <Map
                provider={mapTilerProvider}
                defaultCenter={
                  this.props.latLong !== undefined
                    ? [this.props.latLong.lon, this.props.latLong.lat]
                    : [47.519828, 2.378475]
                }
                defaultZoom={5}
                zoom={16}
                width={500}
                height={450}
                center={
                  this.props.latLong !== undefined && [
                    this.props.latLong.lon,
                    this.props.latLong.lat,
                  ]
                }
              >
                {this.props.latLong !== undefined && (
                  <Marker
                    anchor={[this.props.latLong.lon, this.props.latLong.lat]}
                    payload={1}
                  />
                )}
              </Map>
            </div>
          </div>
        </div>

        <div className="row">
          {!this.props.establishment && isAdmin && (
            <div className="col-12 col-lg-6 offset-lg-3">
              <button
                className="btn btn-info btn-block"
                disabled={this.disabled()}
                onClick={() => this.onSubmit()}
              >
                <FormattedMessage id="Add" />
              </button>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    establishments: state.establishments,
    establishmentTypes: state.establishmentTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddEstablishment: (data, successCallback) =>
      dispatch(addEstablishment(data, successCallback)),
    onUpdateEstablishment: (data) => dispatch(updateEstablishment(data)),
    onAddEstablishmentType: (data, successCallback) =>
      dispatch(addEstablishmentType(data, successCallback)),
    // onCheckIfEmailExists: (email, existsCallback, noExistsCallback) => dispatch(checkIfEmailExists(email, existsCallback, noExistsCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(EstablishmentInfo));
