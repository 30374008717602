import { GET_MODELS } from "../../actions/configurables/models";

export default function models(state = [], action) {
  switch (action.type) {
    case GET_MODELS:
      return action.models;
    default:
      return state;
  }
}
