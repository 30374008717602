import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import GeneralSettingsAdmin from "./GeneralSettingsAdmin";
import SAVAdmin from "./SAV/SAVAdmin";
import TrialPerceptionsAdmin from "./trialPerceptions/TrialPerceptionsAdmin";
import Collaborators from "./collaborators/Collaborators";
import { Tab, Tabs } from "react-bootstrap";
import WheelchairMenuSettings from "./wheelchairSettings/WheelchairMenuSettings";

class MenuSettingsAdmin extends React.Component {
  render() {
    if (Object.keys(this.props.generalSettings).length === 0) return null;
    return (
      <React.Fragment>
        <Tabs defaultActiveKey="home" id="tab-settings" className="mb-3">
          <Tab eventKey="home" title={<FormattedMessage id="General" />}>
            <GeneralSettingsAdmin
              generalSettings={this.props.generalSettings}
            />
          </Tab>

          <Tab
            eventKey="collaborator"
            title={<FormattedMessage id="Collaborators" />}
          >
            <Collaborators collaborators={this.props.collaborator} />
          </Tab>

          <Tab eventKey="SAV" title={<FormattedMessage id="SAV" />}>
            <SAVAdmin />
          </Tab>

          <Tab eventKey="trial" title={<FormattedMessage id="Trial" />}>
            <TrialPerceptionsAdmin
              trialPerceptions={this.props.trialPerceptions}
            />
          </Tab>

          <Tab eventKey="wheelchair" title={<FormattedMessage id="Wheelchairs" />}>
            <WheelchairMenuSettings />
          </Tab>
        </Tabs>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    collaborator: state.collaborator,
    generalSettings: state.generalSettings,
    trialPerceptions: state.trialPerceptions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuSettingsAdmin);
