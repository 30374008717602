import React from "react";
import { connect } from "react-redux";
import CollaboratorTypesTable from "./CollaboratorTypesTable";

class CollaboratorTypes extends React.Component {
  render() {
    let { collaboratorTypes, collaborators } = this.props;
    return (
      <React.Fragment>
        <CollaboratorTypesTable
          collaboratorTypes={collaboratorTypes}
          collaborators={collaborators}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    collaboratorTypes: state.collaboratorTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollaboratorTypes);
