import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { removeBrand } from "../../../../../actions/configurables/brands.js";
import Icon from "../../../../sub/Icon";
import ActionMenu from "../../../../sub/ActionMenu";

class BrandsAdminRow extends React.Component {
  getBrandName(brandId) {
    for (let brand of this.props.brands) {
      if (brand._id === brandId) return brand.name.toString().toUpperCase();
    }
  }

  getModelName(modelId) {
    for (let model of this.props.models) {
      if (model._id === modelId) return model.name;
    }
  }

  getEstablishmentName(establishmentId) {
    for (let establishment of this.props.establishments) {
      if (establishment._id === establishmentId) return establishment.name;
    }
  }

  check(brandId, checkedValue) {
    const { patients, models } = this.props;
    let result = [];

    const checkById = (items) => {
      let result = [];
      for (let item of items) {
        if (item.brand_id === brandId) result.push(item);
      }
      return result;
    }

    if (checkedValue === "wheelchairs") {
      for (const patient of patients) {
        if (!patient.wheelchairs || patient.wheelchairs === 0) return;
  
        result.concat(checkById(patient.wheelchairs));
      }
    } else if (checkedValue === "models") {
      result = checkById(models);
    }

    return result;
  }

  modalDeleteBrand(modalTitle, modalContent, successCallback) {
    let { brand, openConfModal } = this.props;
    let errorsPatients = this.check(brand._id, "wheelchairs");
    let errorsModels = this.check(brand._id, "models");

    if (errorsPatients.length > 0 || errorsModels.length > 0) {
      this.openDataIntegrityModal(errorsPatients, errorsModels);
    } else {
      openConfModal(modalTitle, modalContent, successCallback);
    }
  }

  deleteBrand(brandId) {
    this.props.onDeleteBrand({
      brandId: brandId,
    });
  }

  openDataIntegrityModal(dataProblemsEquipments, dataProblemsModels) {
    var errorModalTitle = <FormattedMessage id="Error" />;
    var errorModalContent = (
      <div>
        <div className="alert alert-danger">
          <div>
            <FormattedMessage
              id="Brand.Can.Not.Be.Deleted"
              values={{ brand: this.getBrandName(this.props.brand._id) }}
            />
          </div>
          {/* {(Object.values(dataProblems).length === 30) && <div><FormattedMessage id="Mercurial.File.Error.Count" values={{ count: Object.values(dataProblems).length }} /></div>} */}
        </div>
        {dataProblemsEquipments.length > 0 && (
          <div>
            <h5 className="pt-3 pb-2">
              <FormattedMessage id="Equipments.List" />
            </h5>
            <table className="table table-striped tablee4vhp">
              <thead>
                <tr className="d-flex">
                  <th scope="col" className="col col-3">
                    <FormattedMessage id="Establishment" />
                  </th>
                  <th scope="col" className="col col-3">
                    <FormattedMessage id="Intern.Ref" />
                  </th>
                  <th scope="col" className="col col-3">
                    <FormattedMessage id="Brand" />
                  </th>
                  <th scope="col" className="col col-3">
                    <FormattedMessage id="Model" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataProblemsEquipments.map((row, index) => {
                  return (
                    <tr key={index} className="d-flex">
                      <td className="col col-3">
                        {this.getEstablishmentName(row.establishment_id)}
                      </td>
                      <td className="col col-3">{row.internal_ref}</td>
                      <td className="col col-3">
                        {this.getBrandName(row.brand_id)}
                      </td>
                      <td className="col col-3">
                        {this.getModelName(row.model_id)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        {dataProblemsModels.length > 0 && (
          <div>
            <h5 className="pt-3 pb-2">
              <FormattedMessage id="Models.List" />
            </h5>
            <table className="table table-striped tablee4vhp">
              <thead>
                <tr className="d-flex">
                  <th scope="col" className="col col-3">
                    <FormattedMessage id="Brand" />
                  </th>
                  <th scope="col" className="col col-9">
                    <FormattedMessage id="Model" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataProblemsModels.map((row, index) => {
                  return (
                    <tr key={index} className="d-flex">
                      <td className="col col-3">
                        {this.getBrandName(row.brand_id)}
                      </td>
                      <td className="col col-9">{row.name}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );

    this.props.openErrorModal(errorModalTitle, errorModalContent);
  }

  render() {
    let { brand, key } = this.props;

    // 'Delete brand modal setup
    var modalTitle = <FormattedMessage id="Confirmation" />;
    var modalContent = (
      <React.Fragment>
        <div className="card text-white bg-danger">
          <div className="card-header">
            <Icon icon="triangle-exclamation" className="mr-2 text-white" />
            <FormattedMessage id="Warning" />
          </div>
          <div className="card-body">
            <p className="card-text">
              <FormattedMessage
                id="Delete.Brand"
                values={{ brand: this.getBrandName(this.props.brand._id) }}
              />
            </p>
          </div>
        </div>
      </React.Fragment>
    );
    var successCallback = () => this.deleteBrand(brand._id);

    let menuItems = [];

    menuItems.push(
      // edit establishment
      {
        icon: "pen-to-square",
        action: () => this.props.openEditModal(brand),
        text: <FormattedMessage id="Modify" />
      },
      // show users
      {
        icon: "trash",
        action: (e) => this.modalDeleteBrand(modalTitle, modalContent, successCallback),
        text: <FormattedMessage id="Delete" />
      },
    );

    let menuAction = (
      <ActionMenu
        items={menuItems}
      />
    );

    return (
      <React.Fragment>
        <tr key={key} className="tre4coll d-flex">
          <td className="col">{brand.name}</td>
          <td className="text-center col col-1">
            {menuAction}
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.user.lang,
    models: state.models,
    establishments: state.establishments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteBrand: (data) => dispatch(removeBrand(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandsAdminRow);
