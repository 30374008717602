import APIUrl from "../../APIUrl";
import axios from "axios";

export const GET_TRIALS = "GET_TRIALS";

export function getTrialAction(trials) {
  return { type: GET_TRIALS, trials: trials };
}

export const getTrialsAdmin = function (callback) {
  return function (dispatch) {
    return axios.get(APIUrl.getTrialsAdmin).then(function (response) {
      dispatch(getTrialAction(response.data));
      if (callback) callback();
    });
  };
};

export const getTrials = function (callback) {
  return function (dispatch) {
    return axios.get(APIUrl.getTrials).then(function (response) {
      dispatch(getTrialAction(response.data));
      if (callback) callback();
    });
  };
};

export const addTrial = function (trial, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addTrial, trial)
      .then(function (response) {
        dispatch(getTrialsAdmin());
        return successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateTrial = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateTrial, data)
      .then(function (response) {
        dispatch(getTrialsAdmin());
        if (successCallback) successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const removeTrial = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.removeTrial, data)
      .then(function (response) {
        dispatch(getTrialsAdmin());
        return successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addTrialFiles = function (
  trialId,
  patientId,
  files,
  successCallback
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addTrialFiles + patientId + "/" + trialId, files, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        dispatch(getTrialsAdmin());
        return successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const getTrialFileAdmin = function (fileName, file, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.getTrialFileAdmin + fileName, file, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        dispatch(getTrialsAdmin());
        return successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};
