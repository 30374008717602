import React from "react";
import { connect } from "react-redux";
import BrandsTableAdmin from "./BrandsAdminTable";

class BrandsAdmin extends React.Component {
  render() {
    return (
      <BrandsTableAdmin
        patients={this.props.patients}
        brands={this.props.brands}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patients: state.patients,
    brands: state.brands,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandsAdmin);
