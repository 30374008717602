export default class Effects {
  // Toggle visibility of an element
  static collapse(elemId) {
    var elem = document.getElementById(elemId);

    if (!elem.classList.contains("show")) {
      elem.classList.add("show");
      return true;
    } else {
      elem.classList.remove("show");
      return false;
    }
  }
}
