import React from "react";

import { nanoid } from "nanoid";

import SignatureCanvas from "react-signature-canvas";

export default class SignatureInput extends React.Component {
  constructor(props) {
    super(props);

    this.id = "signature-" + nanoid(10);

    this.state = {
      img: null,
    };
  }

  // We need this to resize the canvas to parent container
  componentDidMount() {
    this.resizeCanvas();

    window.addEventListener("resize", () => this.resizeCanvas());
  }

  resizeCanvas() {
    if (!this.sigCanvas) return;

    var img = this.sigCanvas.toDataURL();
    if (img) this.setState({ img: img }, this.fixCanvas);

    var canvas = document.getElementById(this.id);
    var container = document.getElementById(this.props.containerId);

    canvas.width = container.offsetWidth;
    canvas.height = container.offsetHeight;
  }

  fixCanvas() {
    if (this.state.img) {
      this.redrawImg(this.state.img);
      this.setState({});
    }
  }

  redrawImg(img) {
    if (!img) return;

    var canvas = document.getElementById(this.id),
      ctx = canvas.getContext("2d");

    var image = new Image();
    image.onload = function () {
      ctx.drawImage(image, 0, 0, canvas.width - 5, canvas.height - 5);
    };

    image.src = img;
  }

  render() {
    return (
      <SignatureCanvas
        ref={(ref) => {
          this.sigCanvas = ref;
        }}
        onEnd={() => this.props.onEnd(this.sigCanvas.toDataURL())}
        canvasProps={{ id: this.id, height: "auto" }}
        backgroundColor="white"
        clearOnResize={false}
      />
    );
  }
}
