import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import ClientsModalAdmin from "./ClientsModalAdmin";
import { getEstablishmentTypes } from "../../actions/configurables/establishmentTypes";
import EstablishmentsRow from "./EstablishmentsRow";
import Paginator from "../sub/Paginator";
import Roles from "../../enums/Roles";
import Util from "../../util/Util";
import { UncontrolledTooltip } from "reactstrap";
import { Alert, Button, Card } from "react-bootstrap";

class Establishments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      nameFilter: "",
      typeFilter: "",
      addressFilter: "",
      phoneFilter: "",
      emailFilter: "",

      modal: null,
    };

    this.paginator = new Paginator(this);
  }

  componentDidMount() {
    if (this.props.establishmentTypes.length === 0)
      this.props.onGetEstablishmentTypes();
  }

  openModal(establishment) {
    this.setState({
      modal: (
        <ClientsModalAdmin
          isOpen={true}
          establishment={establishment}
          openModal={(establishment) => this.openModal(establishment)}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  getClients(establishmentId) {
    var clients = [];

    for (let client of this.props.clients) {
      if (client.establishment_id === establishmentId) {
        clients.push(client);
      }
    }

    return clients;
  }

  areResultsFiltered = () => {
    if (
      !Util.emptyString(this.state.nameFilter) ||
      !Util.emptyString(this.state.typeFilter) ||
      !Util.emptyString(this.state.addressFilter) ||
      !Util.emptyString(this.state.phoneFilter) ||
      !Util.emptyString(this.state.emailFilter)
    ) {
      return true;
    } else {
      return false;
    }
  };

  resetSearchFields() {
    this.setState({
      nameFilter: "",
      typeFilter: "",
      addressFilter: "",
      phoneFilter: "",
      emailFilter: "",
    });
  }

  render() {
    const { user } = this.props;

    if (
      this.props.establishments.length === 0 &&
      this.props.clients.length === 0
    ) {
      return (
        <React.Fragment>
          <div className="row search-filters">
            <div className="col-12">
              <Card>
                <Card.Body>
                  <div className="form-inline">
                    <Alert variant="info" className="w-100">
                      <FormattedMessage id="Empty.Establishment" />
                    </Alert>

                    {user && user.role === Roles.ADMIN && (
                      <Button
                        variant="info"
                        className="ml-auto"
                        onClick={(e) => this.openModal()}
                      >
                        <FormattedMessage id="Establishment.Add" />
                      </Button>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>

          {this.state.modal}
        </React.Fragment>
      );
    }

    this.paginator.init();

    let disableFormInput = this.paginator.paginationIndex !== 1 ? true : false;

    var i = 0;

    let establishmentsSelectNameNode = this.props.establishments.map(
      (establishment) => {
        return (
          <option key={establishment._id} value={establishment.name}>
            {establishment.name.toUpperCase()}
          </option>
        );
      }
    );

    var establishmentsNodes = this.props.establishments.map((establishment) => {
      if (this.state.nameFilter && this.state.nameFilter !== "") {
        if (establishment.name.indexOf(this.state.nameFilter)) return null;
      }

      if (this.state.addressFilter && this.state.addressFilter !== "") {
        if (
          // https://stackoverflow.com/questions/42394239/touppercase-is-not-a-function
          (!establishment.address ||
            establishment.address
              .toString()
              .toUpperCase()
              .indexOf(this.state.addressFilter.toUpperCase()) === -1) &&
          (!establishment.postal_code ||
            establishment.postal_code
              .toString()
              .toUpperCase()
              .indexOf(this.state.addressFilter.toUpperCase()) === -1) &&
          (!establishment.city ||
            establishment.city
              .toString()
              .toUpperCase()
              .indexOf(this.state.addressFilter.toUpperCase()) === -1)
        )
          return null;
      }

      if (this.state.typeFilter && this.state.typeFilter !== "") {
        if (
          !establishment.type_id ||
          establishment.type_id !== this.state.typeFilter
        )
          return null;
      }

      if (this.state.phoneFilter && this.state.phoneFilter !== "") {
        if (
          !establishment.phone ||
          establishment.phone.indexOf(this.state.phoneFilter)
        )
          return null;
      }

      if (this.state.emailFilter && this.state.emailFilter !== "") {
        if (
          !establishment.email ||
          establishment.email.indexOf(this.state.emailFilter)
        )
          return null;
      }

      if (this.props.limit && ++i > this.props.limit) return null;

      if (!this.paginator.keep()) return null;

      return (
        <EstablishmentsRow
          key={establishment._id}
          establishment={establishment}
          openModal={(establishment) => this.openModal(establishment)}
          clients={this.getClients(establishment._id)}
          limit={this.props.limit && true}
        />
      );
    });

    var typeNode = this.props.establishmentTypes.map((centreType) => {
      return (
        <option key={centreType._id} value={centreType._id}>
          {centreType.name.toUpperCase()}{" "}
        </option>
      );
    });

    // Define a button to reset search filters
    let resetSearchButton = () => {
      let button = (
        <button className="btn btn-outline-secondary" disabled>
          <i className="fa fa-filter"></i>
        </button>
      );

      if (this.areResultsFiltered() && !disableFormInput) {
        return (
          <>
            <button
              id="searchFilterBtn"
              className="btn btn-warning"
              onClick={() => this.resetSearchFields()}
            >
              <i className="fa fa-filter"></i>
            </button>
            <UncontrolledTooltip
              delay={{ show: 0, hide: 0 }}
              placement="bottom"
              target="searchFilterBtn"
            >
              <FormattedMessage id="Remove.Filter" />
            </UncontrolledTooltip>
          </>
        );
      } else {
        return button;
      }
    };

    return (
      <React.Fragment>
        {!this.props.limit && (
          <div className="row search-filters">
            <div className="col-12">
              <Card>
                <Card.Body>
                  <div className="form-inline">
                    <label className="my-1 mr-2" htmlFor="search_client">
                      {this.props.intl.formatMessage({ id: "Client" })}
                    </label>
                    <select
                      id="search_client"
                      className="form-control mr-sm-3"
                      onChange={(e) => {
                        this.setState({ nameFilter: e.target.value });
                      }}
                      value={this.state.nameFilter}
                      disabled={disableFormInput}
                    >
                      <option value="">
                        {this.props.intl.formatMessage({ id: "All" })}
                      </option>
                      {establishmentsSelectNameNode}
                    </select>

                    <label className="my-1 mr-2" htmlFor="search_status">
                      {this.props.intl.formatMessage({ id: "Type" })}
                    </label>
                    <select
                      id="search_status"
                      className="form-control mr-sm-3"
                      onChange={(e) => {
                        this.setState({ typeFilter: e.target.value });
                      }}
                      value={this.state.typeFilter}
                      disabled={disableFormInput}
                    >
                      <option value="">
                        {this.props.intl.formatMessage({ id: "All" })}
                      </option>
                      {typeNode}
                    </select>

                    <input
                      id="search_address"
                      className="form-control mr-sm-3"
                      type="search"
                      placeholder={this.props.intl.formatMessage({
                        id: "Address",
                      })}
                      onChange={(e) => {
                        this.setState({ addressFilter: e.target.value });
                      }}
                      disabled={disableFormInput}
                      value={this.state.addressFilter}
                    />

                    <input
                      id="search_phone"
                      className="form-control mr-sm-3"
                      type="search"
                      placeholder={this.props.intl.formatMessage({
                        id: "Phone",
                      })}
                      onChange={(e) => {
                        this.setState({ phoneFilter: e.target.value });
                      }}
                      disabled={disableFormInput}
                      value={this.state.phoneFilter}
                    />

                    <input
                      id="search_email"
                      className="form-control mr-sm-3"
                      type="search"
                      placeholder={this.props.intl.formatMessage({
                        id: "Email",
                      })}
                      onChange={(e) => {
                        this.setState({ emailFilter: e.target.value });
                      }}
                      disabled={disableFormInput}
                      value={this.state.emailFilter}
                    />

                    {resetSearchButton()}

                    {user && user.role === Roles.ADMIN && (
                      <Button
                        variant="info"
                        className="ml-auto"
                        onClick={(e) => this.openModal()}
                      >
                        <FormattedMessage id="Establishment.Add" />
                      </Button>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        )}

        <table className="table tablee4vhp">
          <thead>
            <tr>
              <th>
                <FormattedMessage id="Establishment" />
              </th>
              <th className="d-none d-md-table-cell">
                <FormattedMessage id="Type" />
              </th>
              <th className="d-none d-md-table-cell">
                <FormattedMessage id="Address" />
              </th>
              <th className="d-none d-sm-table-cell">
                <FormattedMessage id="Phone" />
              </th>
              <th className="d-none d-lg-table-cell">
                <FormattedMessage id="Email" />
              </th>
              {!this.props.limit && (
                <th className="text-center">
                  <FormattedMessage id="Actions" />
                </th>
              )}
            </tr>
          </thead>
          <tbody>{establishmentsNodes}</tbody>
        </table>

        {!this.props.limit && this.paginator.render()}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    clients: state.clients,
    establishments: state.establishments,
    establishmentTypes: state.establishmentTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetEstablishmentTypes: () => dispatch(getEstablishmentTypes()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Establishments));
