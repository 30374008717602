import APIUrl from "../../APIUrl";
import axios from "axios";

export const GET_BRANDS = "GET_BRANDS";

function getBrandsAction(brands) {
  return { type: GET_BRANDS, brands: brands };
}

export const getBrands = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getBrands)
      .then(function (response) {
        if (response) dispatch(getBrandsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addBrand = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addBrand, data)
      .then(function (response) {
        dispatch(getBrands());
        if (response) successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateBrand = function (data) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateBrand, data)
      .then(function (response) {
        dispatch(getBrands());
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const removeBrand = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.removeBrand, data)
      .then(function (response) {
        dispatch(getBrands());
        // if(response) console.log(response);
      })
      .catch(function (err) {
        throw err;
      });
  };
};
