import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import UserModal from "./UserModal";
import Paginator from "../sub/Paginator";
import { UncontrolledTooltip } from "reactstrap";

class EstablishmentUsers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };

    this.random = Math.floor(Math.random() * 999999999999) + 1;

    this.paginator = new Paginator(this);
  }

  openModal(client) {
    this.setState({
      modal: (
        <UserModal
          client={client}
          company={this.props.company}
          establishment={this.props.establishment}
          close={() => this.closeModal()}
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  render() {
    this.paginator.init();
    let clientsNode = this.props.clients.map((client) => {
      if (!this.paginator.keep()) return null;

      return (
        <tr key={client._id}>
          <td>{client.username}</td>
          <td>{client.name}</td>
          <td>{client.first_name}</td>
          <td>{client.email}</td>
          <td>{client.function}</td>
          <td className="text-center">
            {client.validator ? (
              <i className="fa fa-check text-success"></i>
            ) : (
              <i className="fa fa-close text-danger"></i>
            )}
          </td>
          <td className="text-center tdaction">
            <i
              id={"modify" + client._id + this.random}
              className="fa fa-edit icon-big"
              onClick={(e) => this.openModal(client)}
            ></i>
            <UncontrolledTooltip
              delay={{ show: 0, hide: 0 }}
              placement="top"
              target={"modify" + client._id + this.random}
            >
              <FormattedMessage id="Modify" />
            </UncontrolledTooltip>
          </td>
        </tr>
      );
    });

    let tableStyle = "table mt-3";
    if (this.props.cssStyle) tableStyle = this.props.cssStyle;

    return (
      <React.Fragment>
        <table className={tableStyle}>
          <thead>
            <tr>
              <th scope="col">
                <FormattedMessage id="Username" />
              </th>
              <th scope="col">
                <FormattedMessage id="Name" />
              </th>
              <th scope="col">
                <FormattedMessage id="First.Name" />
              </th>
              <th scope="col">
                <FormattedMessage id="Email" />
              </th>
              <th scope="col">
                <FormattedMessage id="Function" />
              </th>
              <th scope="col" className="text-center">
                <FormattedMessage id="Validator" />
              </th>
              <th scope="col" className="text-center">
                <FormattedMessage id="Actions" />
              </th>
            </tr>
          </thead>
          <tbody>
            {clientsNode}
            <tr>
              <td className="pt-2 pb-0 mb-0" colSpan="7">
                {this.paginator.render()}
              </td>
            </tr>
          </tbody>
        </table>

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EstablishmentUsers);
