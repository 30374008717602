import { GET_BRANDS } from "../../actions/configurables/brands";

export default function brands(state = [], action) {
  switch (action.type) {
    case GET_BRANDS:
      return action.brands;
    default:
      return state;
  }
}
