import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../util/Util";
import { addPatient, updPatient } from "../../actions/patients/patients";
import DateUtil from "../../util/DateUtil";
import Roles from "../../enums/Roles";
import PatientOccupationalTherapist from "./PatientOccupationalTherapist";
import PatientHelpers from "./PatientHelpers";
import CustomLabel from "../sub/CustomLabel";

class PatientIdentification extends React.Component {
  constructor(props) {
    super(props);

    const { patient } = this.props;

    this.isUpdate = !!patient;

    const getValue = (field, subfield) => {
      if (patient) {
        if (patient[field] && subfield) {
          return patient[field][subfield];
        } else {
          return patient[field];
        }
      } else if (subfield === "prescription_provided") {
        return false;
      } else return "";
    };

    this.state = {
      establishment_id: getValue("establishment_id"),
      first_name: getValue("first_name"),
      name: getValue("name"),
      birth_date: DateUtil.toyyyyMMdd(getValue("birth_date")),
      address: getValue("address"),
      postal_code: getValue("postal_code"),
      city: getValue("city"),
      phone: getValue("phone"),
      mobile: getValue("mobile"),
      email: getValue("email"),

      healthcare: {
        name: getValue("healthcare", "name"),
        phone: getValue("healthcare", "phone"),
        address: getValue("healthcare", "address"),
        postal_code: getValue("healthcare", "postal_code"),
        city: getValue("healthcare", "city"),
        membership_number: getValue("healthcare", "membership_number"),
        prescription_provided: getValue("healthcare", "prescription_provided"),
        phoneError: null,
        postal_codeError: null,
      },

      mutual: {
        name: getValue("mutual", "name"),
        phone: getValue("mutual", "phone"),
        address: getValue("mutual", "address"),
        postal_code: getValue("mutual", "postal_code"),
        city: getValue("mutual", "city"),
        membership_number: getValue("mutual", "membership_number"),
        phoneError: null,
        postal_codeError: null,
      },

      phoneError: null,
      mobileError: null,
      emailError: null,

      disabled: false,
      loading: false,
    };
  }

  stopEvent(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  onSubmit() {
    this.setState({ loading: true });
    if (this.disabled()) return;
    this.setState({ disabled: true });

    const patient = {
      establishment_id: this.state.establishment_id,
      first_name: this.state.first_name,
      name: this.state.name,
      birth_date: this.state.birth_date,
      address: this.state.address,
      postal_code: this.state.postal_code,
      city: this.state.city,
      phone: this.state.phone,
      mobile: this.state.mobile,
      email: this.state.email,

      mutual: {
        name: this.state.mutual["name"],
        phone: this.state.mutual["phone"],
        address: this.state.mutual["address"],
        postal_code: this.state.mutual["postal_code"],
        city: this.state.mutual["city"],
        membership_number: this.state.mutual["membership_number"],
      },

      // settings_SAV: {
      //     select_forfait_SAV: "",
      //     code_LPP: "",
      //     amount_TTC:"",
      //     start_date_SAV: ""
      // },

      healthcare: {
        name: this.state.healthcare["name"],
        phone: this.state.healthcare["phone"],
        address: this.state.healthcare["address"],
        postal_code: this.state.healthcare["postal_code"],
        city: this.state.healthcare["city"],
        membership_number: this.state.healthcare["membership_number"],
        prescription_provided: this.state.healthcare["prescription_provided"],
      },
    };

    // Send to BE
    this.props.onAddPatient(patient, (patient) =>
      this.setState({ loading: false }, () => this.props.openModal(patient))
    );
  }

  disabled() {
    return (
      Util.emptyString(this.state.establishment_id) ||
      Util.emptyString(this.state.name) ||
      Util.emptyString(this.state.first_name) ||
      Util.emptyString(this.state.address) ||
      Util.emptyString(this.state.postal_code) ||
      Util.emptyString(this.state.city) ||
      this.state.phoneError ||
      this.state.mobileError ||
      this.state.emailError ||
      this.state.healthcare["phoneError"] ||
      this.state.healthcare["postal_codeError"] ||
      this.state.mutual["phoneError"] ||
      this.state.mutual["postal_codeError"] ||
      this.state.loading
    );
  }

  onChangeFieldObject(field, subfield, value) {
    this.onChange(field, subfield, { ...this.state[field], [subfield]: value });
  }

  onBlurFieldObject(field, subfield, value) {
    if (field === "healthcare")
      this.onBlur(field, subfield, {
        ...this.state.healthcare,
        [subfield]: value,
      });
    if (field === "mutual")
      this.onBlur(field, subfield, { ...this.state.mutual, [subfield]: value });
  }

  onChange(field, subfield, value) {
    this.setState({ [field]: value });

    if (field === "phone") {
      if (!Util.emptyString(value) && !Util.isPhone(value)) {
        this.setState({
          phoneError: <FormattedMessage id="Invalid.Phone.Number.Error" />,
        });
      } else this.setState({ phoneError: null });
    } else if (field === "postal_code") {
      if (!Util.emptyString(value) && !Util.isPostal(value))
        this.setState({
          postal_codeError: (
            <FormattedMessage id="Invalid.Postal.Number.Error" />
          ),
        });
      else this.setState({ postal_codeError: null });
    } else if (field === "mobile") {
      if (!Util.emptyString(value) && !Util.isPhone(value))
        this.setState({
          mobileError: <FormattedMessage id="Invalid.Phone.Number.Error" />,
        });
      else this.setState({ mobileError: null });
    } else if (field === "email") {
      if (!Util.emptyString(value) && !Util.isEmail(value))
        this.setState({
          emailError: <FormattedMessage id="Invalid.Email.Error" />,
        });
      else this.setState({ emailError: null });
    } else if (field === "healthcare") {
      if (subfield === "phone") {
        if (!Util.emptyString(value.phone) && !Util.isPhone(value.phone)) {
          let healthcare = {
            ...value,
            phoneError: <FormattedMessage id="Invalid.Phone.Number.Error" />,
          };
          this.setState({ healthcare: healthcare });
        } else {
          let healthcare = { ...value, phoneError: null };
          this.setState({ healthcare: healthcare });
        }
      }
      if (subfield === "postal_code") {
        if (
          !Util.emptyString(value.postal_code) &&
          !Util.isPostal(value.postal_code)
        ) {
          let healthcare = {
            ...value,
            postal_codeError: (
              <FormattedMessage id="Invalid.Postal.Number.Error" />
            ),
          };
          this.setState({ healthcare: healthcare });
        } else {
          let healthcare = { ...value, postal_codeError: null };
          this.setState({ healthcare: healthcare });
        }
      }
      if (subfield === "prescription_provided") {
        let healthcare;
        if (value.prescription_provided) {
          healthcare = { ...value, prescription_provided: true };
        } else {
          healthcare = { ...value, prescription_provided: false };
        }
        this.setState({ healthcare: healthcare });
      }
    } else if (field === "mutual") {
      if (subfield === "phone") {
        if (!Util.emptyString(value.phone) && !Util.isPhone(value.phone)) {
          let mutual = {
            ...value,
            phoneError: <FormattedMessage id="Invalid.Phone.Number.Error" />,
          };
          this.setState({ mutual: mutual });
        } else {
          let mutual = { ...value, phoneError: null };
          this.setState({ mutual: mutual });
        }
      }
      if (subfield === "postal_code") {
        if (
          !Util.emptyString(value.postal_code) &&
          !Util.isPostal(value.postal_code)
        ) {
          let mutual = {
            ...value,
            postal_codeError: (
              <FormattedMessage id="Invalid.Postal.Number.Error" />
            ),
          };
          this.setState({ mutual: mutual });
        } else {
          let mutual = { ...value, postal_codeError: null };
          this.setState({ mutual: mutual });
        }
      }
    }
  }

  onBlur(field, subfield, value) {
    if (!this.isUpdate) return;

    if (field === "phone" && this.state.phoneError) return;
    else if (field === "mobile" && this.state.mobileError) return;
    else if (field === "email" && this.state.emailError) return;
    else if (field === "postal_code" && this.state.postal_codeError) return;
    else if (field === "healthcare") {
      if (subfield === "phone" && this.state.healthcare["phoneError"]) return;
      if (
        subfield === "postal_code" &&
        this.state.healthcare["postal_codeError"]
      )
        return;
    } else if (field === "mutual") {
      if (subfield === "phone" && this.state.mutual["phoneError"]) return;
      if (subfield === "postal_code" && this.state.mutual["postal_codeError"])
        return;
    }

    // Required fields
    else if (field === "name" && Util.emptyString(this.state.name)) return;
    else if (field === "first_name" && Util.emptyString(this.state.first_name))
      return;
    else if (field === "city" && Util.emptyString(this.state.city)) return;
    else if (field === "address" && Util.emptyString(this.state.address))
      return;
    else if (
      field === "postal_code" &&
      Util.emptyString(this.state.postal_code)
    )
      return;

    var patient;
    if (field === "healthcare" || field === "mutual") {
      patient = {
        _id: this.props.patient._id,
        [field]: this.state[field],
      };
    } else {
      patient = {
        _id: this.props.patient._id,
        [field]: value,
      };
    }
    this.props.onUpdPatient(patient);
  }

  render() {
    const { establishments } = this.props;

    var disable = false;

    disable = this.props.user.role === Roles.CLIENT;

    const establishmentsNode = establishments.map((establishment) => (
      <option key={establishment._id} value={establishment._id}>
        {establishment.name}
      </option>
    ));

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 col-lg-6 pt-3">
            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Establishment" })}
                htmlFor="establishment_id"
                labelClassName="col-12 col-sm-5 col-form-label"
                required
              />
              <div className="col-12 col-sm-7">
                <select
                  className="form-control"
                  value={this.state.establishment_id}
                  onBlur={(e) =>
                    this.onBlur("establishment_id", null, e.target.value)
                  }
                  onChange={(e) => {
                    this.setState({ establishment_id: e.target.value });
                  }}
                  disabled={disable}
                >
                  {!this.isUpdate && (
                    <option value="">
                      {this.props.intl.formatMessage({ id: "Select" })}
                    </option>
                  )}
                  {establishmentsNode}
                </select>
              </div>
            </div>

            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "First.Name" })}
                htmlFor="first_name"
                labelClassName="col-12 col-sm-5 col-form-label"
                required
              />
              <div className="col-12 col-sm-7">
                <input
                  className="form-control text-capitalize"
                  type="text"
                  id="first_name"
                  autoComplete="off"
                  value={this.state.first_name}
                  onChange={(e) =>
                    this.onChange(
                      "first_name",
                      null,
                      e.target.value.toLowerCase()
                    )
                  }
                  onBlur={(e) =>
                    this.onBlur(
                      "first_name",
                      null,
                      e.target.value.toLowerCase()
                    )
                  }
                  disabled={disable}
                />
              </div>
            </div>

            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Name" })}
                htmlFor="name"
                labelClassName="col-12 col-sm-5 col-form-label"
                required
              />
              <div className="col-12 col-sm-7">
                <input
                  className="form-control"
                  type="text"
                  id="name"
                  autoComplete="off"
                  value={this.state.name}
                  onChange={(e) =>
                    this.onChange("name", null, e.target.value.toUpperCase())
                  }
                  onBlur={(e) =>
                    this.onBlur("name", null, e.target.value.toUpperCase())
                  }
                  disabled={disable}
                />
              </div>
            </div>

            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Birth.Date" })}
                htmlFor="birth_date"
                labelClassName="col-12 col-sm-5 col-form-label"
              />
              <div className="col-12 col-sm-7">
                <input
                  type="date"
                  className="form-control"
                  id="birth_date"
                  autoComplete="off"
                  onChange={(e) =>
                    this.onChange("birth_date", null, e.target.value)
                  }
                  onBlur={(e) =>
                    this.onBlur("birth_date", null, e.target.value)
                  }
                  value={this.state.birth_date}
                  disabled={disable}
                />
              </div>
            </div>

            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Address" })}
                htmlFor="address"
                labelClassName="col-12 col-sm-5 col-form-label"
                required
              />
              <div className="col-12 col-sm-7">
                <input
                  className="form-control"
                  type="text"
                  id="address"
                  autoComplete="off"
                  value={this.state.address}
                  onChange={(e) =>
                    this.onChange("address", null, e.target.value)
                  }
                  onBlur={(e) => this.onBlur("address", null, e.target.value)}
                  disabled={disable}
                />
              </div>
            </div>

            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Postal.Code" })}
                htmlFor="postal_code"
                labelClassName="col-12 col-sm-5 col-form-label"
                required
              />
              <div className="col-12 col-sm-7">
                <input
                  className="form-control"
                  type="text"
                  id="postal_code"
                  autoComplete="off"
                  value={this.state.postal_code}
                  onChange={(e) =>
                    this.onChange("postal_code", null, e.target.value)
                  }
                  onBlur={(e) =>
                    this.onBlur("postal_code", null, e.target.value)
                  }
                  disabled={disable}
                />
                <div className="text-danger">
                  <small>{this.state.postal_codeError}</small>
                </div>
              </div>
            </div>

            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "City" })}
                htmlFor="city"
                labelClassName="col-12 col-sm-5 col-form-label"
                required
              />
              <div className="col-12 col-sm-7">
                <input
                  className="form-control text-uppercase"
                  type="text"
                  id="city"
                  autoComplete="off"
                  value={this.state.city}
                  onChange={(e) =>
                    this.onChange("city", null, e.target.value.toUpperCase())
                  }
                  onBlur={(e) =>
                    this.onBlur("city", null, e.target.value.toUpperCase())
                  }
                  disabled={disable}
                />
              </div>
            </div>

            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Phone" })}
                htmlFor="phone"
                labelClassName="col-12 col-sm-5 col-form-label"
              />
              <div className="col-12 col-sm-7">
                <input
                  className="form-control"
                  type="text"
                  id="phone"
                  autoComplete="off"
                  value={this.state.phone}
                  onChange={(e) => this.onChange("phone", null, e.target.value)}
                  onBlur={(e) => this.onBlur("phone", null, e.target.value)}
                  disabled={disable}
                />
                <div className="text-danger">
                  <small>{this.state.phoneError}</small>
                </div>
              </div>
            </div>

            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Mobile" })}
                htmlFor="mobile"
                labelClassName="col-12 col-sm-5 col-form-label"
              />
              <div className="col-12 col-sm-7">
                <input
                  className="form-control"
                  type="text"
                  id="mobile"
                  autoComplete="off"
                  value={this.state.mobile}
                  onChange={(e) =>
                    this.onChange("mobile", null, e.target.value)
                  }
                  onBlur={(e) => this.onBlur("mobile", null, e.target.value)}
                  disabled={disable}
                />
                <div className="text-danger">
                  <small>{this.state.mobileError}</small>
                </div>
              </div>
            </div>

            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Email" })}
                htmlFor="email"
                labelClassName="col-12 col-sm-5 col-form-label"
              />
              <div className="col-12 col-sm-7">
                <input
                  className="form-control"
                  type="text"
                  id="email"
                  autoComplete="off"
                  value={this.state.email}
                  onChange={(e) => this.onChange("email", null, e.target.value)}
                  onBlur={(e) => this.onBlur("email", null, e.target.value)}
                  disabled={disable}
                />
                <div className="text-danger">
                  <small>{this.state.emailError}</small>
                </div>
              </div>
            </div>
          </div>
          {this.isUpdate && (
            <div className="col-12 col-lg-6">
              <fieldset className="healthcare-fieldset fieldset-form">
                <legend className="healthcare-fieldset">
                  <FormattedMessage id="Occupational.Therapists" />
                </legend>
                <PatientOccupationalTherapist patient={this.props.patient} />

                {/* <div className="form-group row">
                                    <label htmlFor="healthcare_name" className="col-12 col-sm-5 col-form-label"><FormattedMessage id="Name" /></label>
                                    <div className="col-12 col-sm-7">
                                        <input className="form-control" type="text" id="healthcare_name" autoComplete="off"
                                            value={this.state.healthcare["name"]} onChange={(e) => this.onChangeFieldObject("healthcare", "name", e.target.value)}
                                            onBlur={(e) => this.onBlurFieldObject("healthcare", "name", e.target.value)}
                                            disabled={disable} />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="healthcare_phone" className="col-12 col-sm-5 col-form-label"><FormattedMessage id="Phone" /></label>
                                    <div className="col-12 col-sm-7">
                                        <input className="form-control" type="text" id="healthcare_phone" autoComplete="off"
                                            value={this.state.healthcare["phone"]} onChange={(e) => this.onChangeFieldObject("healthcare", "phone", e.target.value)}
                                            onBlur={(e) => this.onBlurFieldObject("healthcare", "phone", e.target.value)}
                                            disabled={disable} />
                                        <div className="text-danger"><small>{this.state.healthcare["phoneError"]}</small></div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="healthcare_address" className="col-12 col-sm-5 col-form-label"><FormattedMessage id="Address" /></label>
                                    <div className="col-12 col-sm-7">
                                        <input className="form-control" type="text" id="healthcare_address" autoComplete="off"
                                            value={this.state.healthcare["address"]} onChange={(e) => this.onChangeFieldObject("healthcare", "address", e.target.value)}
                                            onBlur={(e) => this.onBlurFieldObject("healthcare", "address", e.target.value)}
                                            disabled={disable} />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="healthcare_postal_code" className="col-12 col-sm-5 col-form-label"><FormattedMessage id="Postal.Code" /></label>
                                    <div className="col-12 col-sm-7">
                                        <input className="form-control" type="text" id="healthcare_postal_code" autoComplete="off"
                                            value={this.state.healthcare["postal_code"]} onChange={(e) => this.onChangeFieldObject("healthcare", "postal_code", e.target.value)}
                                            onBlur={(e) => this.onBlurFieldObject("healthcare", "postal_code", e.target.value)}
                                            disabled={disable} />
                                        <div className="text-danger"><small>{this.state.healthcare["postal_codeError"]}</small></div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="healthcare_city" className="col-12 col-sm-5 col-form-label"><FormattedMessage id="City" /></label>
                                    <div className="col-12 col-sm-7">
                                        <input className="form-control" type="text" id="healthcare_city" autoComplete="off"
                                            value={this.state.healthcare["city"]} onChange={(e) => this.onChangeFieldObject("healthcare", "city", e.target.value)}
                                            onBlur={(e) => this.onBlurFieldObject("healthcare", "city", e.target.value)}
                                            disabled={disable} />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="healthcare_membership_number" className="col-12 col-sm-5 col-form-label"><FormattedMessage id="Membership.Number" /></label>
                                    <div className="col-12 col-sm-7">
                                        <input className="form-control" type="text" id="healthcare_membership_number" autoComplete="off"
                                            value={this.state.healthcare["membership_number"]} onChange={(e) => this.onChangeFieldObject("healthcare", "membership_number", e.target.value)}
                                            onBlur={(e) => this.onBlurFieldObject("healthcare", "city", e.target.value)}
                                            disabled={disable} />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="healthcare_prescription_provided" className="col-12 col-sm-5 col-form-label"><FormattedMessage id="Prescription.Provided" /></label>
                                    <div className="col-12 col-sm-7 d-flex align-items-center">
                                        <div className="custom-control custom-switch mx-auto d-inline-block">
                                            <input onChange={(e) => { this.onChangeFieldObject("healthcare", "prescription_provided", e.target.checked); }}
                                                onBlur={(e) => this.onBlurFieldObject("healthcare", "prescription_provided", e.target.checked)}
                                                type="checkbox"
                                                className={"custom-control-input switch-bg-blue"}
                                                id={"switch-av"}
                                                checked={this.state.healthcare.prescription_provided}
                                                disabled={disable} />
                                            <label className="custom-control-label cursor-pointer" htmlFor={"switch-av"}></label>
                                        </div>
                                    </div>
                                </div> */}
              </fieldset>
            </div>
          )}
          {this.isUpdate && (
            <div className="col-12">
              <fieldset className="mutual-fieldset fieldset-form">
                <legend className="mutual-fieldset">
                  <FormattedMessage id="Helpers" />
                </legend>
                <PatientHelpers
                  patient={this.props.patient}
                  openModal={(patient) => this.props.openModal(patient)}
                />
                {/* <div className="form-group row">
                                    <label htmlFor="mutual_name" className="col-12 col-sm-5 col-form-label"><FormattedMessage id="Name" /></label>
                                    <div className="col-12 col-sm-7">
                                        <input className="form-control" type="text" id="mutual_name" autoComplete="off"
                                            value={this.state.mutual["name"]} onChange={(e) => this.onChangeFieldObject("mutual", "name", e.target.value)}
                                            onBlur={(e) => this.onBlurFieldObject("mutual", "name", e.target.value)}
                                            disabled={disable} />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="mutual_phone" className="col-12 col-sm-5 col-form-label"><FormattedMessage id="Phone" /></label>
                                    <div className="col-12 col-sm-7">
                                        <input className="form-control" type="text" id="mutual_phone" autoComplete="off"
                                            value={this.state.mutual["phone"]} onChange={(e) => this.onChangeFieldObject("mutual", "phone", e.target.value)}
                                            onBlur={(e) => this.onBlurFieldObject("mutual", "phone", e.target.value)}
                                            disabled={disable} />
                                        <div className="text-danger"><small>{this.state.mutual["phoneError"]}</small></div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="mutual_address" className="col-12 col-sm-5 col-form-label"><FormattedMessage id="Address" /></label>
                                    <div className="col-12 col-sm-7">
                                        <input className="form-control" type="text" id="mutual_address" autoComplete="off"
                                            value={this.state.mutual["address"]} onChange={(e) => this.onChangeFieldObject("mutual", "address", e.target.value)}
                                            onBlur={(e) => this.onBlurFieldObject("mutual", "address", e.target.value)}
                                            disabled={disable} />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="mutual_postal_code" className="col-12 col-sm-5 col-form-label"><FormattedMessage id="Postal.Code" /></label>
                                    <div className="col-12 col-sm-7">
                                        <input className="form-control" type="text" id="mutual_postal_code" autoComplete="off"
                                            value={this.state.mutual["postal_code"]} onChange={(e) => this.onChangeFieldObject("mutual", "postal_code", e.target.value)}
                                            onBlur={(e) => this.onBlurFieldObject("mutual", "postal_code", e.target.value)} 
                                            disabled={disable}/>
                                        <div className="text-danger"><small>{this.state.mutual["postal_codeError"]}</small></div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="mutual_city" className="col-12 col-sm-5 col-form-label"><FormattedMessage id="City" /></label>
                                    <div className="col-12 col-sm-7">
                                        <input className="form-control" type="text" id="mutual_city" autoComplete="off"
                                            value={this.state.mutual["city"]} onChange={(e) => this.onChangeFieldObject("mutual", "city", e.target.value)}
                                            onBlur={(e) => this.onBlurFieldObject("mutual", "city", e.target.value)}
                                            disabled={disable} />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="mutual_membership_number" className="col-12 col-sm-5 col-form-label"><FormattedMessage id="Membership.Number" /></label>
                                    <div className="col-12 col-sm-7">
                                        <input className="form-control" type="text" id="mutual_membership_number" autoComplete="off"
                                            value={this.state.mutual["membership_number"]} onChange={(e) => this.onChangeFieldObject("mutual", "membership_number", e.target.value)}
                                            onBlur={(e) => this.onBlurFieldObject("mutual", "membership_number", e.target.value)}
                                            disabled={disable} />
                                    </div>
                                </div> */}
              </fieldset>
            </div>
          )}

          {!this.isUpdate && this.props.user.role !== Roles.CLIENT && (
            <div className="col-12 col-lg-6 offset-lg-3 mt-3">
              <button
                className="btn btn-info btn-block"
                disabled={this.state.disabled || this.disabled()}
                onClick={() => this.onSubmit()}
              >
                <FormattedMessage id="Add" />
              </button>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    establishments: state.establishments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddPatient: (patient, successCallback) =>
      dispatch(addPatient(patient, successCallback)),
    onUpdPatient: (patient) => dispatch(updPatient(patient)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(PatientIdentification));
