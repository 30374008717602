import React from "react";
import { connect } from "react-redux";
import APIUrl from "../../APIUrl";
// import FileUtil from '../../util/FileUtil';

import { FormattedMessage } from "react-intl";
import { Alert, Modal } from "react-bootstrap";

class VideoPlayer extends React.Component {
  stopEvent(e) {
    //e.preventDefault();
    e.stopPropagation();
  }

  render() {
    const { videos, path } = this.props;

    return (
      <React.Fragment>
        <div className="modal-bg show">
          <Modal
            show={true}
            onHide={() => this.props.close()}
            backdrop={"static"}
            dialogClassName={
              "modal-dialog xl no-max-height height-full height-auto"
            }
            size={"xl"}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <FormattedMessage id="Videos" />
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div className="row">
                {videos &&
                  videos.map((video) => (
                    <div key={video} className="col-12 pl-5 pr-5">
                      <video
                        controls
                        width="100%"
                        key={path + video + "?token=" + APIUrl.jwtToken}
                      >
                        <source
                          src={path + video + "?token=" + APIUrl.jwtToken}
                          type="video/mp4"
                        />
                      </video>
                    </div>
                  ))}
              </div>

              {(!videos || videos.length === 0) && (
                <Alert variant={"secondary"} className="mt-3">
                  <FormattedMessage id="Empty.Video" />
                </Alert>
              )}
            </Modal.Body>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoPlayer);
