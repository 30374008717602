import React from "react";
import PaginationComponent from "react-js-pagination";

export default class Pagination {
  constructor(parent) {
    this.parent = parent;
    this.nbrElems = 0;
    this.paginationIndex = 1;
    this.paginationElems = 5;
    this.pageRangeDisplayed = 10;
  }

  keep() {
    if (
      this.nbrElems < (this.paginationIndex - 1) * this.paginationElems ||
      this.nbrElems >= this.paginationIndex * this.paginationElems
    ) {
      // Increment
      this.nbrElems++;

      return false;
    }

    // Increment
    this.nbrElems++;

    return true;
  }

  init() {
    this.nbrElems = 0;
  }

  updateIndex(i) {
    this.paginationIndex = i;
    this.nbrElems = 0;

    this.parent.setState({});
  }

  handlePageChange(pageNumber) {
    this.updateIndex(pageNumber);
    // console.log(`active page is ${pageNumber}`);
    this.paginationIndex = pageNumber;
  }

  render() {
    if (this.nbrElems < this.paginationElems) return null;

    return (
      <div className="pagination justify-content-center">
        <PaginationComponent
          activePage={this.paginationIndex}
          itemsCountPerPage={this.paginationElems}
          totalItemsCount={this.nbrElems}
          pageRangeDisplayed={this.pageRangeDisplayed}
          onChange={this.handlePageChange.bind(this)}
          itemClass="page-item"
          linkClass="page-link text-info"
          activeLinkClass="bg-info paginator-active-page"
          firstPageText={
            <>
              <i className="fa fa-caret-left"></i>
              <i className="fa fa-caret-left"></i>
            </>
          }
          prevPageText={<i className="fa fa-caret-left"></i>}
          nextPageText={<i className="fa fa-caret-right"></i>}
          lastPageText={
            <>
              <i className="fa fa-caret-right"></i>
              <i className="fa fa-caret-right"></i>
            </>
          }
        />
      </div>
    );
  }
}
