import React from "react";
import { connect } from "react-redux";
import ModelsAdminTable from "./ModelsAdminTable";

class ModelsAdmin extends React.Component {
  render() {
    return (
      <ModelsAdminTable
        patients={this.props.patients}
        brands={this.props.brands}
        models={this.props.models}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patients: state.patients,
    brands: state.brands,
    models: state.models,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModelsAdmin);
