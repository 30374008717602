import { GET_TRIALS } from "../../actions/trials/trials";

export default function trials(state = [], action) {
  switch (action.type) {
    case GET_TRIALS:
      return action.trials;
    default:
      return state;
  }
}
