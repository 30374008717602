import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, Modal } from "react-bootstrap";

class ConfirmationModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
      mandatoryConfirmation: this.props.mandatoryConfirmation ? (Math.floor(Math.random() * (999999 - 100001 + 1) + 100001)).toString() : false,
      inputConfirmationData: ""
    };
  }

  close() {
    this.props.closeModal();
  }

  confirm() {
    // Little hack there. Should have used proper callbacks, but the modal was used everywhere,
    // so used async/await no to break the existing code.
    var callback = async () => {
      await this.props.successCallback();
      this.close();
    };

    this.setState({ disabled: true }, callback);
  }

  verifyRequiredInput(data) {
    this.setState({ inputConfirmationData: data });
  }

  render() {
    let sizeClassName = "";
    if (this.props.size) sizeClassName = "modal-" + this.props.size;

    var title = <FormattedMessage id="Confirm" />;
    if (this.props.title) title = this.props.title;

    return (
      <div className="modal-bg show">
        <Modal
          show={true}
          onHide={() => this.close()}
          backdrop={"static"}
          dialogClassName={"modal-dialog " + sizeClassName}
          size={sizeClassName}
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {this.props.content}

            {this.state.mandatoryConfirmation && (
              <p className="text-danger mt-2">
                <strong>
                  <FormattedMessage
                    id="Mandatory.Confirmation.Message"
                    values={{ code: this.state.mandatoryConfirmation }}
                  />
                </strong>
                <br />
                <input
                  type="text"
                  className="form-control border-danger mt-1"
                  placeholder={this.props.intl.formatMessage({ id: "Code" })}
                  value={this.state.inputConfirmationData}
                  onChange={(e) => this.verifyRequiredInput(e.target.value)}
                />
              </p>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.close()}>
              <FormattedMessage id="Cancel" />
            </Button>
            <Button
              variant="info"
              onClick={() => this.confirm()}
              disabled={this.state.mandatoryConfirmation ? (this.state.inputConfirmationData !== this.state.mandatoryConfirmation) : this.state.disabled}
            >
              <FormattedMessage id="Confirm" />
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ConfirmationModal));
