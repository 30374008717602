import { GET_GENERAL_SETTINGS } from "../../../actions/settings/generalSettings/generalSettings";

export default function generalSettings(state = {}, action) {
  switch (action.type) {
    case GET_GENERAL_SETTINGS:
      return action.generalSettings;
    default:
      return state;
  }
}
