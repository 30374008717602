import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from "react-intl";
import PatientModal from "./PatientModal";
import Paginator from "../sub/Paginator";
import PatientRow from "./PatientRow";
import ConfirmationModal from "../sub/modals/ConfirmationModal";
import { deletePatient } from "../../actions/patients/patients";
import EquipmentsModal from "./EquipmentsModal";
import Roles from "../../enums/Roles";
import ElementsPdfsModal from "../pdf/ElementsPdfsModal";
import Util from "../../util/Util";
import { UncontrolledTooltip } from "reactstrap";
import { Alert, Button, Card } from "react-bootstrap";

class Patients extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      patientFilter: "",
      centerFilter: "",
      therapistFilter: "",

      modal: null,
    };

    this.paginator = new Paginator(this);
  }

  openModal(patient, section) {
    this.setState({ modal: null }, () =>
      this.setState({
        modal: (
          <PatientModal
            section={section}
            isOpen={true}
            patientId={patient ? patient._id : null}
            openModal={(patient) => this.openModal(patient)}
            close={() => this.closeModal()}
          />
        ),
      })
    );
  }

  delete(patient) {
    if (!patient) return;

    const onConfirm = () => {
      // Send to BE
      this.props.onDeletePatient(patient._id);
    };

    this.setState({
      modal: (
        <ConfirmationModal
          isOpen={true}
          title={<FormattedMessage id="Confirm" />}
          content={<FormattedMessage id="Confirm.Patient.Removal" />}
          successCallback={onConfirm}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  displaysEquipmentModal(patient) {
    if (!patient) return;
    this.setState({
      modal: (
        <EquipmentsModal
          isOpen={true}
          auditEquipments={this.props.auditEquipments}
          patient={patient}
          close={() => this.closeModal()}
        />
      ),
    });
  }

  elementsPdfsModal(patient) {
    if (!patient) return;
    this.setState({
      modal: (
        <ElementsPdfsModal
          isOpen={true}
          audits={this.props.audits}
          patient={patient}
          close={() => this.closeModal()}
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  areResultsFiltered = () => {
    if (
      !Util.emptyString(this.state.patientFilter) ||
      !Util.emptyString(this.state.centerFilter) ||
      !Util.emptyString(this.state.therapistFilter)
    ) {
      return true;
    } else {
      return false;
    }
  };

  resetSearchFields() {
    this.setState({
      patientFilter: "",
      centerFilter: "",
      therapistFilter: "",
    });
  }

  render() {
    const { patients, user } = this.props;

    if (!user) return null;

    // No patients
    if (!patients || patients.length === 0) {
      return (
        <React.Fragment>
          <div className="row search-filters">
            <div className="col-12">
              <Card>
                <Card.Body>
                  <div className="form-inline">
                    {this.props.user.role === Roles.CLIENT && (
                      <Alert variant="info" className="w-100">
                        <FormattedMessage id={"Empty.Patient"} />
                      </Alert>
                    )}
                    {this.props.user.role === Roles.ADMIN && (
                      <Alert variant="info" className="w-100">
                        <FormattedMessage id={"Empty.Patient"} />
                      </Alert>
                    )}
                    {this.props.user.role === Roles.SALES_REP && (
                      <Alert variant="info" className="w-100">
                        <FormattedMessage id={"Empty.Patient.Collaborator"} />
                      </Alert>
                    )}

                    {user && user.role === Roles.ADMIN && (
                      <Button
                        variant="info"
                        className="ml-auto"
                        onClick={(e) => this.openModal()}
                      >
                        <FormattedMessage id="Patient.Add" />
                      </Button>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>

          {this.state.modal}
        </React.Fragment>
      );
    }

    this.paginator.init();

    let disableFormInput = this.paginator.paginationIndex !== 1 ? true : false;

    var i = 0;

    let establishmentsSelectNameNode = this.props.establishments.map(
      (establishment) => {
        return (
          <option key={establishment._id} value={establishment.name}>
            {establishment.name.toUpperCase()}
          </option>
        );
      }
    );

    let patientsNode = patients.map((patient) => {
      if (this.props.limit && ++i > this.props.limit) return null;

      if (this.state.patientFilter && this.state.patientFilter !== "") {
        if (
          patient.name
            .toUpperCase()
            .indexOf(this.state.patientFilter.toUpperCase()) === -1 &&
          patient.first_name
            .toUpperCase()
            .indexOf(this.state.patientFilter.toUpperCase()) === -1
        )
          return null;
      }

      if (this.state.centerFilter && this.state.centerFilter !== "") {
        let establishment;
        establishment = this.props.establishments.find(
          (establishment) => establishment._id === patient.establishment_id
        );

        if (
          !establishment ||
          !establishment.name ||
          establishment.name
            .toString()
            .toUpperCase()
            .indexOf(this.state.centerFilter.toUpperCase()) === -1
        )
          return null;
      }

      if (this.state.therapistFilter && this.state.therapistFilter !== "") {
        let therapist = this.props.clients.find(
          (client) => client._id === patient.prescribers_ids[0]
        );
        if (
          !therapist ||
          !therapist.first_name ||
          !therapist.name ||
          (
            therapist.first_name.toUpperCase() +
            " " +
            therapist.name.toUpperCase()
          ).indexOf(this.state.therapistFilter.toUpperCase()) === -1
        )
          return null;
      }

      if (!this.paginator.keep()) return null;

      return (
        <PatientRow
          key={patient._id}
          patient={patient}
          edit={(patient) => this.openModal(patient)}
          delete={(patient) => this.delete(patient)}
          displaysEquipmentModal={(patient) =>
            this.displaysEquipmentModal(patient)
          }
          elementsPdfsModal={(patient) => this.elementsPdfsModal(patient)}
          openModal={(section) => this.openModal(patient, section)}
          limit={this.props.limit && true}
        />
      );
    });

    // Define a button to reset search filters
    let resetSearchButton = () => {
      let button = (
        <button className="btn btn-outline-secondary" disabled>
          <i className="fa fa-filter"></i>
        </button>
      );

      if (this.areResultsFiltered() && !disableFormInput) {
        return (
          <>
            <button
              id="searchFilterBtn"
              className="btn btn-warning"
              onClick={() => this.resetSearchFields()}
            >
              <i className="fa fa-filter"></i>
            </button>
            <UncontrolledTooltip
              delay={{ show: 0, hide: 0 }}
              placement="bottom"
              target="searchFilterBtn"
            >
              <FormattedMessage id="Remove.Filter" />
            </UncontrolledTooltip>
          </>
        );
      } else {
        return button;
      }
    };

    return (
      <React.Fragment>
        {!this.props.limit && (
          <div className="row search-filters">
            <div className="col-12">
              <Card>
                <Card.Body>
                  <div className="form-inline">
                    <label className="my-1 mr-2" htmlFor="search_name">
                      {this.props.intl.formatMessage({ id: "Patient" })}
                    </label>
                    <input
                      id="search_name"
                      type="text"
                      className="form-control mr-sm-3"
                      placeholder={
                        this.props.intl.formatMessage({ id: "Name" }) +
                        " " +
                        this.props.intl.formatMessage({ id: "Or" }) +
                        " " +
                        this.props.intl.formatMessage({ id: "First.Name" })
                      }
                      onChange={(e) => {
                        this.setState({ patientFilter: e.target.value });
                      }}
                      disabled={disableFormInput}
                      value={this.state.patientFilter}
                    />

                    <label className="my-1 mr-2" htmlFor="search_establishment">
                      {this.props.intl.formatMessage({ id: "Client" })}
                    </label>
                    <select
                      id="search_establishment"
                      className="form-control mr-sm-3"
                      onChange={(e) => {
                        this.setState({ centerFilter: e.target.value });
                      }}
                      value={this.state.centerFilter}
                      disabled={disableFormInput}
                    >
                      <option value="">
                        {this.props.intl.formatMessage({ id: "All" })}
                      </option>
                      {establishmentsSelectNameNode}
                    </select>

                    <label className="my-1 mr-2" htmlFor="search_therapist">
                      {this.props.intl.formatMessage({ id: "Therapist" })}
                    </label>
                    <input
                      id="search_therapist"
                      type="text"
                      className="form-control mr-sm-3"
                      placeholder={
                        this.props.intl.formatMessage({ id: "Name" }) +
                        " " +
                        this.props.intl.formatMessage({ id: "Or" }) +
                        " " +
                        this.props.intl.formatMessage({ id: "First.Name" })
                      }
                      onChange={(e) => {
                        this.setState({ therapistFilter: e.target.value });
                      }}
                      disabled={disableFormInput}
                      value={this.state.therapistFilter}
                    />

                    {resetSearchButton()}

                    {user && user.role === Roles.ADMIN && (
                      <Button
                        variant="info"
                        className="ml-auto"
                        onClick={(e) => this.openModal()}
                      >
                        <FormattedMessage id="Patient.Add" />
                      </Button>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        )}

        <table className="table tablee4vhp">
          <thead>
            <tr className="d-flex align-items-center h-100">
              <th scope="col" className="col">
                <FormattedMessage id="Name" />{" "}
              </th>
              {this.props.user.role !== Roles.CLIENT && (
                <th scope="col" className="col">
                  <FormattedMessage id="Center" />{" "}
                </th>
              )}
              {this.props.user.role !== Roles.CLIENT && (
                <th scope="col" className="col">
                  <FormattedMessage id="Therapist" />{" "}
                </th>
              )}
              <th scope="col" className="col">
                <FormattedMessage id="Type" />{" "}
              </th>
              <th scope="col" className="col text-center">
                <FormattedHTMLMessage id="Expression.Needs.Split" />
              </th>
              <th scope="col" className="col text-center">
                <FormattedHTMLMessage id="Anthopometric.Measures" />
              </th>
              <th scope="col" className="col text-center">
                <FormattedHTMLMessage id="Test.Debriefs" />
              </th>
              <th scope="col" className="col text-center">
                <FormattedHTMLMessage id="Documents" />
              </th>
              <th scope="col" className="col text-center">
                <FormattedHTMLMessage id="SAV" />
              </th>
              <th scope="col" className="col text-center">
                <FormattedHTMLMessage id="New.Trials" />
              </th>

              {!this.props.limit && (
                <th scope="col" className="col text-center">
                  <FormattedMessage id="Actions" />{" "}
                </th>
              )}
              {/* {this.props.user.role === Roles.CLIENT &&
                                    <React.Fragment>
                                        <th className="d-none d-md-table-cell text-center"><FormattedMessage id="Elements.PDFS.Title" /> </th>
                                        <th className="d-none d-md-table-cell text-center"><FormattedMessage id="Equipments" /> </th>
                                    </React.Fragment>
                                } */}
            </tr>
          </thead>
          <tbody>{patientsNode}</tbody>
        </table>

        {!this.props.limit && this.paginator.render()}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auditEquipments: state.auditEquipments,
    patients: state.patients,
    clients: state.clients,
    establishment: state.establishment,
    establishments: state.establishments,
    user: state.user,
    audits: state.audits,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeletePatient: (patientId) => dispatch(deletePatient(patientId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Patients));
