import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import { logout } from "../../../actions/authentication/authentication";
import { Link } from "react-router-dom";

class MenuProfile extends React.Component {
  logout(e) {
    e.preventDefault();
    e.stopPropagation();

    let that = this;
    function onLogoutSuccess() {
      that.props.history.push("/");
    }

    // Call logout and redirect on success
    this.props.onLogout(onLogoutSuccess);
  }

  render() {
    return (
      <div className="dropdown">
        <button
          className="btn btn-info dropdown-toggle text-nowrap"
          type="button"
          id="dropdownMenuLink"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i className="fa fa-user"></i>
        </button>
        <div
          className="dropdown-menu dropdown-menu-right"
          aria-labelledby="dropdownMenuLink"
        >
          <h6 className="dropdown-header">
            {this.props.user.first_name} {this.props.user.name}
          </h6>
          <div className="dropdown-divider"></div>
          <Link to="/home/account" className="dropdown-item">
            <i className="fa fa-edit"></i>
            <FormattedMessage id="My.Account" />
          </Link>
          <div className="dropdown-divider"></div>
          <Link
            to="/#"
            className="dropdown-item"
            onClick={(e) => this.logout(e)}
          >
            <i className="fa fa-power-off"></i>
            <FormattedMessage id="Disconnect" />
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.i18n.lang,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: (successCallback) => dispatch(logout(successCallback)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MenuProfile)
);
