import APIUrl from "../../APIUrl";
import axios from "axios";

export const GET_ESTABLISHMENT_TYPES = "GET_ESTABLISHMENT_TYPES";

function getEstablishmentTypesAction(establishmentTypes) {
  return {
    type: GET_ESTABLISHMENT_TYPES,
    establishmentTypes: establishmentTypes,
  };
}

export const getEstablishmentTypes = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getEstablishmentTypes)
      .then(function (response) {
        dispatch(getEstablishmentTypesAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addEstablishmentType = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addEstablishmentType, data)
      .then(function (response) {
        dispatch(getEstablishmentTypes());
        successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};
