import { GET_ESTABLISHMENT_SETTINGS } from "../../actions/establishments/establishmentSettings";

export default function establishmentSettings(state = {}, action) {
  switch (action.type) {
    case GET_ESTABLISHMENT_SETTINGS:
      return action.establishmentSettings;
    default:
      return state;
  }
}
