import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import Util from "../../util/Util";
import Role from "../../enums/Roles";
import { updateUser } from "../../actions/user/user";

class UpdateAccountForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: this.props.user.username,
      name: this.props.user.name,
      first_name: this.props.user.first_name,
      email: this.props.user.email,
      phone: this.props.user.phone,
      function: this.props.user.function,
      emailError: null,
    };
  }

  onChange(key, value) {
    this.setState({ [key]: value });
  }

  update(field, value) {
    if (Util.emptyString(value)) return;

    if (field === "email" && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      this.setState({ emailError: <FormattedMessage id="Invalid.Email" /> });
      return;
    } else {
      this.setState({ emailError: null });
    }

    var data = {
      updatedField: field,
      updatedValue: value,
    };

    this.props.onUpdateUser(data);
  }

  render() {
    return (
      <div className="col-12 col-sm-8">
        <div className="form-group row">
          <label htmlFor="username" className="col-12 col-sm-5 col-form-label">
            <FormattedMessage id="Username" />
          </label>
          <div className="col-12 col-sm-7">
            <input
              className="form-control"
              type="text"
              id="username"
              autoComplete="off"
              value={this.state.username || ""}
              disabled={true}
            />
          </div>
        </div>
        <div className="form-group row">
          <label
            htmlFor="first_name"
            className="col-12 col-sm-5 col-form-label"
          >
            <FormattedMessage id="First.Name" />
          </label>
          <div className="col-12 col-sm-7">
            <input
              className="form-control"
              type="text"
              id="first_name"
              autoComplete="off"
              value={this.state.first_name || ""}
              onChange={(e) => this.onChange("first_name", e.target.value)}
              onBlur={() => this.update("first_name", this.state.first_name)}
            />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="name" className="col-12 col-sm-5 col-form-label">
            <FormattedMessage id="Name" />
          </label>
          <div className="col-12 col-sm-7">
            <input
              className="form-control"
              type="text"
              id="name"
              autoComplete="off"
              value={this.state.name || ""}
              onChange={(e) => this.onChange("name", e.target.value)}
              onBlur={() => this.update("name", this.state.name)}
            />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="email" className="col-12 col-sm-5 col-form-label">
            <FormattedMessage id="Email" />
          </label>
          <div className="col-12 col-sm-7">
            <input
              className="form-control"
              type="email"
              id="email"
              autoComplete="off"
              value={this.state.email || ""}
              onChange={(e) => this.onChange("email", e.target.value)}
              onBlur={() => this.update("email", this.state.email)}
            />
            <small className="text-danger">{this.state.emailError}</small>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="function" className="col-12 col-sm-5 col-form-label">
            <FormattedMessage id="Function" />
          </label>
          <div className="col-12 col-sm-7">
            <input
              className="form-control"
              type="text"
              id="function"
              autoComplete="off"
              value={this.state.function || ""}
              onChange={(e) => this.onChange("function", e.target.value)}
              onBlur={() => this.update("function", this.state.function)}
            />
          </div>
        </div>
        {this.props.user.role === Role.CLIENT && (
          <EstablishmentComponent establishment={this.props.establishment} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateUser: (data) => dispatch(updateUser(data)),
    // onCheckIfUpdatedEmailExists: (email, existsCallback, noExistsCallback) => dispatch(checkIfUpdatedEmailExists(email, existsCallback, noExistsCallback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateAccountForm);

class EstablishmentComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      establishment: this.props.establishment.name,
      address: this.props.establishment.address,
      postal_code: this.props.establishment.postal_code,
      phone: this.props.establishment.phone,
      city: this.props.establishment.city,
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="form-group row">
          <label
            htmlFor="establishment"
            className="col-12 col-sm-5 col-form-label"
          >
            <FormattedMessage id="Establishment" />
          </label>
          <div className="col-12 col-sm-7">
            <input
              className="form-control"
              type="text"
              id="establishment"
              autoComplete="off"
              value={this.state.establishment || ""}
              disabled={true}
            />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="address" className="col-12 col-sm-5 col-form-label">
            <FormattedMessage id="Address" />
          </label>
          <div className="col-12 col-sm-7">
            <input
              className="form-control"
              type="text"
              id="address"
              autoComplete="off"
              value={this.state.address || ""}
              disabled={true}
            />
          </div>
        </div>
        <div className="form-group row">
          <label
            htmlFor="postal_code"
            className="col-12 col-sm-5 col-form-label"
          >
            <FormattedMessage id="Postal.Code" />
          </label>
          <div className="col-12 col-sm-7">
            <input
              className="form-control"
              type="text"
              id="postal_code"
              autoComplete="off"
              value={this.state.postal_code || ""}
              disabled={true}
            />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="city" className="col-12 col-sm-5 col-form-label">
            <FormattedMessage id="City" />
          </label>
          <div className="col-12 col-sm-7">
            <input
              className="form-control"
              type="text"
              id="city"
              autoComplete="off"
              value={this.state.city || ""}
              disabled={true}
            />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="phone" className="col-12 col-sm-5 col-form-label">
            <FormattedMessage id="Phone" />
          </label>
          <div className="col-12 col-sm-7">
            <input
              className="form-control"
              type="text"
              id="phone"
              autoComplete="off"
              value={this.state.phone || ""}
              disabled={true}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
