import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../util/Util";
import { updPatient } from "../../actions/patients/patients";
import { Button, Modal } from "react-bootstrap";
import CustomLabel from "../sub/CustomLabel";
class AddPatientHelperModal extends React.Component {
  constructor(props) {
    super(props);

    const { helper } = this.props;

    this.isUpdate = !!helper;

    const getValue = (field) => (helper ? helper[field] : "");

    this.state = {
      first_name: getValue("first_name"),
      name: getValue("name"),
      link: getValue("link"),
      other: getValue("other"),
      email: getValue("email"),
      phone: getValue("phone"),

      loading: false,
      emailError: null,
      phoneError: null,
    };
  }

  stopEvent(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  onSubmit() {
    this.setState({ loading: true });

    var patient = Object.assign({}, this.props.patient);

    const helper = {
      first_name: this.state.first_name,
      name: this.state.name,
      link: this.state.link,
      email: this.state.email,
      other: this.state.other,
      phone: this.state.phone,
    };

    if (!patient.helpers) patient.helpers = [];
    patient.helpers.push(helper);

    // Send to BE
    this.props.onUpdPatient(patient, () => this.props.close());
  }

  onChange(field, value) {
    this.setState({ [field]: value });

    if (field === "email" && !Util.emptyString(value) && !Util.isEmail(value))
      this.setState({
        emailError: <FormattedMessage id="Invalid.Email.Error" />,
      });
    else this.setState({ emailError: null });

    if (field === "phone" && !Util.emptyString(value) && !Util.isPhone(value))
      this.setState({
        phoneError: <FormattedMessage id="Invalid.Phone.Number.Error" />,
      });
    else this.setState({ phoneError: null });
  }

  onBlur(field, value) {
    const { patient, helper } = this.props;

    if (field === "email" && !Util.emptyString(value) && !Util.isEmail(value)) {
      this.setState({
        emailError: <FormattedMessage id="Invalid.Email.Error" />,
      });
      return;
    } else {
      this.setState({ emailError: null });
    }

    if (field === "phone" && !Util.emptyString(value) && !Util.isPhone(value)) {
      this.setState({
        phoneError: <FormattedMessage id="Invalid.Phone.Number.Error" />,
      });
      return;
    } else {
      this.setState({ phoneError: null });
    }

    if (!this.isUpdate || !patient || !helper) return;

    var clonedPatient = Util.shallowClone(patient);

    for (let h of clonedPatient.helpers) {
      if (h._id === helper._id) {
        h.first_name = this.state.first_name;
        h.name = this.state.name;
        h.link = this.state.link;
        h.other = this.state.other;
        h.email = this.state.email;
        h.phone = this.state.phone;
      }
    }

    // Send to BE
    this.props.onUpdPatient(clonedPatient);
  }

  disabled() {
    return (
      Util.emptyString(this.state.name) ||
      Util.emptyString(this.state.first_name) ||
      this.state.emailError ||
      this.state.phoneError ||
      this.state.loading
    );
  }

  render() {
    return (
      <div className="modal-bg show">
        <Modal
          show={true}
          onHide={() => this.props.close()}
          backdrop={"static"}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id="Helpers" />
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "First.Name" })}
                htmlFor="first_name"
                className="col-12 col-sm-5 col-form-label"
                required
              />
              <div className="col-12 col-sm-7">
                <input
                  className="form-control"
                  type="text"
                  id="first_name"
                  autoComplete="off"
                  value={this.state.first_name}
                  onChange={(e) => this.onChange("first_name", e.target.value)}
                  onBlur={(e) => this.onBlur("first_name", e.target.value)}
                />
              </div>
            </div>

            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Name" })}
                htmlFor="name"
                className="col-12 col-sm-5 col-form-label"
                required
              />
              <div className="col-12 col-sm-7">
                <input
                  className="form-control"
                  type="text"
                  id="name"
                  autoComplete="off"
                  value={this.state.name}
                  onChange={(e) => this.onChange("name", e.target.value)}
                  onBlur={(e) => this.onBlur("name", e.target.value)}
                />
              </div>
            </div>

            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Link" })}
                htmlFor="link"
                className="col-12 col-sm-5 col-form-label"
              />
              <div className="col-12 col-sm-7">
                <input
                  className="form-control"
                  type="text"
                  id="link"
                  autoComplete="off"
                  value={this.state.link}
                  onChange={(e) => this.onChange("link", e.target.value)}
                  onBlur={(e) => this.onBlur("link", e.target.value)}
                />
              </div>
            </div>

            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Other" })}
                htmlFor="other"
                className="col-12 col-sm-5 col-form-label"
              />
              <div className="col-12 col-sm-7">
                <input
                  className="form-control"
                  type="text"
                  id="other"
                  autoComplete="off"
                  value={this.state.other}
                  onChange={(e) => this.onChange("other", e.target.value)}
                  onBlur={(e) => this.onBlur("other", e.target.value)}
                />
              </div>
            </div>

            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Email" })}
                htmlFor="email"
                className="col-12 col-sm-5 col-form-label"
              />
              <div className="col-12 col-sm-7">
                <input
                  className="form-control"
                  type="text"
                  id="email"
                  autoComplete="off"
                  value={this.state.email}
                  onChange={(e) => this.onChange("email", e.target.value)}
                  onBlur={(e) => this.onBlur("email", e.target.value)}
                />
                <div className="text-danger">
                  <small>{this.state.emailError}</small>
                </div>
              </div>
            </div>

            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Phone" })}
                htmlFor="phone"
                className="col-12 col-sm-5 col-form-label"
              />
              <div className="col-12 col-sm-7">
                <input
                  className="form-control"
                  type="text"
                  id="phone"
                  autoComplete="off"
                  value={this.state.phone}
                  onChange={(e) => this.onChange("phone", e.target.value)}
                  onBlur={(e) => this.onBlur("phone", e.target.value)}
                />
                <div className="text-danger">
                  <small>{this.state.phoneError}</small>
                </div>
              </div>
            </div>
          </Modal.Body>

          {!this.isUpdate && (
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.props.close()}>
                <FormattedMessage id="Cancel" />
              </Button>
              <Button
                variant="info"
                onClick={() => this.onSubmit()}
                disabled={this.disabled()}
              >
                <FormattedMessage id="Add" />
              </Button>
            </Modal.Footer>
          )}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdPatient: (patient, successCallback) =>
      dispatch(updPatient(patient, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(AddPatientHelperModal));
