import { GET_HOME_ASSESSMENTS } from "../../actions/homeAssessment/homeAssessment";

export default function HomeAssessments(state = [], action) {
  switch (action.type) {
    case GET_HOME_ASSESSMENTS:
      return action.HomeAssessments;

    default:
      return state;
  }
}
