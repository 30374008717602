import { GET_SAVS } from "../../actions/savs/savs";

export default function savs(state = [], action) {
  switch (action.type) {
    case GET_SAVS:
      return action.savs;
    default:
      return state;
  }
}
