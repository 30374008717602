export default class FileUtil {
  static extension(fileName) {
    var fragments = fileName.split(".");
    var extension = fragments[fragments.length - 1];
    // Convert to lowercase for eval
    return extension.toLowerCase();
  }

  static extensionIsOk(fileName, acceptedExtensions) {
    var extension = FileUtil.extension(fileName);

    for (let ext of acceptedExtensions) {
      if (extension === ext) return true;
    }

    return false;
  }

  static withoutExtension(fileName) {
    let extension = FileUtil.extension(fileName);
    return fileName.replace("." + extension, "");
  }

  // This will normalize the name to fit file names requirements (no accents, lowercase, special characters etc.)
  static toFileName(name) {
    return name
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-z0-9]/gi, "_")
      .toLowerCase();
  }
}
