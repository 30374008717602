import React from "react";
import Util from "../../util/Util";

export default class CustomLabel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      label: this.checkProp(this.props.label) ? this.props.label : "",
      htmlFor: this.checkProp(this.props.htmlFor) ? this.props.htmlFor : "",
      required: this.props.required ? this.props.required : null,
    };
  }

  // Check if required props are set and are of type "string" and not empty
  checkProp = (myProp) => {
    return Util.typeOf(myProp) === "String" && myProp.length > 0;
  };

  render() {
    // console.log(this.props.options)
    // console.log(this.props.options.type)
    // console.log(this.props.options.props.className)
    let labelClassName = this.checkProp(this.props.labelClassName)
      ? this.props.labelClassName
      : "col-12 col-md-5";
    labelClassName += " col-form-label";

    let requiredIcon;

    // Specific options for labels made of icons where there is no need for a "String" label
    let iconOption = this.checkProp(this.props.iconOptions)
      ? this.props.iconOptions
      : null;
    if (iconOption && !this.state.label)
      iconOption = <i className={iconOption} />;

    if (this.state.required) {
      requiredIcon = <i className="fa fa-asterisk"></i>;
      labelClassName += " required";
    }

    return (
      <label htmlFor={this.state.htmlFor} className={labelClassName}>
        {this.state.label}
        {requiredIcon} {iconOption}
      </label>
    );
  }
}
