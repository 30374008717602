import APIUrl from "../../APIUrl";
import axios from "axios";

export const GET_ESTABLISHMENT_SETTINGS = "GET_ESTABLISHMENT_SETTINGS";

export const GET_ESTABLISHMENTS_SETTINGS = "GET_ESTABLISHMENTS_SETTINGS";

function getEstablishmentSettingsAction(establishmentSettings) {
  return {
    type: GET_ESTABLISHMENT_SETTINGS,
    establishmentSettings: establishmentSettings,
  };
}

function getEstablishmentsSettingsAction(establishmentsSettings) {
  return {
    type: GET_ESTABLISHMENTS_SETTINGS,
    establishmentsSettings: establishmentsSettings,
  };
}

export const getEstablishmentSettings = function (successCallback) {
  return function (dispatch) {
    return axios
      .get(APIUrl.getEstablishmentSettings)
      .then(function (response) {
        dispatch(getEstablishmentSettingsAction(response.data));
        if (successCallback) successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const getEstablishmentsSettings = function (successCallback) {
  return function (dispatch) {
    return axios
      .get(APIUrl.getEstablishmentsSettings)
      .then(function (response) {
        dispatch(getEstablishmentsSettingsAction(response.data));
        if (successCallback) successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateEstablishmentSettings = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateEstablishmentSettings, data)
      .then(function (response) {
        dispatch(getEstablishmentsSettings(successCallback));
      })
      .catch(function (err) {
        throw err;
      });
  };
};
