import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import ConfirmationModal from "../../../../sub/modals/ConfirmationModal.js";
import BrandsAdminRow from "./BrandsAdminRow";
import ErrorModal from "../../../../sub/modals/ErrorModal.js";
import Paginator from "../../../../sub/Paginator";
import Util from "../../../../../util/Util.js";
import CustomLabel from "../../../../sub/CustomLabel.js";
import BrandsAdminModal from "./BrandsAdminModal.js";
import TableToolbar from "../../../../sub/bootstrap/TableToolbar";
import MenuButton from "../../../../sub/bootstrap/MenuButton";

class BrandsAdminTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      brandFilter: "",
    };
    this.paginator = new Paginator(this);
  }

  openConfModal(title, content, successCallback) {
    this.setState({
      modal: (
        <ConfirmationModal
          title={title}
          content={content}
          successCallback={successCallback}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  openBrandsModal() {
    this.setState({
      modal: (
        <BrandsAdminModal closeModal={() => this.closeModal()} />
      ),
    });
  }

  openEditModal(brand) {
    this.setState({
      modal: (
        <BrandsAdminModal
          closeModal={() => this.closeModal()}
          brandId={brand._id}
        />
      ),
    });
  }

  openErrorModal(title, content) {
    this.setState({
      modal: (
        <ErrorModal
          size="lg"
          title={title}
          content={content}
          closeModal={() => this.closeModal()}
          buttonLabel={<FormattedMessage id="Cancel" />}
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  areResultsFiltered = () => {
    if (!Util.emptyString(this.state.brandFilter)) {
      return true;
    } else {
      return false;
    }
  };

  resetSearchFields() {
    this.setState({
      brandFilter: "",
    });
  }

  render() {
    let { brands, patients, intl } = this.props;

    this.paginator.init();
    let disableFormInput = this.paginator.paginationIndex !== 1 ? true : false;

    let BrandsRowNode = brands.map((brand) => {
      if (this.state.brandFilter && this.state.brandFilter !== "") {
        let brandName = brand.name.toLowerCase();
        if (brandName.indexOf(this.state.brandFilter.toLowerCase()) === -1)
          return null;
      }

      if (!this.paginator.keep()) return null;

      return (
        <BrandsAdminRow
          key={brand._id}
          brand={brand}
          brands={brands}
          patients={patients}
          openConfModal={(title, content, successCallback) =>
            this.openConfModal(title, content, successCallback)
          }
          openErrorModal={(title, content) =>
            this.openErrorModal(title, content)
          }
          openEditModal={(brand) => this.openEditModal(brand)}
        />
      );
    });

    let resetSearchButton = () => {
      if (this.areResultsFiltered() && !disableFormInput) {
        return (
          <MenuButton
            icon="filter"
            onClick={() => this.resetSearchFields()}
            variant="warning"
            hover={<FormattedMessage id="Remove.Filter" />}
          />
        );
      } else {
        return <MenuButton
          icon="filter"
          onClick={() => this.resetSearchFields()}
          variant="outline-secondary"
          disabled
        />
      }
    };

    return (
      <div className="mt-3">
        {this.state.modal}
        <TableToolbar>
          {brands.length > 0 && (
            <div className="form-inline">
              <CustomLabel
                label={intl.formatMessage({ id: "Brand" })}
                htmlFor="search_brand"
                labelClassName="my-1 mr-2"
              />
              <input
                id="search_brand"
                className="form-control mr-sm-3"
                onChange={(e) => {
                  this.setState({ brandFilter: e.target.value });
                }}
                disabled={disableFormInput}
                value={this.state.brandFilter}
              />
              {resetSearchButton()}
            </div>
          )}
          <button
            className="btn btn-info ml-auto"
            onClick={(e) => this.openBrandsModal()}
          >
            <FormattedMessage id="Add.Brand" />
          </button>
        </TableToolbar>

        {brands.length > 0 && (
          <table className="table tablee4vhp">
            <thead>
              <tr className="d-flex">
                <th scope="col" className="col">
                  <FormattedMessage id="Brand" />
                </th>
                <th scope="col" className="text-center col col-1">
                  <FormattedMessage id="Actions" />
                </th>
              </tr>
            </thead>
            <tbody>{BrandsRowNode}</tbody>
          </table>
        )}

        <div className="mt-3">{this.paginator.render()}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(BrandsAdminTable));
