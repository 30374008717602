import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  updPatient,
  deletePatientDocument,
} from "../../actions/patients/patients";
import DateUtil from "../../util/DateUtil";
import AddPatientDocumentModal from "./AddPatientDocumentModal";
import ConfirmationModal from "../sub/modals/ConfirmationModal";
import APIUrl from "../../APIUrl";
import Roles from "../../enums/Roles";
import { UncontrolledTooltip } from "reactstrap";
import { Alert } from "react-bootstrap";

class PatientDocuments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      disabled: false,
    };
  }

  openModal(patient) {
    this.setState({
      modal: (
        <AddPatientDocumentModal
          patient={patient}
          close={() => this.closeModal()}
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  delete(document) {
    const { patient } = this.props;

    if (!document || !patient) return;

    this.setState({
      modal: (
        <ConfirmationModal
          isOpen={true}
          title={<FormattedMessage id="Confirm" />}
          content={<FormattedMessage id="Confirm.Tech.Document.Removal" />}
          successCallback={() =>
            this.props.onDeletePatientDocument(
              this.props.user.role,
              patient._id,
              document._id
            )
          }
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  render() {
    const { patient, user } = this.props;

    return (
      <React.Fragment>
        {(user.role === Roles.ADMIN || user.role === Roles.SALES_REP) && (
          <button
            className="btn btn-info m-auto hoverable"
            onClick={(e) => this.openModal(patient)}
          >
            <FormattedMessage id="Patient.Document.Add" />
          </button>
        )}
        {(!patient || !patient.documents || patient.documents.length <= 0) && (
          <Alert variant="secondary" className="mt-3">
            <FormattedMessage id="Empty.Patient.Document" />
          </Alert>
        )}

        {patient && patient.documents && patient.documents.length > 0 && (
          <table className="table tablee4vhp mt-3">
            <thead>
              <tr key={patient._id} className="tablerowhead">
                <th className="d-md-table-cell">
                  <FormattedMessage id="Date" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                <th className="d-md-table-cell">
                  <FormattedMessage id="Title" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                <th className="d-md-table-cell">
                  <FormattedMessage id="Type" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                <th className="d-md-table-cell">
                  <FormattedMessage id="File" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                {user.role === Roles.CLIENT ? (
                  ""
                ) : (
                  <th className="d-none d-md-table-cell">
                    <FormattedMessage id="Actions" />{" "}
                    <i className="fa fa-chevron-down float-right mr-5"></i>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {patient.documents.map((document) => (
                <tr key={document._id} className={"order-tr order-tr"}>
                  <td className="d-md-table-cell mw-200 align-middle">
                    {DateUtil.toDate(document.date)}
                  </td>
                  <td className="d-md-table-cell mw-200 align-middle">
                    {document.title}
                  </td>
                  <td className="d-md-table-cell mw-200 align-middle">
                    <FormattedMessage
                      id={"Patient.DocumentType." + document.type_id}
                    />
                  </td>
                  <td className="d-md-table-cell mw-200 align-middle">
                    <a
                      id={"display-" + document._id}
                      role="button"
                      href={
                        APIUrl.getPatientDocumentFile +
                        patient._id +
                        "/" +
                        document._id +
                        "?token=" +
                        APIUrl.jwtToken
                      }
                      rel="noopener noreferrer"
                      download={document.file_name}
                      target="_blank"
                    >
                      {document.file_name.substring(0, 20) + "..."}
                    </a>
                  </td>
                  <UncontrolledTooltip
                    delay={{ show: 0, hide: 0 }}
                    placement="top"
                    target={"display-" + document._id}
                  >
                    <FormattedMessage id="Display.Document" />
                  </UncontrolledTooltip>
                  {user.role === Roles.CLIENT ? (
                    ""
                  ) : (
                    <td className="text-center tdaction">
                      <i
                        id={"delete-" + document._id}
                        className="fa fa-trash icon-big"
                        disabled={this.state.disabled}
                        onClick={(e) => this.delete(document)}
                      ></i>
                    </td>
                  )}
                  {user.role === Roles.CLIENT ? (
                    ""
                  ) : (
                    <UncontrolledTooltip
                      delay={{ show: 0, hide: 0 }}
                      placement="top"
                      target={"delete-" + document._id}
                    >
                      <FormattedMessage id="Delete" />
                    </UncontrolledTooltip>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdPatient: (patient, successCallback) =>
      dispatch(updPatient(patient, successCallback)),
    onDeletePatientDocument: (
      userRole,
      patientId,
      documentId,
      successCallback
    ) =>
      dispatch(
        deletePatientDocument(userRole, patientId, documentId, successCallback)
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(PatientDocuments));
