import fr from "./fr";
import en from "./en";

import frMT from "./frMT";
import enMT from "./enMT";

var enContent = Object.assign({}, en, enMT);
var frContent = Object.assign({}, fr, frMT);

export { enContent as enMessages, frContent as frMessages };
