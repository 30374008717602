import { GET_CALENDAR_EVENTS } from "../../actions/calendarEvents/calendarEvents";

export default function calendarEvents(state = [], action) {
  switch (action.type) {
    case GET_CALENDAR_EVENTS:
      return action.calendarEvents;
    default:
      return state;
  }
}
