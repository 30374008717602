import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";

class ErrorModal extends React.Component {
  close() {
    this.props.closeModal();
  }

  confirm() {
    this.props.successCallback();
    this.close();
  }

  render() {
    return (
      <div className="modal-bg show">
        <Modal show={true} onHide={() => this.close()} backdrop={"static"}>
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="fa fa-warning fa-big mr-3"></i>
              {this.props.title}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>{this.props.content}</Modal.Body>

          <Modal.Footer>
            <Button variant="danger" onClick={() => this.close()}>
              <FormattedMessage id="Ok" />
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal);
